import * as React              from "react";
import { TableCell, TableRow } from "@material-ui/core";
import formatDate              from "../../../config/formatDate";
import Actions                 from "../Table/Actions";
import moment                  from "moment";
import { Product }             from "../../../Models/Product/Product";

interface ITableRowProductViewProps {
    product: Product;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowProductView extends React.Component<ITableRowProductViewProps, {}> {

    public render(): React.ReactNode {
        const {
                  product,
                  onEdit,
                  onDelete,
              } = this.props;

        return (
            <TableRow>
                <TableCell>{product.getCode()}</TableCell>
                <TableCell>{product.getName()}</TableCell>
                <TableCell>{product.getBrand()}</TableCell>
                <TableCell>{product.getPrice()}</TableCell>
                <TableCell>{product.getOrder()}</TableCell>
                <TableCell>{product.getInStock() ? "Si" : "No"}</TableCell>
                <TableCell>{product.getSlug()}</TableCell>
                <TableCell>{moment(product.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(product.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowProductView;
