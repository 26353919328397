import { inject, observer }               from "mobx-react";
import * as React                         from "react";
import ConfirmationModal                  from "../../../Views/Components/Modal/ConfirmationModal";
import ModalService                       from "../../../Service/ModalService";
import { Container }                      from "typedi";
import { ModalStore }                     from "../../../Store/ModalStore";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { CategoryStore }                 from "../../../Store/CategoryStore";
import { Category }                      from "../../../Models/Product/Category";

interface IDeleteCategoryViewModelProps extends WithNamespaces {
    ModalStore?: ModalStore;
    CategoryStore?: CategoryStore;
    category: Category;
}

@inject(ModalStore.NAME_STORE, CategoryStore.NAME_STORE)
@observer
class DeleteCategoryViewModel extends React.Component<IDeleteCategoryViewModelProps, {}> {
    private readonly modalService: ModalService;

    constructor(props: IDeleteCategoryViewModelProps) {
        super(props);

        this.modalService = Container.get(ModalService);
    }

    get categoryStore(): CategoryStore {
        return this.props.CategoryStore as CategoryStore;
    }

    get modalStore(): ModalStore {
        return this.props.ModalStore as ModalStore;
    }

    public action = async () => {
        const {category} = this.props;

        this.categoryStore.setSpinner(true);
        await this.categoryStore.deleteById(category.getId());
        const data = await this.categoryStore.getPaginate();

        this.categoryStore
            .setPages(data.pages)
            .setCategories(data.data)
            .setSpinner(false);

        this.modalService.closeModal();
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <ConfirmationModal
                handleClose={this.modalService.closeModal}
                open={this.modalStore.getModal().getOpenModal()}
                action={this.action}
                spinner={this.categoryStore.getSpinner()}
                title={t("confirm.content")}
                content={t("confirm.title")}
            />
        );
    }
}

export default withNamespaces("modal")(DeleteCategoryViewModel);
