import { observable }   from "mobx";
import { serializable } from "serializr";

export class ProductFilter {
    @serializable
    @observable
    private code: string = "";

    @serializable
    @observable
    private name: string = "";

    @serializable
    @observable
    private description: string = "";

    @serializable
    @observable
    private brand: string = "";

    @serializable
    @observable
    private sortDirection: number = 1;

    @serializable
    @observable
    private sort: string = "name";

    public getCode(): string {
        return this.code;
    }

    public setCode(value: string) {
        this.code = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(value: string) {
        this.description = value;
    }

    public getBrand(): string {
        return this.brand;
    }

    public setBrand(value: string) {
        this.brand = value;
    }

    public getSort(): string {
        return this.sort;
    }

    public setSort(value: string) {
        this.sort = value;
    }

    public getSortDirection(): number {
        return this.sortDirection;
    }

    public setSortDirection(value: number) {
        this.sortDirection = value;
    }
}
