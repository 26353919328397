import { inject, observer }               from "mobx-react";
import * as React                         from "react";
import ConfirmationModal                  from "../../../Views/Components/Modal/ConfirmationModal";
import ModalService                       from "../../../Service/ModalService";
import { Container }                      from "typedi";
import { ModalStore }                     from "../../../Store/ModalStore";
import { GalleryStore }                   from "../../../Store/GalleryStore";
import { Gallery }                        from "../../../Models/Gallery/Gallery";
import { withNamespaces, WithNamespaces } from "react-i18next";

interface IDeleteGalleryViewModelProps extends WithNamespaces {
    ModalStore?: ModalStore;
    GalleryStore?: GalleryStore;
    gallery: Gallery;
}

@inject(ModalStore.NAME_STORE, GalleryStore.NAME_STORE)
@observer
class DeleteGalleryViewModel extends React.Component<IDeleteGalleryViewModelProps, {}> {
    private readonly modalService: ModalService;

    constructor(props: IDeleteGalleryViewModelProps) {
        super(props);

        this.modalService = Container.get(ModalService);
    }

    get galleryStore(): GalleryStore {
        return this.props.GalleryStore as GalleryStore;
    }

    get modalStore(): ModalStore {
        return this.props.ModalStore as ModalStore;
    }

    public action = async () => {
        const {gallery} = this.props;

        this.galleryStore.setSpinner(true);
        await this.galleryStore.deleteById(gallery.getId());
        const data = await this.galleryStore.getPaginate();

        this.galleryStore
            .setPages(data.pages)
            .setGalleries(data.data)
            .setSpinner(false);

        this.modalService.closeModal();
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <ConfirmationModal
                handleClose={this.modalService.closeModal}
                open={this.modalStore.getModal().getOpenModal()}
                action={this.action}
                spinner={this.galleryStore.getSpinner()}
                title={t("confirm.content")}
                content={t("confirm.title")}
            />
        );
    }
}

export default withNamespaces("modal")(DeleteGalleryViewModel);
