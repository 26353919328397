import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
    CONFIG_CALL_GET_API,
    CONFIG_CALL_POST_API,
    CONFIG_GET_ACCESS_TOKEN,
    PARAMS_GET_ACCESS_TOKEN
}                                                   from "../config/axios.config";
import { Container, Service }                       from "typedi";
import { stringify }                                from "qs";
import { ApplicationStore }                         from "../Store/ApplicationStore";
import AccessToken                                  from "../Models/Security/AccessToken";
import API_CONST                                    from "../config/API_CONST";
import { TokenStore }                               from "../Store/TokenStore";
import { generatePath }                             from "react-router";
import { UserStore }                                from "../Store/UserStore";

@Service()
class AxiosService {
    private _axios: AxiosInstance;
    private callToken: boolean = false;
    private multipart: boolean = false;

    constructor() {
        this.createAxios();
    }

    private getTokenStore(): TokenStore {
        return Container.get(ApplicationStore).getStore(TokenStore);
    }

    private getUserStore(): UserStore {
        return Container.get(ApplicationStore).getStore(UserStore);
    }

    public setMultipart = (multipart: boolean) => {
        this.multipart = multipart;
    };

    private createAxios() {
        this._axios = Axios.create(CONFIG_CALL_GET_API);

        this.axiosInterceptor();
    }

    private axiosInterceptor() {
        this._axios.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
            // call a new token
            if (this.callToken) {
                if (config.headers["content-type"] === "application/x-www-form-urlencoded") config.data = stringify(config.data);

                return config;
            }

            // call deleted api, append token
            const token = this.getTokenStore().getAccessToken();

            switch (config.method) {
                case "post":
                case "put": {
                    config.headers = CONFIG_CALL_POST_API.headers;

                    if (config.data instanceof FormData)
                        config.headers["content-type"] = "multipart/form-data";

                    break;
                }

                case "get": {
                    config.headers = CONFIG_CALL_GET_API.headers;
                    break;
                }
            }

            if (token && config.headers.authorization)
                config.headers.authorization = config.headers.authorization.replace("%s", token.getAccessToken());

            if (config.headers["content-type"] === "application/x-www-form-urlencoded") config.data = stringify(config.data);

            return config;
        }, function (error) {
            // @TODO handle error to report
            // Do something with request error
            console.log("error", error);
        });

        this._axios.interceptors.response.use(response => response, this.rejectResponse);
    }

    public rejectResponse = (error: any): any => {
        const response = error.response;

        if (process.env.REACT_APP_ENV === "dev") {
            console.log(error);
            return error; // show error on dev
        }

        if (typeof response === "undefined" || (response.status === 401)) {
            this.getUserStore().setUser(undefined);
            this.getTokenStore().setAccessToken(undefined);
            window.location.href = generatePath("/login");
        }

        return error;
    };

    get axios(): AxiosInstance {
        return this._axios;
    }

    public async logout(accessToken: AccessToken): Promise<AccessToken | false> {
        await this.axios.post(API_CONST.POST_LOGOUT, {
            invalidate_access_token: accessToken.getAccessToken()
        });

        return false;
    }

    public async getAccessToken(paramsUser: object): Promise<AccessToken | false> {
        this.callToken = true;
        const params   = Object.assign(PARAMS_GET_ACCESS_TOKEN, paramsUser);

        const accessToken = await this.axios.post(API_CONST.POST_ACCESS_TOKEN, params, CONFIG_GET_ACCESS_TOKEN);
        this.callToken    = false;

        if (accessToken.data.code && accessToken.data.code === 500) return false;

        return accessToken.data;
    }
}

export default AxiosService;
