import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import { Product }                         from "../../../Models/Product/Product";
import DeleteProductViewModel              from "../Delete/DeleteProductViewModel";
import TableRowProductView                 from "../../../Views/Components/TableRowProduct/TableRowProductView";

interface ITableRowProductViewModelProps extends RouteComponentProps<any> {
    product: Product;
}

class TableRowProductViewModel extends React.Component<ITableRowProductViewModelProps, {}> {

    protected onEdit = () => {
        const {history, product} = this.props;
        history.push(renderRoute(PageNames.productEditPage, {id: product.getId()}));
    };

    protected onDelete = () => {
        const {product} = this.props;
        Container.get(ModalService).openModal(DeleteProductViewModel, {
            product
        });
    };

    public render(): React.ReactNode {
        const {product} = this.props;

        return (
            <TableRowProductView
                product={product}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowProductViewModel);
