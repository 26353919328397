import BaseStore                               from "./Base/BaseStore";
import { Gallery }                             from "../Models/Gallery/Gallery";
import { deserialize, serialize }              from "serializr";
import { createFormData }                      from "../Utils/common";
import { Container }                           from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { GalleryItem }                         from "../Models/Gallery/GalleryItem";
import { observable }                          from "mobx";
import { Promotion }                           from "../Models/Promotion/Promotion";
import { globalPagerOptions, IPaginationData } from "../config/pagination";

export class PromotionStore extends BaseStore {
    public static readonly NAME_STORE: string = "PromotionStore";

    @observable
    private promotions: Promotion[] = [];

    @observable
    private promotion: Promotion;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getPromotion(): Promotion {
        return this.promotion;
    }

    public setPromotion(value: Promotion): this {
        this.promotion = value;

        return this;
    }

    public getPromotions(): Promotion[] {
        return this.promotions;
    }

    public setPromotions(value: Promotion[]): this {
        this.promotions = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<Promotion>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginatePromotions(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(Promotion, item)),
            pages: dataResponse.pages
        } as IPaginationData<Promotion>;
    }

    public async getById(id: string): Promise<Promotion> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPromotionId(id);

        return deserialize(Promotion, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deletePromotionId(id);

        return success;
    }

    public async save(promotion: Promotion): Promise<Promotion> {
        const data     = serialize(promotion),
              formData = createFormData(data);;

        promotion.getGallery().getItems().map((galleryItem: GalleryItem, key: number) => {
            const image = galleryItem.getImageSelect() ? galleryItem.getImageSelect().file : "";
            formData.append(`images[${key}]`, image);
        });

        const {data: {data: dataResponse}} = await this.getAjaxService().savePromotion(formData);

        return deserialize(Promotion, dataResponse);
    }
}
