import BaseStore                               from "./Base/BaseStore";
import { deserialize }                         from "serializr";
import { Container }                           from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { observable }                          from "mobx";
import { globalPagerOptions, IPaginationData } from "../config/pagination";
import { ContactUs }                           from "../Models/ContactUs/ContactUs";

export class ContactUsStore extends BaseStore {
    public static readonly NAME_STORE: string = "ContactUsStore";

    @observable
    private contactUss: ContactUs[] = [];

    @observable
    private contactUs: ContactUs;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getContactUs(): ContactUs {
        return this.contactUs;
    }

    public setContactUs(value: ContactUs): this {
        this.contactUs = value;

        return this;
    }

    public getContactUss(): ContactUs[] {
        return this.contactUss;
    }

    public setContactUss(value: ContactUs[]): this {
        this.contactUss = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<ContactUs>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateContactUs(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(ContactUs, item)),
            pages: dataResponse.pages
        } as IPaginationData<ContactUs>;
    }

    public async getById(id: string): Promise<ContactUs> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getContactUsId(id);

        return deserialize(ContactUs, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deleteContactUsId(id);

        return success;
    }
}
