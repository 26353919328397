import * as React           from "react";
import { inject, observer } from "mobx-react";
import { ProductStore }     from "../../Store/ProductStore";
import { observable }       from "mobx";
import { Product }          from "../../Models/Product/Product";
import { ProductFilter }    from "../../Models/Product/ProductFilter";
import AutoSuggestProduct   from "../../Views/Components/AutoSuggestProduct/AutoSuggestProduct";

interface IAutoSuggestProductViewModelProps {
    ProductStore?: ProductStore;
    productSelected: Product;
    setProductSelected: (item: Product) => void;
}

@inject(ProductStore.NAME_STORE)
@observer
class AutoSuggestProductViewModel extends React.Component<IAutoSuggestProductViewModelProps, {}> {
    private searchTimer: NodeJS.Timeout;

    @observable
    private userTyping: string = "";

    @observable
    private products: Product[] = [];

    protected get productStore(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    /**
     * Getter searchTimer
     * @return {NodeJS.Timeout}
     */
    public getSearchTimer(): NodeJS.Timeout {
        return this.searchTimer;
    }

    /**
     * Setter searchTimer
     * @param {NodeJS.Timeout} value
     */
    public setSearchTimer(value: NodeJS.Timeout) {
        this.searchTimer = value;
    }

    /**
     * Getter userTyping
     * @return {string }
     */
    public getUserTyping(): string {
        return this.userTyping;
    }

    /**
     * Setter userTyping
     * @param {string } value
     */
    public setUserTyping(value: string) {
        this.userTyping = value;
    }

    protected getProducts = (): Product[] => {
        return this.products;
    };

    protected setProducts = (value: Product[]) => {
        this.products = value;
    };

    private onSuggestionsClear = () => {
        // this.setProducts([]);
    };

    private onChange = (ev: React.FormEvent<any>, {newValue}: any) => {
        this.setUserTyping(newValue);
    };

    private async getSuggestionResults(suggest: string): Promise<Product[]> {
        const page = await this.productStore.getPaginate(Object.assign(new ProductFilter(), {
            name: suggest
        }));

        return page.data;
    }

    private escapeRegexCharacters(str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    private onSuggestionsFetch = ({value}: any) => {
        if (!value) {
            return;
        }

        const escapedValue = this.escapeRegexCharacters(value.trim());

        // if last timer is active clear it
        if (this.searchTimer)
            clearTimeout(this.searchTimer);

        // set timer before actually search for the user
        this.setSearchTimer(setTimeout(async () => {
            // Get suggestion values from API
            if (escapedValue !== "" && escapedValue.length >= 2) {
                this.setProducts(await this.getSuggestionResults(escapedValue));
            }
        }, 700) as unknown as NodeJS.Timeout);
    };

    public render(): React.ReactNode {
        const {
                  productSelected,
                  setProductSelected
              } = this.props;

        return (
            <AutoSuggestProduct
                productSelected={productSelected}
                setProductSelected={setProductSelected}
                products={this.getProducts()}
                userTyping={this.getUserTyping()}
                setUserTyping={this.setUserTyping}
                onSuggestionsClear={this.onSuggestionsClear}
                onSuggestionsFetch={this.onSuggestionsFetch}
                onChange={this.onChange}
            />
        );
    }
}

export default AutoSuggestProductViewModel;