import * as React                         from "react";
import {
    Chip,
    FormControl, InputLabel,
    LinearProgress, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
}                                         from "@material-ui/core";
import { withNamespaces, WithNamespaces } from "react-i18next";
import AddIcon                            from "@material-ui/icons/Add";
import InjectPrefix                       from "../../../../Decorators/InjectPrefix";
import { Button }                         from "../../../Components/Button/Button";
import { Product }                        from "../../../../Models/Product/Product";
import TableRowProductViewModel           from "../../../../ViewModel/Product/View/TableRowProductViewModel";
import Pagination                         from "../../../Components/Pagination/Pagination";
import { ProductFilter }                  from "../../../../Models/Product/ProductFilter";
import { Col, Row }                       from "reactstrap";
import SearchIcon                         from "@material-ui/icons/Search";
import { observer }                       from "mobx-react";
import { MethodsPay }                     from "../../../../config/MethodsPay";

interface IProductViewProps extends WithNamespaces {
    spinner: boolean;
    prefix?: string;
    productFilter: ProductFilter;
    getPage: (page: number) => void;
}

@InjectPrefix
@observer
class ProductFilterView extends React.Component<IProductViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t,
                  productFilter,
                  getPage,
                  spinner,
              }            = this.props,
              propsButtons = {
                  color  : "primary" as "primary",
                  onClick: () => { if (!spinner) getPage(1); },
                  size   : "small" as "small",
                  spinner,
                  variant: "contained" as "contained"
              };

        return (
            <Row className="mb-3 mt-3 align-items-center">
                <Col xs={4}>
                    <TextField
                        id="form-code"
                        label={t("edit.code")}
                        fullWidth
                        value={productFilter.getCode()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            productFilter.setCode(evt.target.value);
                        }}
                        inputProps={{
                            autoComplete: "off",
                        }}
                        margin="normal"
                        variant="outlined"
                    />
                </Col>
                <Col xs={4}>
                    <TextField
                        id="form-name"
                        label={t("edit.name")}
                        fullWidth
                        value={productFilter.getName()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            productFilter.setName(evt.target.value);
                        }}
                        inputProps={{
                            autoComplete: "off",
                        }}
                        margin="normal"
                        variant="outlined"
                    />
                </Col>
                <Col xs={4}>
                    <TextField
                        id="form-brand"
                        label={t("edit.brand")}
                        fullWidth
                        value={productFilter.getBrand()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            productFilter.setBrand(evt.target.value);
                        }}
                        inputProps={{
                            autoComplete: "off",
                        }}
                        margin="normal"
                        variant="outlined"
                    />
                </Col>
                <Col xs={4}>
                    <TextField
                        id="form-description"
                        label={t("edit.description")}
                        fullWidth
                        value={productFilter.getDescription()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            productFilter.setDescription(evt.target.value);
                        }}
                        inputProps={{
                            autoComplete: "off",
                        }}
                        margin="normal"
                        variant="outlined"
                    />
                </Col>
                <Col xs={4}>
                    <FormControl fullWidth variant="outlined" className="wish-contact">
                        <InputLabel htmlFor="order-by" variant="outlined">
                            {t("edit.order-by")}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            required
                            fullWidth
                            value={productFilter.getSort()}
                            onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                                productFilter.setSort(event.target.value as string);
                            }}
                            inputProps={{
                                id  : "order-by",
                                name: "order-by",
                            }}
                        >
                            {
                                [
                                    "code",
                                    "name",
                                    "brand",
                                    "order",
                                    "createdAt",
                                ].map((item: string) => {
                                    return (
                                        <MenuItem value={item} key={item}>
                                            {t(`edit.${item}`)}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={4}>
                    <FormControl fullWidth variant="outlined" className="wish-contact">
                        <InputLabel htmlFor="order-status" variant="outlined">
                            {t("edit.order-by")}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            required
                            fullWidth
                            value={productFilter.getSortDirection()}
                            onChange={(event: React.ChangeEvent<{ name?: string; value: string }>) => {
                                productFilter.setSortDirection(parseInt(event.target.value.toString(), 10));
                            }}
                            inputProps={{
                                id  : "order-status",
                                name: "order-status",
                            }}
                        >
                            {
                                [
                                    1,
                                    -1,
                                ].map((item: number) => {
                                    return (
                                        <MenuItem value={item} key={item}>
                                            {t(`global:order.${item}`)}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={4} className="justify-content-end">
                    <Button {...propsButtons}>
                        <SearchIcon/> {t("edit.search")}
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default withNamespaces("page")(ProductFilterView);
