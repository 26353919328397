import * as React              from "react";
import { TableCell, TableRow } from "@material-ui/core";
import formatDate              from "../../../config/formatDate";
import Actions                 from "../Table/Actions";
import moment                  from "moment";
import { AboutUs }             from "../../../Models/AboutUs/AboutUs";

interface ITableRowGalleryViewProps {
    aboutUs: AboutUs;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowAboutUsView extends React.Component<ITableRowGalleryViewProps, {}> {

    public render(): React.ReactNode {
        const {
                  aboutUs,
                  onEdit,
                  onDelete,
              }    = this.props;

        return (
            <TableRow>
                <TableCell>{aboutUs.getName()}</TableCell>
                <TableCell>{aboutUs.getActive() ? "Si" : "No"}</TableCell>
                <TableCell>{moment(aboutUs.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(aboutUs.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowAboutUsView;
