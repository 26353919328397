import { Chargelog } from "./Chargelog";
import {
    object,
    serializable
}                    from "serializr";
import { Result }    from "./Result";

export class SuccessPayment {
    @serializable
    // tslint:disable-next-line:variable-name
    private charge_id: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private charge_by_customer: boolean;

    @serializable(object(Chargelog))
    // tslint:disable-next-line:variable-name
    private charge_log: Chargelog;

    @serializable
    // tslint:disable-next-line:variable-name
    private status: number;

    @serializable
    // tslint:disable-next-line:variable-name
    private orderId: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private authorization: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private amount: number;

    @serializable
    // tslint:disable-next-line:variable-name
    private currency: string;

    @serializable(object(Result))
    // tslint:disable-next-line:variable-name
    private result: Result;

    @serializable
    // tslint:disable-next-line:variable-name
    private _signature: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private last4: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private brand: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private bin: string;

    public getChargeId(): string {
        return this.charge_id;
    }

    public setChargeId(value: string) {
        this.charge_id = value;
    }

    public getChargeByCustomer(): boolean {
        return this.charge_by_customer;
    }

    public setChargeByCustomer(value: boolean) {
        this.charge_by_customer = value;
    }

    public getChargeLog(): Chargelog {
        return this.charge_log;
    }

    public setChargeLog(value: Chargelog) {
        this.charge_log = value;
    }

    public isGreenPay() {
        return !!this.result;
    }

    public getLast4(): string {
        return this.last4;
    }

    public getOrderId(): string {
        return this.orderId;
    }

    public getAuthorization(): string {
        return this.authorization;
    }

    public getBrand(): string {
        return this.brand;
    }

    public getAmount(): number {
        return this.amount;
    }
}