import * as React                         from "react";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import BackTop                            from "./BackTop";
import {
    Container,
    List
}                                         from "@material-ui/core";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { PageNames, renderRoute }         from "../../../Routes/routes";
import { Link }                           from "react-router-dom";
import cx from "classnames";

interface FooterProps extends WithNamespaces {
    prefix?: string;
    currentPage: string;
}

@InjectPrefix
class Footer extends React.Component<FooterProps, {}> {

    get prefix(): string {
        return this.props.prefix as string;
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <footer className={`${this.prefix}-footer ${this.prefix}-primary-color-background`}>
                <BackTop/>
                <div className="copyright">
                    <Container>
                        <p>
                            {t("all-rights-reserved", {year: new Date().getFullYear()})}
                        </p>
                        <p>
                            {t("global:enterprise")}
                        </p>
                    </Container>
                </div>
            </footer>
        );
    }
}

export default withNamespaces("footer")(Footer);
