import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import { ContactUsStore }                  from "../../../Store/ContactUsStore";
import ContactUsView                       from "../../../Views/Pages/ContactUs/View/ContactUsView";

interface IContactUsViewModelProps extends RouteComponentProps<any> {
    ContactUsStore?: ContactUsStore;
}

@inject(ContactUsStore.NAME_STORE)
@observer
class ContactUsViewModel extends React.Component<IContactUsViewModelProps, {}> {
    get contactUsStore(): ContactUsStore {
        return this.props.ContactUsStore as ContactUsStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.contactUsStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.contactUsStore.getPaginate();

        this.contactUsStore
            .setPages(response.pages)
            .setContactUss(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        return (
            <ContactUsView
                contactUss={this.contactUsStore.getContactUss()}
                spinner={this.contactUsStore.getSpinner()}
                getPage={this.getPage}
                pages={this.contactUsStore.getPages()}
                currentPage={this.contactUsStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(ContactUsViewModel);
