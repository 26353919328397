import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { ProductStore }                    from "../../../Store/ProductStore";
import { ProductFilter }                   from "../../../Models/Product/ProductFilter";
import ProductView                         from "../../../Views/Pages/Product/View/ProductView";

interface IProductViewModelProps extends RouteComponentProps<any> {
    ProductStore?: ProductStore;
}

@inject(ProductStore.NAME_STORE)
@observer
class ProductViewModel extends React.Component<IProductViewModelProps, {}> {
    get productStore(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    constructor(props: IProductViewModelProps) {
        super(props);

        if (!this.productStore.getProductFilter())
            this.productStore.setProductFilter(new ProductFilter());
    }

    public componentDidMount = async (): Promise<void> => {
        await this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.productStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.productStore.getPaginate(this.productStore.getProductFilter());

        this.productStore
            .setPages(response.pages)
            .setProducts(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        return (
            <ProductView
                products={this.productStore.getProducts()}
                spinner={this.productStore.getSpinner()}
                getPage={this.getPage}
                pages={this.productStore.getPages()}
                currentPage={this.productStore.getCurrentPage()}
                productFilter={this.productStore.getProductFilter()}
            />
        );
    }
}

export default withRouter(ProductViewModel);
