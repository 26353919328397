import * as React                         from "react";
import { inject, observer }               from "mobx-react";
import { ERROR_CLASS, FormStore }         from "../../Store/FormStore";
import { Login }                          from "../../Models/Form/Login";
import LoginFormView                      from "../../Views/Components/Form/LoginFormView";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { observable }                     from "mobx";
import InjectPrefix                       from "../../Decorators/InjectPrefix";

interface ILoginFormViewModelProps extends WithNamespaces {
    FormStore?: FormStore;
    login: Login;
    onSubmit: () => Promise<boolean>;
    prefix?: string;
    invalidCredentials: boolean;
}

@InjectPrefix
@inject(FormStore.NAME_STORE)
@observer
class LoginFormViewModel extends React.Component<ILoginFormViewModelProps, {}> {
    @observable
    private spinner: boolean = false;

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get prefix(): string {
        return this.props.prefix as string;
    }

    constructor(props: ILoginFormViewModelProps) {
        super(props);
        const {t} = props;

        this.formStore.initValidator(t);
    }

    private submitForm = async (form: React.Component) => {
        const valid = this.formStore.getValidator().allValid();

        if (!valid) {
            this.formStore.getValidator().showMessages();
            form.forceUpdate(() => {
                this.formStore.setErrorDOMElements(document.querySelector(`.${ERROR_CLASS}`));
                this.formStore.scrollOnError();
            });

            return;
        }

        this.spinner = true;

        const validUser = await this.props.onSubmit();

        if (!validUser)
            this.spinner = false;
    };

    public render(): React.ReactNode {
        const {login, invalidCredentials} = this.props;

        return (
            <LoginFormView
                login={login}
                onSubmit={this.submitForm}
                validator={this.formStore.getValidator()}
                invalidCredentials={invalidCredentials}
                spinner={this.spinner}
            />
        );
    }
}

export default withNamespaces("forms")(LoginFormViewModel);
