import {
    inject,
    observer
}                            from "mobx-react";
import * as React            from "react";
import ConfirmationModal     from "../../../Views/Components/Modal/ConfirmationModal";
import ModalService          from "../../../Service/ModalService";
import { Container }         from "typedi";
import { ModalStore }        from "../../../Store/ModalStore";
import {
    withNamespaces,
    WithNamespaces
}                            from "react-i18next";
import { ColorPaletteStore } from "../../../Store/ColorPaletteStore";
import { ColorPalette }      from "../../../Models/Color/ColorPalette";

interface IDeleteColorPaletteViewModelProps extends WithNamespaces {
    ModalStore?: ModalStore;
    ColorPaletteStore?: ColorPaletteStore;
    colorPalette: ColorPalette;
}

@inject(ModalStore.NAME_STORE, ColorPaletteStore.NAME_STORE)
@observer
class DeleteColorPaletteViewModel extends React.Component<IDeleteColorPaletteViewModelProps, {}> {
    private readonly modalService: ModalService;

    constructor(props: IDeleteColorPaletteViewModelProps) {
        super(props);

        this.modalService = Container.get(ModalService);
    }

    get colorPaletteStore(): ColorPaletteStore {
        return this.props.ColorPaletteStore as ColorPaletteStore;
    }

    get modalStore(): ModalStore {
        return this.props.ModalStore as ModalStore;
    }

    public action = async () => {
        const {colorPalette} = this.props;

        this.colorPaletteStore.setSpinner(true);
        await this.colorPaletteStore.deleteById(colorPalette.getId());
        const data = await this.colorPaletteStore.getPaginate();

        this.colorPaletteStore
            .setPages(data.pages)
            .setColorPalettes(data.data)
            .setSpinner(false);

        this.modalService.closeModal();
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <ConfirmationModal
                handleClose={this.modalService.closeModal}
                open={this.modalStore.getModal().getOpenModal()}
                action={this.action}
                spinner={this.colorPaletteStore.getSpinner()}
                title={t("confirm.content")}
                content={t("confirm.title")}
            />
        );
    }
}

export default withNamespaces("modal")(DeleteColorPaletteViewModel);
