import BaseStore         from "./Base/BaseStore";
import { Container }     from "typedi";
import AjaxService       from "../Service/AjaxService";
import { observable }    from "mobx";
import { CronJobStatus } from "../Models/CronJobStatus/CronJobStatus";
import { deserialize }   from "serializr";

export class ActionStore extends BaseStore {
    public static readonly NAME_STORE: string = "ActionStore";

    @observable
    private cronJobStatus: CronJobStatus;

    @observable
    private spinner: boolean = false;

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public getCronJobStatus(): CronJobStatus {
        return this.cronJobStatus;
    }

    public setCronJobStatus(value: CronJobStatus) {
        this.cronJobStatus = value;
    }

    public async getByName(name: string): Promise<CronJobStatus | undefined> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getActionByName(name);

        return dataResponse ? deserialize(CronJobStatus, dataResponse) : undefined;
    }

    public async doAction(name: string): Promise<boolean> {
        const {data: {data: dataResponse}} = await this.getAjaxService().postAction(name);

        return dataResponse;
    }
}
