import * as React                         from "react";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
}                                         from "@material-ui/core";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../../Decorators/InjectPrefix";
import { Product }                        from "../../../../Models/Product/Product";
import TableRowProductViewModel           from "../../../../ViewModel/Product/View/TableRowProductViewModel";
import Pagination                         from "../../../Components/Pagination/Pagination";
import { ProductFilter }                  from "../../../../Models/Product/ProductFilter";
import { observer }                       from "mobx-react";
import ProductFilterView                  from "./ProductFilterView";

interface IProductViewProps extends WithNamespaces {
    products: Product[];
    spinner: boolean;
    prefix?: string;
    pages: number;
    currentPage: number;
    productFilter: ProductFilter;
    getPage: (page: number) => void;
}

@InjectPrefix
@observer
class ProductView extends React.Component<IProductViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderBody = (): React.ReactNode => {
        const {products} = this.props;

        return products.map((product: Product, key: number) => (
            <TableRowProductViewModel
                product={product}
                key={product.getId()}
            />
        ));
    };

    protected renderPagination = (): React.ReactNode => {
        const {
                  spinner,
                  pages,
                  currentPage,
                  getPage,
              } = this.props;

        return <Pagination
            className="mt-4"
            pages={pages}
            currentPage={currentPage}
            getPage={!spinner ? getPage : () => {}}
        />;
    };

    public render(): React.ReactNode {
        const {
                  spinner,
                  t,
                  productFilter,
                  getPage,
              }            = this.props;

        return (
            <div>
                <h3>
                    {t("products")}
                </h3>
                {<ProductFilterView
                    spinner={spinner}
                    productFilter={productFilter}
                    getPage={getPage}
                />}
                <Table className={`${this.prefix}-table-info`}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("edit.code")}</TableCell>
                            <TableCell>{t("edit.name")}</TableCell>
                            <TableCell>{t("edit.brand")}</TableCell>
                            <TableCell>{t("edit.price")}</TableCell>
                            <TableCell>{t("edit.order")}</TableCell>
                            <TableCell>{t("edit.inStock")}</TableCell>
                            <TableCell>{t("edit.slug")}</TableCell>
                            <TableCell>{t("edit.createdAt")}</TableCell>
                            <TableCell>{t("edit.updatedAt")}</TableCell>
                            <TableCell align="right">{t("edit.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderBody()}
                    </TableBody>
                </Table>
                {this.renderPagination()}
                {
                    spinner &&
                    <LinearProgress className="mt-4 mb-4"/>
                }
            </div>
        );
    }
}

export default withNamespaces("page")(ProductView);
