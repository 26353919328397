import { raw, serializable }       from "serializr";
import { observable }              from "mobx";
import { CronJobStatus as Status } from "../../config/cronJobStatus";

export class CronJobStatus {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private status: Status = Status.DONE;

    @serializable
    @observable
    private name: string;

    @serializable(raw())
    @observable
    private data: { percentage: number } & any = {percentage: 0};


    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getStatus(): Status {
        return this.status;
    }

    public setStatus(value: Status) {
        this.status = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getData(): any {
        return this.data;
    }

    public setData(value: any) {
        this.data = value;
    }
}
