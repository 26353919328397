import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import * as React                         from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
}                                         from "@material-ui/core";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { Button }                         from "../Button/Button";


interface IConfirmationModalProps extends WithNamespaces {
    handleClose: () => void;
    action: () => void;
    open: boolean;
    spinner: boolean;
    title: string;
    content: string;
}

@InjectPrefix
class ConfirmationModal extends React.Component<IConfirmationModalProps, {}> {
    public render(): React.ReactNode {
        const {
                  action,
                  content,
                  spinner,
                  handleClose,
                  open,
                  title,
                  t
              } = this.props;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        size="medium"
                        variant="outlined"
                        spinner={false}
                        disable={spinner}
                        text={t("cancel")}
                    />
                    <Button
                        onClick={action}
                        color="primary"
                        size="medium"
                        variant="outlined"
                        spinner={spinner}
                        text={t("agree")}
                    />
                </DialogActions>
            </Dialog>
        );
    }
}


export default withNamespaces("modal")(ConfirmationModal);
