import {
    inject,
    observer
}                         from "mobx-react";
import { OrderStore }     from "../../Store/OrderStore";
import * as React         from "react";
import { LinearProgress } from "@material-ui/core";
import { observable }     from "mobx";
import { Order }          from "../../Models/Order/Order";
import SendEmailView      from "../../Views/Components/Order/SendEmailView";
import {
    WithNamespaces,
    withNamespaces
}                         from "react-i18next";
import { FormStore }      from "../../Store/FormStore";

interface ISendEmailViewModelProps extends WithNamespaces {
    OrderStore?: OrderStore;
    FormStore?: FormStore;
    order: Order;
}

@inject(OrderStore.NAME_STORE, FormStore.NAME_STORE)
@observer
class SendEmailViewModel extends React.Component<ISendEmailViewModelProps, {}> {
    @observable
    private loading: boolean = false;

    @observable
    private email: string = "";

    get orderStore(): OrderStore {
        return this.props.OrderStore as OrderStore;
    }

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    protected getEmail = (): string => {
        return this.email;
    }

    protected setEmail = (value: string) => {
        this.email = value;
    }

    public constructor(props: ISendEmailViewModelProps) {
        super(props);
        this.formStore.initValidator(props.t);
    }

    protected sendEmail = async () => {
        const {order} = this.props;

        if (!this.validate()) return;

        this.loading = true;
        await this.orderStore.sendEmail(order, this.getEmail());
        this.email   = "";
        this.loading = false;
    }

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    public render(): React.ReactNode {
        if (this.loading)
            return (<LinearProgress className="mt-4 mb-4"/>);

        const {order} = this.props;

        return (
            <SendEmailView
                order={order}
                email={this.getEmail()}
                setEmail={this.setEmail}
                sendEmail={this.sendEmail}
                validator={this.formStore.getValidator()}
            />
        );
    }
}

export default withNamespaces("forms")(SendEmailViewModel);
