import * as React                                               from "react";
import Tabs                                                     from "@material-ui/core/Tabs";
import Tab                                                      from "@material-ui/core/Tab";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Row, Col }                                             from "reactstrap";
import SimpleReactValidator                                     from "simple-react-validator";
import { observer }                                             from "mobx-react";
import { withNamespaces, WithNamespaces }                       from "react-i18next";
import JSONInput                                                from "react-json-editor-ajrm";
// @ts-ignore
import locale                                                   from "react-json-editor-ajrm/locale/en";
import { TabPanel }                                             from "../../../Components/TabPanel/TabPanel";
import { GroupButtons }                                         from "../../../Components/GroupButtons/GroupButtons";
import { Category }                                             from "../../../../Models/Product/Category";

interface ICreateOrEditCategoryViewProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    category: Category;
    validate: () => boolean;
    spinner: boolean;
    save: () => void;
    cancel: () => void;
    validator: SimpleReactValidator;
    categories: Category[];
}

@observer
class CreateOrEditCategoryView extends React.Component<ICreateOrEditCategoryViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  category,
                  spinner,
                  cancel,
                  validate,
                  save,
                  validator,
                  categories,
                  t
              } = this.props;

        return (
            <div className={``}>
                <form noValidate autoComplete="off">
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setCurrentTab(newValue);
                        }} aria-label="tabs"
                    >
                        <Tab label={t("edit.general")}/>
                    </Tabs>
                    <hr/>
                    <TabPanel value={currentTab} index={0}>
                        <Row>
                            <Col xs={12} md={6} xl={4}>
                                <FormControl fullWidth variant="outlined" className="">
                                    <InputLabel htmlFor="subcategories" variant="outlined">
                                        {t("edit.category")}
                                    </InputLabel>
                                    <Select
                                        variant="outlined"
                                        required
                                        fullWidth
                                        value={category.getCategoryId() || ""}
                                        onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                                            category.setCategoryId(event.target.value as string);
                                        }}
                                        inputProps={{
                                            id  : "subcategories",
                                            name: "subcategories",
                                        }}
                                    >
                                        <MenuItem value="">
                                            None
                                        </MenuItem>
                                        {
                                            categories.map((item: Category) => {
                                                return (
                                                    <MenuItem value={item.getId()} key={item.getId()}>
                                                        {item.getName()}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.name")}
                                    className={""}
                                    value={category.getName() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        category.setName(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.name"), category.getName(), "required")}
                                <TextField
                                    fullWidth
                                    label={t("edit.order")}
                                    className={""}
                                    value={category.getOrder() || 2000}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        category.setOrder(parseFloat(evt.currentTarget.value));
                                    }}
                                    margin="normal"
                                    type="number"
                                />
                                <JSONInput
                                    placeholder={category.getStyle() || {}}
                                    locale={locale}
                                    height={"200px"}
                                    width={"100%"}
                                    onChange={(e: any) => {
                                        category.setStyle(e.jsObject || {});
                                    }}
                                />
                            </Col>
                        </Row>
                    </TabPanel>
                    <hr/>
                    <GroupButtons
                        action={save}
                        spinner={spinner}
                        validate={validate}
                        cancel={cancel}
                    />
                </form>
            </div>
        );
    }
}

export default withNamespaces("page")(CreateOrEditCategoryView);
