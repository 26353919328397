import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { ActionStore }                     from "../../../Store/ActionStore";
import ActionItemView                      from "../../../Views/Pages/Action/Item/ActionItemView";
import { CronJobStatus }                   from "../../../Models/CronJobStatus/CronJobStatus";
import { observable }                      from "mobx";
import { CronJobList }                     from "../../../config/cronJobList";

interface IActionItemViewModelProps extends RouteComponentProps<any> {
    ActionStore?: ActionStore;
    actionName: CronJobList;
    getActionName: string;
}

@inject(ActionStore.NAME_STORE)
@observer
class ActionItemViewModel extends React.Component<IActionItemViewModelProps, {}> {
    @observable
    private cronJobStatus: CronJobStatus | undefined;

    @observable
    private spinner: boolean = true;

    private interval: number;

    get actionStore(): ActionStore {
        return this.props.ActionStore as ActionStore;
    }

    public componentDidMount = async () => {
        await this.refreshCronJobStatus();
        this.interval = window.setInterval(async () => {
            await this.refreshCronJobStatus();
        }, 1000 * 2);
    };

    public componentWillUnmount() {
        clearInterval(this.interval);
    }

    protected executeAction = async () => {
        const {
                  actionName,
              } = this.props;

        if (this.spinner) return;

        this.spinner = true;
        await this.actionStore.doAction(actionName);
        await this.refreshCronJobStatus();
        this.spinner = false;
    };

    protected refreshCronJobStatus = async () => {
        const {
                  getActionName,
              } = this.props;

        this.cronJobStatus = await this.actionStore.getByName(getActionName);
        this.spinner       = false;
    };

    public render(): React.ReactNode {
        const {
                  getActionName,
                  actionName,
              } = this.props;

        return (
            <ActionItemView
                actionName={actionName}
                cronJobStatus={this.cronJobStatus}
                spinner={this.spinner}
                executeAction={this.executeAction}
                getActionName={getActionName}
            />
        );
    }
}

export default withRouter(ActionItemViewModel);
