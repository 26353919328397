import * as React                         from "react";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
}                                         from "@material-ui/core";
import { withNamespaces, WithNamespaces } from "react-i18next";
import AddIcon                            from "@material-ui/icons/Add";
import InjectPrefix              from "../../../../Decorators/InjectPrefix";
import { Button }                 from "../../../Components/Button/Button";
import Pagination
                                  from "../../../Components/Pagination/Pagination";
import { ContactUs }              from "../../../../Models/ContactUs/ContactUs";
import TableRowContactUsViewModel from "../../../../ViewModel/ContactUs/View/TableRowContactUsViewModel";

interface IContactUsViewProps extends WithNamespaces {
    contactUss: ContactUs[];
    spinner: boolean;
    prefix?: string;
    pages: number;
    currentPage: number;
    getPage: (page: number) => void;
}

@InjectPrefix
class ContactUsView extends React.Component<IContactUsViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderBody = (): React.ReactNode => {
        const {contactUss} = this.props;

        return contactUss.map((contactUs: ContactUs, key: number) => (
            <TableRowContactUsViewModel
                contactUs={contactUs}
                key={key}
            />
        ));
    };

    protected renderPagination = (): React.ReactNode => {
        const {
                  spinner,
                  pages,
                  currentPage,
                  getPage,
              } = this.props;

        return <Pagination
            className="mt-4"
            pages={pages}
            currentPage={currentPage}
            getPage={!spinner ? getPage : () => {}}
        />;
    };

    public render(): React.ReactNode {
        const {
                  spinner,
                  t,
              }            = this.props;

        return (
            <>
                <h3>
                    {t("contact-us")}
                </h3>
                <div className="d-flex align-items-end flex-column mb-3"/>
                <Table className={`${this.prefix}-table-info`}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("edit.name")}</TableCell>
                            <TableCell>{t("edit.lastname")}</TableCell>
                            <TableCell>{t("edit.email")}</TableCell>
                            <TableCell>{t("edit.wishContact")}</TableCell>
                            <TableCell>{t("edit.message")}</TableCell>
                            <TableCell>{t("edit.createdAt")}</TableCell>
                            <TableCell>{t("edit.updatedAt")}</TableCell>
                            <TableCell align="right">{t("edit.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderBody()}
                    </TableBody>
                </Table>
                {this.renderPagination()}
                {
                    spinner &&
                    <LinearProgress className="mt-4 mb-4"/>
                }
            </>
        );
    }
}

export default withNamespaces("page")(ContactUsView);
