import { observer }                    from "mobx-react";
import * as React                      from "react";
import { ProductRelationship }         from "../../../../Models/Product/ProductRelationship";
import AutoSuggestProductViewModel
                                       from "../../../../ViewModel/AutoSuggestProductViewModel/AutoSuggestProductViewModel";
import { ProductRelationshipInternal } from "../../../../Models/Product/ProductRalationshipInternal";
import { Product }                     from "../../../../Models/Product/Product";
import { Button }                      from "../../../Components/Button/Button";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
}                                      from "@material-ui/core";
import {
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon,
}                                      from "@material-ui/icons";
import {
    withNamespaces,
    WithNamespaces
}                                      from "react-i18next";
import NumberFormat                    from "react-number-format";
import InjectPrefix                    from "../../../../Decorators/InjectPrefix";

interface IProductRelationshipViewProps extends WithNamespaces {
    productSelected: Product;
    setProductSelected: (item: Product) => void;
    addProductRelationShip: (item: Product) => void;
    removeProductRelationShip: (item: Product) => void;
    reorderProductRelationShip: (startIndex: number, endIndex: number) => void;
    productRelationship: ProductRelationship;
    productRelationshipInternal: ProductRelationshipInternal;
    prefix?: string;
}

@InjectPrefix
@observer
class ProductRelationshipView extends React.Component<IProductRelationshipViewProps, {}> {
    protected get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderProducts = (): React.ReactNode => {
        const {
                  productRelationshipInternal,
                  removeProductRelationShip,
                  reorderProductRelationShip,
              }
                  = this.props;

        return productRelationshipInternal.getProductsRelationship().map((value: Product, index: number) => {
            const buttons: React.ReactNode[] = [
                <Button
                    key={"delete"}
                    variant={"outlined"}
                    color={"primary"}
                    colorSpinner={"primary"}
                    size={"medium"}
                    text={"Eliminar"}
                    onClick={() => removeProductRelationShip(value)}
                    spinner={false}
                />,
            ];

            if (index !== productRelationshipInternal.getProductsRelationship().length) {
                buttons.push(
                    <Button
                        key={"up"}
                        variant={"outlined"}
                        color={"primary"}
                        colorSpinner={"primary"}
                        size={"medium"}
                        text={<ArrowDownwardIcon/>}
                        onClick={() => reorderProductRelationShip(index, index + 1)}
                        spinner={false}
                    />
                );
            }

            if (index !== 0) {
                buttons.push(
                    <Button
                        key={"down"}
                        variant={"outlined"}
                        color={"primary"}
                        colorSpinner={"primary"}
                        size={"medium"}
                        text={<ArrowUpwardIcon/>}
                        onClick={() => reorderProductRelationShip(index, index - 1)}
                        spinner={false}
                    />
                );
            }

            return (
                <TableRow key={value.getId()}>
                    <TableCell>
                        <img src={value.getImageFullPath()} className="image" alt=""/>
                    </TableCell>
                    <TableCell>{value.getCode()}</TableCell>
                    <TableCell>{value.getName()}</TableCell>
                    <TableCell>{value.getBrand()}</TableCell>
                    <TableCell>
                        <NumberFormat
                            displayType="text"
                            value={value.getPrice()}
                            thousandSeparator={true}
                            prefix={"¢"}
                            renderText={(formattedValue: string) => formattedValue}
                        />
                    </TableCell>
                    <TableCell align="right">
                        {buttons}
                    </TableCell>
                </TableRow>
            );
        });
    };

    public render(): React.ReactNode {
        const {
                  productSelected,
                  setProductSelected,
                  addProductRelationShip,
                  t,
              } = this.props;

        return (
            <section>
                <AutoSuggestProductViewModel
                    productSelected={productSelected}
                    setProductSelected={setProductSelected}
                />
                {productSelected ? <Button
                    variant={"outlined"}
                    color={"primary"}
                    colorSpinner={"primary"}
                    size={"medium"}
                    text={"Agregar"}
                    onClick={() => addProductRelationShip(productSelected)}
                    spinner={false}
                /> : <React.Fragment/>}
                <Table className={`${this.prefix}-table-products`}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("edit.image")}</TableCell>
                            <TableCell>{t("edit.code")}</TableCell>
                            <TableCell>{t("edit.name")}</TableCell>
                            <TableCell>{t("edit.brand")}</TableCell>
                            <TableCell>{t("edit.price")}</TableCell>
                            <TableCell align="right">{t("edit.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderProducts()}
                    </TableBody>
                </Table>
            </section>
        );
    }
}

export default withNamespaces("page")(ProductRelationshipView);