import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import TableRowAboutUsView                 from "../../../Views/Components/TableRowAboutUs/TableRowAboutUsView";
import { AboutUs }                         from "../../../Models/AboutUs/AboutUs";
import DeleteAboutUsViewModel              from "../Delete/DeleteAboutUsViewModel";

interface ITableRowAboutUsViewModelProps extends RouteComponentProps<any> {
    aboutUs: AboutUs;
}

class TableRowAboutUsViewModel extends React.Component<ITableRowAboutUsViewModelProps, {}> {
    protected onEdit = () => {
        const {aboutUs, history} = this.props;
        history.push(renderRoute(PageNames.aboutUsEditPage, {id: aboutUs.getId()}));
    };

    protected onDelete = () => {
        const {aboutUs} = this.props;
        Container.get(ModalService).openModal(DeleteAboutUsViewModel, {
            aboutUs
        });
    };

    public render(): React.ReactNode {
        const {aboutUs} = this.props;

        return (
            <TableRowAboutUsView
                aboutUs={aboutUs}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowAboutUsViewModel);
