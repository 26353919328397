import { observable }  from "mobx";
import {
    list,
    primitive,
    serializable,
}                      from "serializr";
import { OrderStatus } from "../../config/orderStatus";
import { MethodsPay }  from "../../config/MethodsPay";

export class OrderFilter {
    @serializable
    @observable
    private nameClient: string = "";

    @serializable(list(primitive()))
    @observable
    private orderStatus: OrderStatus[] = Object.values(OrderStatus);

    @serializable(list(primitive()))
    @observable
    private methodPay: MethodsPay[] = Object.values(MethodsPay);

    @serializable(list(primitive()))
    @observable
    private methodsTransportId: string[] = [];

    public getNameClient(): string {
        return this.nameClient || "";
    }

    public setNameClient(value: string) {
        this.nameClient = value;
    }

    public getOrderStatus(): OrderStatus[] {
        return this.orderStatus;
    }

    public setOrderStatus(value: OrderStatus[]) {
        this.orderStatus = value;
    }

    public getMethodPay(): MethodsPay[] {
        return this.methodPay;
    }

    public setMethodPay(value: MethodsPay[]) {
        this.methodPay = value;
    }

    public getMethodsTransportId = (): string[] => {
        return this.methodsTransportId;
    };

    public setMethodsTransportId = (value: string[]) => {
        this.methodsTransportId = value;
    };
}