import AccessToken                from "../Models/Security/AccessToken";
import User                       from "../Models/User/User";
import { deserialize, serialize } from "serializr";
import { Container, Service }     from "typedi";
import AxiosService               from "./AxiosService";
import { UserStore }              from "../Store/UserStore";
import { TokenStore }             from "../Store/TokenStore";
import { ApplicationStore }       from "../Store/ApplicationStore";
import { generatePath }           from "react-router";
import AjaxService                from "./AjaxService";
import { Login }                  from "../Models/Form/Login";

@Service()
export default class AuthService {
    private getAxiosService(): AxiosService {
        return Container.get(AxiosService);
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    private getApplicationStore(): ApplicationStore {
        return Container.get(ApplicationStore);
    }

    private getUserStore(): UserStore {
        return this.getApplicationStore().getStore(UserStore);
    }

    private getTokenStore(): TokenStore {
        return this.getApplicationStore().getStore(TokenStore);
    }

    public async getAccessToken(login: Login, cb?: (err: boolean, user: User | undefined) => void): Promise<false | User> {
        const accessToken = await this.getAxiosService().getAccessToken(serialize(login));

        // Access Token Invalid
        if (!accessToken) {
            return false;
        }

        const token = deserialize(AccessToken, accessToken);

        if (this.getTokenStore())
            this.getTokenStore().setAccessToken(token);

        await this.getUserStore().getUserMeApi();

        return token.getUser();
    }

    public redirectToLogin() {
        window.location.href = "/login";
    }

    public async logout() {
        await this.getTokenStore().logout();

        window.location.href = generatePath("/");
    }

    public isAuthenticate(): boolean {
        return !!this.getUserStore().getUser();
    }

    public getUser(): User | undefined {
        return this.getUserStore().getUser();
    }
}
