import moment from "moment";

moment.locale("es");

export default {
    calendarDate : "DD-MM-YYYY",
    fullDate     : "DD-MM-YY h:mm:ss a",
    monthDay     : "MMM D",
    shortDate    : "MMM D, YY",
    shortDateYear: "MMM D, YYYY"
};
