import * as React               from "react";
import {
    inject,
    observer
}                               from "mobx-react";
import {
    RouteComponentProps,
    withRouter
}                               from "react-router";
import { OrderStore }           from "../../../Store/OrderStore";
import { observable }           from "mobx";
import { MethodTransportStore } from "../../../Store/LoadStore/LoadStore";
import OrderDetailView          from "../../../Views/Pages/Order/View/OrderDetailView";
import { LinearProgress }       from "@material-ui/core";
import { OrderStatus }          from "../../../config/orderStatus";

interface IOrderDetailViewModelProps extends RouteComponentProps<any> {
    OrderStore?: OrderStore;
}

@inject(OrderStore.NAME_STORE, MethodTransportStore.NAME_STORE)
@observer
class OrderDetailViewModel extends React.Component<IOrderDetailViewModelProps, {}> {
    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    constructor(props: IOrderDetailViewModelProps) {
        super(props);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }
    }

    get orderStore(): OrderStore {
        return this.props.OrderStore as OrderStore;
    }

    public componentDidMount = async (): Promise<void> => {
        const {id}  = this.props.match.params,
              order = await this.orderStore.getById(id);

        this.orderStore.setOrder(order);
        this.loading = false;
    };

    protected changeStatus = async (status: OrderStatus) => {
        this.spinner = true;

        this.orderStore.setOrder(await this.orderStore.updateStatus(this.orderStore.getOrder(), status));

        this.spinner = false;
    };

    public render(): React.ReactNode {
        if (this.loading || !this.orderStore.getOrder())
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (!this.orderStore.getOrder())
            return (<React.Fragment/>);

        return (
            <OrderDetailView
                order={this.orderStore.getOrder()}
                spinner={this.spinner}
                changeStatus={this.changeStatus}
            />
        );
    }
}

export default withRouter(OrderDetailViewModel);
