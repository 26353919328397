import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { Button }               from "../Button/Button";
import { observer }             from "mobx-react";
import DraggableList            from "react-draggable-list";
import RowInformation           from "./RowInformation";
import SimpleReactValidator     from "simple-react-validator";
import { Promotion }            from "../../../Models/Promotion/Promotion";
import { PromotionInformation } from "../../../Models/Promotion/PromotionInformation";

interface IGalleryImagesViewProps extends WithNamespaces {
    prefix?: string;
    promotion: Promotion;
    addItem: (ele: React.Component) => void;
    removeItem: (item: PromotionInformation, ele: React.Component) => void;
    validator: SimpleReactValidator;
}

@InjectPrefix
@observer
class PromotionInformationView extends React.Component<IGalleryImagesViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected removeItem = (item: PromotionInformation) => {
        const {removeItem} = this.props;

        removeItem(item, this);
    };

    protected renderProvided = (promotion: Promotion, validator: SimpleReactValidator): React.ReactElement<HTMLElement> => {
        return (
            <DraggableList
                itemKey={(item: PromotionInformation) => item.getKey()}
                template={RowInformation as (new (props: any, context?: any) => React.Component)}
                list={promotion.getInformation()}
                onMoveEnd={items => promotion.setInformation(items as PromotionInformation[])}
                commonProps={{
                    removeItem: this.removeItem,
                    validator,
                }}
            />
        );
    };

    public render(): React.ReactNode {
        const {
                  addItem,
                  promotion,
                  validator
              } = this.props;

        return (
            <div className={`${this.prefix}-gallery-image`}>
                {this.renderProvided(promotion, validator)}
                <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"medium"}
                    text={"Add"}
                    onClick={() => addItem(this)}
                    spinner={false}
                />
            </div>
        );
    }
}

export default withNamespaces("page")(PromotionInformationView);
