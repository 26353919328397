import * as React             from "react";
import { Switch, withRouter } from "react-router-dom";
import { routesList }         from "../../../Routes/routes";
import Route                  from "../Route/Route";
import HeaderViewModel        from "../../../ViewModel/Header/HeaderViewModel";
import FooterViewModel        from "../../../ViewModel/Footer/FooterViewModel";
import { ThemeProvider }      from "@material-ui/styles";
import { theme }              from "../../../resources/generator/theme";
import Error404ViewModel      from "../../../ViewModel/Error/Error404ViewModel";
import ManageModalViewModel   from "../../../ViewModel/ManageModal/ManageModalViewModel";
import { prefix }             from "../../../Utils/prefix";
import { Container }          from "typedi";
import { ApplicationStore }   from "../../../Store/ApplicationStore";
import { ConfigurationStore } from "../../../Store/ConfigurationStore";

class ScrollToTop extends React.Component<any, {}> {
    private configurationStore: ConfigurationStore;

    constructor(props: any) {
        super(props);
        this.configurationStore = Container.get(ApplicationStore).getStore(ConfigurationStore);
    }

    public componentDidUpdate(prevProps: any) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
            this.configurationStore.setAnchorEl(undefined);
        }
    }

    public render(): React.ReactNode {
        return this.props.children;
    }
}

// tslint:disable-next-line:variable-name
const ScrollToTopRouter = withRouter(ScrollToTop);

class Layout extends React.Component<{}, {}> {
    /**
     * render
     */
    public render(): React.ReactNode {
        return (
            <React.Fragment>
                <main className={`${prefix}-quinary-color-background`}>
                    <ScrollToTopRouter>
                        <ThemeProvider theme={theme}>
                            <HeaderViewModel/>
                            <div style={{
                                minHeight: "80vh"
                            }}>
                                <Switch>
                                    {routesList.map((page) =>
                                                        <Route
                                                            exact={page.exact}
                                                            auth={page.auth}
                                                            path={page.path}
                                                            key={page.name}
                                                            name={page.name}
                                                            component={page.component}
                                                            middleware={page.middleware}
                                                            options={page.options}
                                                        />
                                    )}
                                    <Route component={Error404ViewModel}/>
                                </Switch>
                            </div>
                            <FooterViewModel/>
                            <ManageModalViewModel/>
                        </ThemeProvider>
                    </ScrollToTopRouter>
                </main>
            </React.Fragment>
        );
    }
}

export default Layout;
