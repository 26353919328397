import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import PageView                       from "../../../Views/Pages/Page/View/PageView";
import { PageStore }                  from "../../../Store/PageStore";

interface IPageViewModelProps extends RouteComponentProps<any> {
    PageStore?: PageStore;
}

@inject(PageStore.NAME_STORE)
@observer
class PageViewModel extends React.Component<IPageViewModelProps, {}> {
    get pageStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.pageStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.pageStore.getPaginate();

        this.pageStore
            .setPages(response.pages)
            .setPagesSeo(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <PageView
                pagesSeo={this.pageStore.getPagesSeo()}
                spinner={this.pageStore.getSpinner()}
                add={() => history.push(renderRoute(PageNames.pageAddPage))}
                getPage={this.getPage}
                pages={this.pageStore.getPages()}
                currentPage={this.pageStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(PageViewModel);
