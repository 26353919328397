import { custom, list, object, serializable } from "serializr";
import { observable }                         from "mobx";
import { date }                               from "../../Serializer/date";
import { PriceByRange }                       from "./PriceByRange";
import { removeItem }                         from "../../Utils/array";

export class MethodTransport {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private slug: string = "";

    @serializable
    @observable
    private name: string = "";

    @serializable(list(object(PriceByRange)))
    @observable
    private priceByKmRange: PriceByRange[] = [];

    @serializable
    @observable
    private minWeight: number = 0;

    @serializable
    @observable
    private maxWeight: number = 0;

    @serializable
    @observable
    private active: boolean;

    @serializable
    @observable
    private pickup: boolean = false;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getSlug(): string {
        return this.slug;
    }

    public setSlug(value: string) {
        this.slug = value;
    }

    public getName(): string {
        return this.name || "";
    }

    public setName(value: string) {
        this.name = value;
    }

    public getActive(): boolean {
        return this.active;
    }

    public setActive(value: boolean) {
        this.active = value;
    }

    public getPickUp(): boolean {
        return this.pickup;
    }

    public setPickUp(value: boolean) {
        this.pickup = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getMinWeight(): number {
        return this.minWeight;
    }

    public setMinWeight(value: number) {
        this.minWeight = value;
    }

    public getMaxWeight(): number {
        return this.maxWeight;
    }

    public setMaxWeight(value: number) {
        this.maxWeight = value;
    }

    public getPriceByKmRange(): PriceByRange[] {
        return this.priceByKmRange;
    }

    public setPriceByKmRange(value: PriceByRange[]): this {
        this.priceByKmRange = value;

        return this;
    }

    public addPriceByKmRange(value: PriceByRange): this {
        this.priceByKmRange.push(value);

        return this;
    }

    public removePriceByKmRange(value: PriceByRange): this {
        this.priceByKmRange = removeItem(this.priceByKmRange, value, "uuid" as keyof PriceByRange);

        return this;
    }
}
