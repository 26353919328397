import { serializable } from "serializr";

export class Card {
    @serializable
    private last4: string;

    @serializable
    private brand: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private exp_year: number;

    @serializable
    // doesn't exist
    private cvc: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private exp_month: number;

    public getLast4(): string {
        return this.last4;
    }

    public setLast4(value: string) {
        this.last4 = value;
    }

    public getBrand(): string {
        return this.brand;
    }

    public setBrand(value: string) {
        this.brand = value;
    }

    public getExpYear(): number {
        return this.exp_year;
    }

    public setExpYear(value: number) {
        this.exp_year = value;
    }

    public getCvc(): string {
        return this.cvc;
    }

    public setCvc(value: string) {
        this.cvc = value;
    }

    public getExpMonth(): number {
        return this.exp_month;
    }

    public setExpMonth(value: number) {
        this.exp_month = value;
    }
}