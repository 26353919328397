import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import MethodTransportView                        from "../../../Views/Pages/MethodTransport/View/MethodTransportView";
import { MethodTransportStore }            from "../../../Store/MethodTransportStore";

interface ICategoryViewModelProps extends RouteComponentProps<any> {
    MethodTransportStore?: MethodTransportStore;
}

@inject(MethodTransportStore.NAME_STORE)
@observer
class MethodTransportViewModel extends React.Component<ICategoryViewModelProps, {}> {
    get methodTransportStore(): MethodTransportStore {
        return this.props.MethodTransportStore as MethodTransportStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.methodTransportStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.methodTransportStore.getPaginate();

        this.methodTransportStore
            .setPages(response.pages)
            .setMethodTransports(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <MethodTransportView
                methodTransports={this.methodTransportStore.getMethodTransports()}
                spinner={this.methodTransportStore.getSpinner()}
                add={() => history.push(renderRoute(PageNames.methodTransportAddPage))}
                getPage={this.getPage}
                pages={this.methodTransportStore.getPages()}
                currentPage={this.methodTransportStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(MethodTransportViewModel);
