import * as React                         from "react";
import { observer }                       from "mobx-react";
import { Search }                         from "@material-ui/icons";
import { withNamespaces, WithNamespaces } from "react-i18next";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { Product }                        from "../../../Models/Product/Product";
import { AutoSuggest }                    from "../AutoSuggest/AutoSuggest";
import { matchTextInString }              from "../../../Utils/common";
import AutoSuggestHighlightParse from "autosuggest-highlight/parse";

interface IAutoSuggestProductProps extends WithNamespaces {
    productSelected: Product;
    setProductSelected: (product: Product) => void;
    products: Product[];
    userTyping: string;
    setUserTyping: (val: string) => void;
    onSuggestionsClear: () => void;
    onSuggestionsFetch: ({value}: any) => void;
    onChange: (ev: React.FormEvent<any>, {newValue}: any) => void;
    prefix?: string;
}

@InjectPrefix
@observer
class AutoSuggestProduct extends React.Component<IAutoSuggestProductProps, {}> {
    protected get prefix(): string {
        return this.props.prefix as string;
    }

    private getSuggestionValue(product: Product) {
        return product.getName();
    }

    private renderItem = (product: Product, {query}: any): React.ReactNode => {
        const {
                  t,
                  products,
                  setProductSelected
              } = this.props;

        if (typeof product !== "object") {
            return (
                <div className="search-item no-results" onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
                    ev.stopPropagation();
                }}>
                    <p className={`${this.prefix}-label-secondary my-0`}>
                        {t("no-results")}
                    </p>
                </div>
            );
        }

        let template = (<></>);

        const matches = matchTextInString(product.getName(), query),
              parts   = AutoSuggestHighlightParse(product.getName(), matches);

        if (products.length > 0) {
            template = (
                <div className={`product-item`}
                     onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
                         ev.preventDefault();
                         if (product) setProductSelected(product);
                     }}>

                    <p className="name d-sm-inline my-0">
                        {
                            parts.map((part: any, index: number) => {
                                const highlightText = part.highlight ? `${this.prefix}-titles-bold-font` : `${this.prefix}-paragraph-tertiary`;
                                return (
                                    <span
                                        className={`${highlightText} no-margin`}
                                        key={index}>{part.text}</span>
                                );
                            })
                        }
                    </p>
                </div>
            );
        }

        return template;
    };


    public render(): React.ReactNode {
        const {
                  products,
                  userTyping,
                  onSuggestionsClear,
                  onSuggestionsFetch,
                  onChange,
              } = this.props;

        return (
            <AutoSuggest
                className={`${this.prefix}-auto-suggest-product`}
                suggestions={products.length > 0 ? products : [""]}
                value={userTyping}
                placeholder={""}
                icon={{
                    className: "icon-search-employee",
                    component: <Search/>
                }}
                getSuggestion={products.length > 0 ? this.getSuggestionValue : () => {}}
                onChange={onChange}
                onSuggestionFetch={onSuggestionsFetch}
                onSuggestionsClear={onSuggestionsClear}
                renderItem={this.renderItem}
                // showSuggestionsAlways={true}
            />
        );
    }
}

export default withNamespaces("global")(AutoSuggestProduct);