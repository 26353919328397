import { PromotionInformation } from "./PromotionInformation";
import {
    object,
    serializable,
    list,
    custom,
}                     from "serializr";
import { observable } from "mobx";
import { Gallery }    from "../Gallery/Gallery";
import { removeItem } from "../../Utils/array";
import { date }       from "../../Serializer/date";
import moment         from "moment";
import { SeoData }    from "../Page/SeoData";

export class Promotion {
    @serializable
    @observable
    private _id: string;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private dateInit: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private dateEnd: Date;

    @serializable
    @observable
    private active: boolean;

    @serializable
    @observable
    private name: string;

    @serializable
    @observable
    private actualPrice: number;

    @serializable
    @observable
    private lastPrice: number;

    @serializable
    @observable
    private shortDescription: string;

    @serializable
    @observable
    private description: string;

    @serializable
    @observable
    private slug: string;

    @serializable(object(Gallery))
    @observable
    private gallery: Gallery;

    @observable
    @serializable(list(object(PromotionInformation)))
    private information: PromotionInformation[] = [];

    @serializable(object(SeoData))
    @observable
    private seoData: SeoData;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    constructor() {
        this.dateInit = new Date();
        this.dateEnd  = moment().add("1", "month").toDate();
        this.setSeoData(new SeoData());
    }

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getDateInit(): Date {
        return this.dateInit;
    }

    public setDateInit(value: Date) {
        this.dateInit = value;
    }

    public getDateEnd(): Date {
        return this.dateEnd;
    }

    public setDateEnd(value: Date) {
        this.dateEnd = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getActualPrice(): number {
        return this.actualPrice;
    }

    public setActualPrice(value: number) {
        this.actualPrice = value;
    }

    public getLastPrice(): number {
        return this.lastPrice;
    }

    public setLastPrice(value: number) {
        this.lastPrice = value;
    }

    public getShortDescription(): string {
        return this.shortDescription;
    }

    public setShortDescription(value: string) {
        this.shortDescription = value;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(value: string) {
        this.description = value;
    }

    public getSlug(): string {
        return this.slug;
    }

    public setSlug(value: string) {
        this.slug = value;
    }

    public getGallery(): Gallery {
        return this.gallery;
    }

    public setGallery(value: Gallery) {
        this.gallery = value;
    }

    public getInformation(): PromotionInformation[] {
        return this.information;
    }

    public setInformation(value: PromotionInformation[]) {
        this.information = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public addItem(value: PromotionInformation) {
        this.information.push(value);
    }

    public removeItem(value: PromotionInformation) {
        removeItem(this.information, value, "key" as keyof PromotionInformation);
    }

    public getActive(): boolean {
        return this.active;
    }

    public setActive(value: boolean) {
        this.active = value;
    }

    public getSeoData(): SeoData {
        return this.seoData;
    }

    public setSeoData(value: SeoData) {
        this.seoData = value;
    }
}
