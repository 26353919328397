import { Roles }        from "../../config/security";
import { serializable } from "serializr";

export default class Role {
    @serializable
    private description: string;

    @serializable
    private displayName: string;

    @serializable
    private name: Roles;

    public getDescription(): string {
        return this.description;
    }

    public setDescription(value: string) {
        this.description = value;
    }

    public getDisplayName(): string {
        return this.displayName;
    }

    public setDisplayName(value: string) {
        this.displayName = value;
    }

    public getName(): Roles {
        return this.name;
    }

    public setName(value: Roles) {
        this.name = value;
    }

    constructor(role?: Role) {
        if (role) {
            Object.assign(this, role);
        }
    }
}
