import * as React                   from "react";
import { Route as RouteRouter }     from "react-router";
import { Container }                from "typedi";
import { MiddlewareHandlerService } from "../../../Service/MiddlewareHandlerService";
import { AuthMiddleware }           from "../../../Middleware/AuthMiddleware";
import { inject }                   from "mobx-react";
import { ConfigurationStore }       from "../../../Store/ConfigurationStore";
import BaseMiddleware               from "../../../Middleware/Base/BaseMiddleware";
import { Paper }                    from "../Paper/Paper";
import { RoleMiddleware }           from "../../../Middleware/RoleMiddleware";
import { Roles }                    from "../../../config/security";

@inject(ConfigurationStore.NAME_STORE)
export default class Route extends React.Component<any> {
    get configurationStore(): ConfigurationStore {
        return this.props.ConfigurationStore as ConfigurationStore;
    }

    public resetFooterHeader() {
        this.configurationStore
            .getConfiguration()
            .setShowFooter(true)
            .setShowHeader(true)
            .setShowMenuLogo(true);
    }

    public constructor(props: any) {
        super(props);

        this.resetFooterHeader();
    }

    public componentDidMount(): void {
        const options = this.props.options || {},
              {name}  = this.props;

        this.configurationStore.setCurrentRolePage(options.currentRole || "")
            .setCurrentPage(name);
    }

    render() {
        const {
                  auth,
                  component: COMPONENT,
                  middleware,
                  options = {needPaper: true},
                  ...rest
              }                 = this.props,
              middlewareService = Container.get(MiddlewareHandlerService),
              authMiddleware    = middlewareService.getMiddleware(AuthMiddleware.getAlias()),
              roleMiddleware    = middlewareService.getMiddleware(RoleMiddleware.getAlias());

        return (
            <RouteRouter {...rest} render={(props) => {
                if (auth) {
                    // validate if logged
                    if (!authMiddleware.validate())
                        return authMiddleware.reject(props);

                    // contain Role Admin
                    if (!roleMiddleware.validate({roles: [Roles.ADMIN]}))
                        return roleMiddleware.reject(props);
                }

                if (middleware) {
                    for (const item of middleware) {
                        (item as BaseMiddleware).setMatch(props.match);

                        const valid = (item as BaseMiddleware).validate(options);

                        if (!valid) return (item as BaseMiddleware).reject(props);
                    }
                }
                const component = <COMPONENT {...props} />;

                this.configurationStore.setOpenMenuLeft(false);

                return options.needPaper
                       ? (
                           <Paper>
                               {component}
                           </Paper>
                       )
                       : component;
            }}/>
        );
    }
}
