import { observable }    from "mobx";
import { configuration } from "../mockdata/configuration";
import { deserialize }   from "serializr";
import Configuration     from "../Models/Configuration/Configuration";
import BaseStore         from "./Base/BaseStore";
import { persist }       from "mobx-persist";

export class ConfigurationStore extends BaseStore {
    public static readonly NAME_STORE: string = "ConfigurationStore";

    @observable
    private currentRolePage: string;

    @observable
    private currentPage: string;

    @observable
    private anchorEl: HTMLElement | undefined;

    @persist("object", Configuration)
    @observable
    private configuration: Configuration;

    @observable
    private openMenuLeft: boolean;

    protected init() {
        this.needPersistData = false;
        const config         = deserialize(Configuration, configuration).setShowHeader(true).setShowFooter(true);

        this.setConfiguration(config);
    }

    public getConfiguration(): Configuration {
        return this.configuration;
    }

    public setConfiguration(value: Configuration) {
        this.configuration = value;

        return this;
    }

    public getCurrentRolePage(): string {
        return this.currentRolePage;
    }

    public setCurrentRolePage(val: string): this {
        this.currentRolePage = val;

        return this;
    }

    public getCurrentPage(): string {
        return this.currentPage;
    }

    public setCurrentPage(val: string): this {
        this.currentPage = val;

        return this;
    }

    public getAnchorEl = (): HTMLElement | undefined => {
        return this.anchorEl;
    };

    public setAnchorEl = (anchorEl: HTMLElement | undefined) => {
        this.anchorEl = anchorEl;
    };

    public getOpenMenuLeft = (): boolean => {
        return this.openMenuLeft;
    };

    public setOpenMenuLeft = (value: boolean) => {
        this.openMenuLeft = value;
    };

}
