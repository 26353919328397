const prefix   = `api`,
      prefixV1 = `${prefix}/v1`;

export default {
    DELETE_ABOUT_US_BY_ID                 : `${prefixV1}/about-us/:id`,
    DELETE_CATEGORY_BY_ID                 : `${prefixV1}/category/:id`,
    DELETE_COLOR_PALETTE_BY_ID            : `${prefixV1}/color-palette/:id`,
    DELETE_CONTACT_US_BY_ID               : `${prefixV1}/contact-us/:id`,
    DELETE_GALLERY_BY_ID                  : `${prefixV1}/gallery/:id`,
    DELETE_METHOD_TRANSPORT_BY_ID         : `${prefixV1}/method-transport/:id`,
    DELETE_OCCUPATION_BY_ID               : `${prefixV1}/occupation/:id`,
    DELETE_PAGE_BY_ID                     : `${prefixV1}/promotion/:id`,
    DELETE_PRODUCT_BY_ID                  : `${prefixV1}/product/:id`,
    DELETE_PROMOTION_BY_ID                : `${prefixV1}/promotion/:id`,
    DELETE_RECOMMENDATION_PERSON_BY_ID    : `${prefixV1}/recommendation-person/:id`,
    DELETE_TUTORIAL_BY_ID                 : `${prefixV1}/tutorial/:id`,
    DELETE_USER_BY_ID                     : `${prefixV1}/users/:id`,
    GET_ABOUT_US_BY_ID                    : `${prefixV1}/about-us/:id`,
    GET_ACTION                            : `${prefixV1}/action/:name`,
    GET_ACTIVE_METHOD_TRANSPORT           : `${prefixV1}/method-transport/active`,
    GET_ALL_CATEGORIES                    : `${prefixV1}/category/categories/all`,
    GET_ALL_SUBCATEGORIES                 : `${prefixV1}/category/subcategories/all`,
    GET_CATEGORY_BY_ID                    : `${prefixV1}/category/:id`,
    GET_COLOR_PALETTE_BY_ID               : `${prefixV1}/color-palette/:id`,
    GET_CONTACT_US_BY_ID                  : `${prefixV1}/contact-us/:id`,
    GET_GALLERY_BY_ID                     : `${prefixV1}/gallery/:id`,
    GET_ME                                : `${prefixV1}/users/me`,
    GET_METHOD_TRANSPORT_BY_ID            : `${prefixV1}/method-transport/:id`,
    GET_OCCUPATION_BY_ID                  : `${prefixV1}/occupation/:id`,
    GET_ORDER_ID                          : `${prefixV1}/order/:id`,
    GET_PAGE_BY_ID                        : `${prefixV1}/page/:id`,
    GET_PAGE_BY_NAME                      : `${prefixV1}/page/name/:name`,
    GET_PAGINATE_ABOUT_US                 : `${prefixV1}/about-us/paginate/:page/:size`,
    GET_PAGINATE_CATEGORY                 : `${prefixV1}/category/paginate/:page/:size`,
    GET_PAGINATE_COLOR_PALETTE            : `${prefixV1}/color-palette/paginate/:page/:size`,
    GET_PAGINATE_CONTACT_US               : `${prefixV1}/contact-us/paginate/:page/:size`,
    GET_PAGINATE_GALLERIES                : `${prefixV1}/gallery/paginate/:page/:size`,
    GET_PAGINATE_METHOD_TRANSPORT         : `${prefixV1}/method-transport/paginate/:page/:size`,
    GET_PAGINATE_OCCUPATION               : `${prefixV1}/occupation/paginate/:page/:size`,
    GET_PAGINATE_ORDER                    : `${prefixV1}/order/paginate/:page/:size`,
    GET_PAGINATE_PAGE                     : `${prefixV1}/page/paginate/:page/:size`,
    GET_PAGINATE_PRODUCT                  : `${prefixV1}/product/paginate/:page/:size`,
    GET_PAGINATE_PROMOTIONS               : `${prefixV1}/promotion/paginate/:page/:size`,
    GET_PAGINATE_RECOMMENDATION_PERSON    : `${prefixV1}/recommendation-person/paginate/:page/:size`,
    GET_PAGINATE_TUTORIALS                : `${prefixV1}/tutorial/paginate/:page/:size`,
    GET_PAGINATE_USER                     : `${prefixV1}/users/paginate/:page/:size`,
    GET_PRODUCT_BY_ID                     : `${prefixV1}/product/:id`,
    GET_PRODUCT_RELATIONSHIP_BY_ID        : `${prefixV1}/product-relationship/:id`,
    GET_PRODUCT_RELATIONSHIP_BY_PRODUCT_ID: `${prefixV1}/product-relationship/product/:id`,
    GET_PROMOTION_BY_ID                   : `${prefixV1}/promotion/:id`,
    GET_RECOMMENDATION_PERSON_BY_ID       : `${prefixV1}/recommendation-person/:id`,
    GET_ROlES                             : `${prefixV1}/users/roles`,
    GET_SIMPLE_ALL_COLOR_PALETTE_BY_ID    : `${prefixV1}/color-palette/all`,
    GET_TUTORIAL_BY_ID                    : `${prefixV1}/tutorial/:id`,
    GET_USER_BY_ID                        : `${prefixV1}/users/:id`,
    POST_ABOUT_US                         : `${prefixV1}/about-us`,
    POST_ACCESS_TOKEN                     : `${prefix}/oauth/token`,
    POST_ACTION                           : `${prefixV1}/action`,
    POST_CATEGORY                         : `${prefixV1}/category`,
    POST_COLOR_PALETTE                    : `${prefixV1}/color-palette`,
    POST_GALLERY                          : `${prefixV1}/gallery`,
    POST_LOGOUT                           : `${prefix}/oauth/invalidate`,
    POST_METHOD_TRANSPORT                 : `${prefixV1}/method-transport`,
    POST_OCCUPATION                       : `${prefixV1}/occupation`,
    POST_PAGE                             : `${prefixV1}/page`,
    POST_PRODUCT                          : `${prefixV1}/product`,
    POST_PRODUCT_RELATIONSHIP             : `${prefixV1}/product-relationship`,
    POST_PROMOTION                        : `${prefixV1}/promotion`,
    POST_RECOMMENDATION_PERSON            : `${prefixV1}/recommendation-person`,
    POST_SEND_EMAIL_ORDER                 : `${prefixV1}/order/send`,
    POST_TUTORIAL                         : `${prefixV1}/tutorial`,
    POST_USER                             : `${prefixV1}/users`,
    PUT_PRODUCT_RELATIONSHIP              : `${prefixV1}/product-relationship/:id`,
    PUT_STATUS_ORDER                      : `${prefixV1}/order/status`,
    PUT_USER                              : `${prefixV1}/users/:id`,
};
