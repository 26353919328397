import InjectPrefix                 from "../../../Decorators/InjectPrefix";
import { observer }                 from "mobx-react";
import * as React                   from "react";
import Tabs                         from "@material-ui/core/Tabs";
import Tab                          from "@material-ui/core/Tab";
import { TabPanel }                 from "../TabPanel/TabPanel";
import {
    Col,
    Row
}                                   from "reactstrap";
import {
    MenuItem,
    Select,
    TextField
}                                   from "@material-ui/core";
import { galleryPages }             from "../../../config/GalleryPages";
import { pages }                    from "../../../config/Pages";
import UploadImage                  from "../Form/Upload/UploadImage";
import { Page }                     from "../../../Models/Page/Page";
import { Product }                  from "../../../Models/Product/Product";
import SimpleReactValidator         from "simple-react-validator";
import {
    withNamespaces,
    WithNamespaces
}                                   from "react-i18next";
import { SeoData }                  from "../../../Models/Page/SeoData";
import { IImageSelect }             from "../../../Models/Gallery/GalleryItem";
import ProductRelationshipViewModel from "../../../ViewModel/Product/CreateOrEdit/ProductRelationshipViewModel";
import { TranslationFunction }      from "i18next";

// tslint:disable-next-line:variable-name
const TagInput = require("reactjs-tag-input").TagInput;

interface ICreateUpdateProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    page?: Page;
    seoData?: SeoData;
    prefix?: string;
    validator: SimpleReactValidator;
}

@InjectPrefix
@observer
class CreateUpdatePageSeo extends React.Component<ICreateUpdateProps, {}> {
    protected showImage = (seoData: SeoData, type: "og_image" | "twitter_image" | "google_image"): React.ReactNode => {
        let imageSelect: IImageSelect,
            fullPath = "";

        switch (type) {
            case "og_image":
                imageSelect = seoData.getOgImageSelect();
                fullPath    = seoData.getOgImageFullPath();
                break;
            case "twitter_image":
                imageSelect = seoData.getTwitterImageSelect();
                fullPath    = seoData.getTwitterImageFullPath();
                break;
            case "google_image":
                imageSelect = seoData.getGoogleImageSelect();
                fullPath    = seoData.getGoogleImageFullPath();
                break;

            default:
                return <React.Fragment/>;
        }

        if (!imageSelect && !fullPath) return <React.Fragment/>;

        return (<img
            src={imageSelect ? imageSelect.base64 : fullPath}
            className="img-fluid"
            alt="image"
        />);
    };

    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderPage = (page: Page) => {
        const {
                  validator,
                  t,
              } = this.props;

        return (
            <Col xs={12} md={6} xl={4}>
                <Select
                    variant="outlined"
                    required
                    fullWidth
                    value={page.getName() || galleryPages.home}
                    onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                        page.setName(event.target.value as pages);
                    }}
                    inputProps={{
                        id  : "name-gallery",
                        name: "nameGallery",
                    }}
                >
                    {
                        Object.keys(pages).map((item: string) => {
                            return (
                                <MenuItem value={pages[item]} key={item}>
                                    {t(pages[item], {ns: "seoPages"})}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
                {validator.message(t("edit.name"), page.getName(), "required")}
            </Col>
        );
    };

    protected getExtraField = (page: Page | undefined, currentTab: number, t: TranslationFunction) => {
        if (!page) return;

        const productRelationship = page.getExtraData();

        return page && page.getName() === pages.home &&
            (
                <TabPanel value={currentTab} index={4}>
                    <Row>
                        <Col xs={12}>
                            <h3>{t("edit.products-relationship")}</h3>
                            <ProductRelationshipViewModel
                                productRelationship={productRelationship}
                                product={new Product()}
                            />
                        </Col>
                    </Row>
                </TabPanel>
            );

    }

    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  page,
                  validator,
                  t,
              }                = this.props,
              seoData: SeoData = (page ? page.getSeoData() : this.props.seoData) as SeoData;

        if (!seoData) return (<React.Fragment/>);

        return (
            <>
                <Tabs
                    value={currentTab}
                    onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                        setCurrentTab(newValue);
                    }} aria-label="tabs"
                >
                    <Tab label={t("edit.general")}/>
                    <Tab label={t("seo.og")}/>
                    <Tab label={t("seo.twitter")}/>
                    <Tab label={t("seo.google")}/>
                    <Tab label={t("edit.extraData")}/>
                </Tabs>
                <hr/>
                <TabPanel value={currentTab} index={0}>
                    <Row>
                        {page ? this.renderPage(page) : <React.Fragment/>}
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.title")}
                                className={""}
                                value={seoData.getTitle() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setTitle(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.title"), seoData.getTitle(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.description")}
                                className={""}
                                value={seoData.getDescription() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setDescription(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.description"), seoData.getDescription(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4} className={`${this.prefix}-input-tags`}>
                            <TagInput
                                tags={seoData.getKeywords().map((item: string, index: number) => {
                                    return {
                                        displayValue: item,
                                        index,
                                        value       : item
                                    };
                                })}
                                onTagsChanged={(data: any[]) => {
                                    seoData.setKeywords(data.map((item) => item.displayValue));
                                }}
                                placeholder={"Escriba los tags"}
                            />
                        </Col>
                    </Row>
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                    <Row>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.title")}
                                className={""}
                                value={seoData.getOgTitle() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setOgTitle(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.title"), seoData.getOgTitle(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.type")}
                                className={""}
                                value={seoData.getOgType() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setOgType(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.type"), seoData.getOgType(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.url")}
                                className={""}
                                value={seoData.getOgUrl() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setOgUrl(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.url"), seoData.getOgUrl(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.site_name")}
                                className={""}
                                value={seoData.getOgSiteName() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setOgSiteName(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.site_name"), seoData.getOgSiteName(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.description")}
                                className={""}
                                value={seoData.getOgDescription() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setOgDescription(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.description"), seoData.getOgDescription(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <UploadImage
                                onDrop={(files: File[], picturesBase64: string[]) => {
                                    const file   = files[files.length - 1],
                                          base64 = picturesBase64[picturesBase64.length - 1];

                                    if (!(file && base64)) return;

                                    seoData.setOgImageSelect({
                                        base64,
                                        file
                                    });
                                }}
                            />
                            {this.showImage(seoData, "og_image")}
                        </Col>
                    </Row>
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                    <Row>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.twitterCard")}
                                className={""}
                                value={seoData.getTwitterCard() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setTwitterCard(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.twitterCard"), seoData.getTwitterCard(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.twitterSite")}
                                className={""}
                                value={seoData.getTwitterSite() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setTwitterSite(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.twitterSite"), seoData.getTwitterSite(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.twitterTitle")}
                                className={""}
                                value={seoData.getTwitterTitle() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setTwitterTitle(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.twitterTitle"), seoData.getTwitterTitle(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.twitterDescription")}
                                className={""}
                                value={seoData.getTwitterDescription() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setTwitterDescription(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.twitterDescription"), seoData.getTwitterDescription(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.twitterCreator")}
                                className={""}
                                value={seoData.getTwitterCreator() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setTwitterCreator(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.twitterCreator"), seoData.getTwitterCreator(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <UploadImage
                                onDrop={(files: File[], picturesBase64: string[]) => {
                                    const file   = files[files.length - 1],
                                          base64 = picturesBase64[picturesBase64.length - 1];

                                    if (!(file && base64)) return;

                                    seoData.setTwitterImageSelect({
                                        base64,
                                        file
                                    });
                                }}
                            />
                            {this.showImage(seoData, "twitter_image")}
                        </Col>
                    </Row>
                </TabPanel>
                <TabPanel value={currentTab} index={3}>
                    <Row>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.googleAuthor")}
                                className={""}
                                value={seoData.getGoogleAuthor() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setGoogleAuthor(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.googleAuthor"), seoData.getGoogleAuthor(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.googlePublisher")}
                                className={""}
                                value={seoData.getGooglePublisher() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setGooglePublisher(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.googlePublisher"), seoData.getGooglePublisher(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.googleName")}
                                className={""}
                                value={seoData.getGoogleName() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setGoogleName(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.googleName"), seoData.getGoogleName(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <TextField
                                fullWidth
                                label={t("seo.googleDescription")}
                                className={""}
                                value={seoData.getGoogleDescription() || ""}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    seoData.setGoogleDescription(evt.currentTarget.value);
                                }}
                                margin="normal"
                            />
                            {validator.message(t("seo.googleDescription"), seoData.getGoogleDescription(), "required")}
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <UploadImage
                                onDrop={(files: File[], picturesBase64: string[]) => {
                                    const file   = files[files.length - 1],
                                          base64 = picturesBase64[picturesBase64.length - 1];

                                    if (!(file && base64)) return;

                                    seoData.setGoogleImageSelect({
                                        base64,
                                        file
                                    });
                                }}
                            />
                            {this.showImage(seoData, "google_image")}
                        </Col>
                    </Row>
                </TabPanel>
                {this.getExtraField(page, currentTab, t)}
            </>
        );
    }
}

export default withNamespaces("page")(CreateUpdatePageSeo);