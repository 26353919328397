import * as React                         from "react";
import { TableCell, TableRow }            from "@material-ui/core";
import formatDate                         from "../../../config/formatDate";
import Actions                            from "../Table/Actions";
import moment                             from "moment";
import { Page }                           from "../../../Models/Page/Page";
import { withNamespaces, WithNamespaces } from "react-i18next";

interface ITableRowPageViewProps extends WithNamespaces {
    page: Page;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowPageView extends React.Component<ITableRowPageViewProps, {}> {

    public render(): React.ReactNode {
        const {
                  t,
                  page,
                  onEdit,
                  onDelete,
              } = this.props;

        return (
            <TableRow>
                <TableCell>{t(`${page.getName()}`)}</TableCell>
                <TableCell>{moment(page.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(page.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default withNamespaces("seoPages")(TableRowPageView);
