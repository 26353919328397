import { serializable } from "serializr";
import { observable }   from "mobx";
import uuid             from "uuid";

export class Color {
    @serializable
    @observable
    private code: string = "";

    @serializable
    @observable
    private name: string = "";

    @serializable
    @observable
    private rgb: string = "";

    @serializable
    @observable
    private uuid: string = uuid();

    public getCode(): string {
        return this.code;
    }

    public setCode(value: string): this {
        this.code = value;

        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string): this {
        this.name = value;

        return this;
    }

    public getRgb(): string {
        return this.rgb;
    }

    public getUuid(): string {
        return this.uuid;
    }

    public setRgb(value: string): this {
        this.rgb = value;

        return this;
    }
}
