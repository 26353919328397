import BaseStore                               from "./Base/BaseStore";
import { deserialize, serialize }              from "serializr";
import { createFormData }                      from "../Utils/common";
import { Container }                           from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { observable }                          from "mobx";
import { globalPagerOptions, IPaginationData } from "../config/pagination";
import { Page }                                from "../Models/Page/Page";
import { pages }                               from "../config/Pages";
import { ProductRelationship }                 from "../Models/Product/ProductRelationship";

export class PageStore extends BaseStore {
    public static readonly NAME_STORE: string = "PageStore";

    @observable
    private pagesSeo: Page[] = [];

    @observable
    private page: Page;

    @observable
    private pageDefault: Page;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getPage(): Page {
        return this.page;
    }

    public setPage(value: Page): this {
        this.page = value;

        return this;
    }

    public async getPageDefault(): Promise<Page | undefined> {
        if (this.pageDefault) return this.pageDefault;

        this.pageDefault = await this.getByName(pages.default) as Page;

        return this.pageDefault;
    }

    public setPageDefault(value: Page): this {
        this.pageDefault = value;

        return this;
    }

    public getPagesSeo(): Page[] {
        return this.pagesSeo;
    }

    public setPagesSeo(value: Page[]): this {
        this.pagesSeo = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<Page>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginatePages(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(Page, item)),
            pages: dataResponse.pages
        } as IPaginationData<Page>;
    }

    public async getById(id: string): Promise<Page> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPageId(id);

        return deserialize(Page, dataResponse);
    }

    public async getByName(id: string): Promise<Page | undefined> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPageName(id);

        if (!dataResponse) return;

        return deserialize(Page, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deletePageId(id);

        return success;
    }

    public async save(page: Page): Promise<Page> {
        const data     = serialize(page),
              formData = createFormData(data),
              seoData  = page.getSeoData();

        const googleImageSelect  = seoData.getGoogleImageSelect() ? seoData.getGoogleImageSelect().file : "",
              twitterImageSelect = seoData.getTwitterImageSelect() ? seoData.getTwitterImageSelect().file : "",
              ogImageSelect      = seoData.getOgImageSelect() ? seoData.getOgImageSelect().file : "";

        formData.append(`og_image_file`, googleImageSelect);
        formData.append(`twitter_image_file`, twitterImageSelect);
        formData.append(`google_image_file`, ogImageSelect);

        const {data: {data: dataResponse}} = await this.getAjaxService().savePage(formData);

        return deserialize(Page, dataResponse);
    }

    public populateExtraDataHome(page: Page): Page {
        const extraData = page.getExtraData() || {};
        page.setExtraData(deserialize(ProductRelationship, extraData));

        return page;
    }
}
