export enum galleryPages {
    contact              = "PAGE_CONTACT",
    home                 = "PAGE_HOME",
    homeBrands           = "PAGE_HOME_BRANDS",
    promotions           = "PAGE_PROMOTION",
    recommendationPerson = "PAGE_RECOMMENDATION_PERSON",
    tutorials            = "PAGE_TUTORIALS",
    whoAre               = "PAGE_WHO_ARE",
    whoAreCarousel       = "PAGE_WHO_ARE_CAROUSEL",
}
