import * as React                          from "react";
import TableRowGalleryView                 from "../../../Views/Components/TableRowGallery/TableRowGalleryView";
import { Gallery }                         from "../../../Models/Gallery/Gallery";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import DeleteGalleryViewModel              from "../Delete/DeleteGalleryViewModel";

interface ITableRowGalleryViewModelProps extends RouteComponentProps<any> {
    gallery: Gallery;
}

class TableRowGalleryViewModel extends React.Component<ITableRowGalleryViewModelProps, {}> {

    protected onEdit = () => {
        const {history, gallery} = this.props;
        history.push(renderRoute(PageNames.galleryEditPage, {id: gallery.getId()}));
    };

    protected onDelete = () => {
        const {gallery} = this.props;
        Container.get(ModalService).openModal(DeleteGalleryViewModel, {
            gallery
        });
    };

    public render(): React.ReactNode {
        const {gallery} = this.props;

        return (
            <TableRowGalleryView
                gallery={gallery}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowGalleryViewModel);
