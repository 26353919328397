import { observer }         from "mobx-react";
import * as React           from "react";
import { ColorPalette }     from "../../../../Models/Color/ColorPalette";
import ColorsCollectionView from "../../../../Views/Components/ColorPalette/ColorsCollectionView";

interface IColorsCollectionViewModelProps {
    colorPalette: ColorPalette;
}

@observer
class ColorsCollectionViewModel extends React.Component<IColorsCollectionViewModelProps, {}> {
    public render(): React.ReactNode {
        const {colorPalette} = this.props;

        return (
            <ColorsCollectionView
                colorPalette={colorPalette}
            />
        );
    }
}

export default ColorsCollectionViewModel;