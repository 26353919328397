import * as React                         from "react";
import { TableCell, TableRow }            from "@material-ui/core";
import { Gallery }                        from "../../../Models/Gallery/Gallery";
import formatDate                         from "../../../config/formatDate";
import Actions                            from "../Table/Actions";
import moment                             from "moment";
import { WithNamespaces, withNamespaces } from "react-i18next";

interface ITableRowGalleryViewProps extends WithNamespaces {
    gallery: Gallery;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowGalleryView extends React.Component<ITableRowGalleryViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  gallery,
                  t,
                  onEdit,
                  onDelete,
              }    = this.props,
              item = gallery.getFirstItem();

        return (
            <TableRow>
                <TableCell>
                    {
                        gallery.getPage()
                        ? t(gallery.getName(), {ns: "galleryPages"})
                        : gallery.getName()
                    }
                </TableCell>
                <TableCell>
                    {
                        item ? <img src={item.getImageFullPath()} alt="" className="img-fluid"/> : ""
                    }
                </TableCell>
                <TableCell>{moment(gallery.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(gallery.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default withNamespaces("page")(TableRowGalleryView);
