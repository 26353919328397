import React                              from "react";
import AppBar                             from "@material-ui/core/AppBar";
import {
    Button,
    Container, MenuItem,
}                                         from "@material-ui/core";
import Menu                               from "./Menu";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { PageNames, renderRoute }         from "../../../Routes/routes";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { Link }                           from "react-router-dom";
import User                               from "../../../Models/User/User";
import { observer }                       from "mobx-react";
import { MenuUserViewModel }              from "../../../ViewModel/Header/MenuUserViewModel";
import MenuUser                           from "@material-ui/core/Menu/Menu";

interface HeaderProps extends WithNamespaces {
    openMenuLeft: boolean;
    setOpenMenuLeft: (evt: boolean) => void;
    prefix?: string;
    user: User | undefined;
    currentPage: string;
    getAnchorEl: () => HTMLElement | undefined;
    setAnchorEl: (anchorEl: HTMLElement | undefined) => void;
    logout: () => void;
}

@InjectPrefix
@observer
class Header extends React.Component<HeaderProps, {}> {
    private readonly refWrap: React.RefObject<HTMLDivElement>;

    constructor(props: HeaderProps) {
        super(props);
        this.refWrap = React.createRef();
    }

    get prefix(): string {
        return this.props.prefix as string;
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        const {
                  t,
                  currentPage,
                  user,
                  openMenuLeft,
                  setOpenMenuLeft,
                  getAnchorEl,
                  setAnchorEl,
                  logout
              } = this.props;

        return (
            <div className={`${this.prefix}-header`}>
                <AppBar color={"primary"} ref={this.refWrap} position="relative">
                    <div className="top-menu">
                        <Container className="d-flex align-content-center justify-content-between">
                            <div className="d-flex">
                                <Menu
                                    openMenuLeft={openMenuLeft}
                                    setOpenMenuLeft={setOpenMenuLeft}
                                    currentPage={currentPage}
                                    user={user}
                                />
                                <Link className="image-logo" to={renderRoute(PageNames.homePage)}>
                                    <img src="/assets/img/components/logo/logo.png" alt="" className="img-fluid"/>
                                </Link>
                            </div>
                            {user && (<MenuUserViewModel/>)}
                        </Container>
                        <MenuUser
                            id="menu-appbar"
                            anchorEl={getAnchorEl()}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical  : "top",
                            }}
                            keepMounted
                            transformOrigin={{
                                horizontal: "right",
                                vertical  : "top",
                            }}
                            open={!!getAnchorEl()}
                            onClose={() => setAnchorEl(undefined)}
                        >
                            <MenuItem onClick={() => {
                                setAnchorEl(undefined);
                                logout();
                            }}>Logout</MenuItem>
                        </MenuUser>
                    </div>
                </AppBar>
            </div>
        );
    }
}

export default withNamespaces("header")(Header);
