import { observer }        from "mobx-react";
import * as React          from "react";
import { MethodTransport } from "../../../Models/MethodTransport/MethodTransport";
import TablePriceByKms     from "../../../Views/Components/TablePriceByKms/TablePriceByKms";

interface ITablePriceByKmsViewModelProps {
    methodTransport: MethodTransport;
}

@observer
class TablePriceByKmsViewModel extends React.Component<ITablePriceByKmsViewModelProps, {}> {
    public render(): React.ReactNode {
        const {
                  methodTransport,
              } = this.props;

        return (
            <TablePriceByKms
                methodTransport={methodTransport}
            />
        );
    }
}

export default TablePriceByKmsViewModel;