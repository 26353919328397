import * as React              from "react";
import { TableCell, TableRow } from "@material-ui/core";
import formatDate              from "../../../config/formatDate";
import Actions                 from "../Table/Actions";
import moment                  from "moment";
import { Category }            from "../../../Models/Product/Category";

interface ITableRowCategoryViewProps {
    category: Category;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowCategoryView extends React.Component<ITableRowCategoryViewProps, {}> {

    public render(): React.ReactNode {
        const {
                  category,
                  onEdit,
                  onDelete,
              } = this.props;

        return (
            <TableRow>
                <TableCell>{category.getName()}</TableCell>
                <TableCell>{category.getCategory() ? category.getCategory().getName() : ""}</TableCell>
                <TableCell>{moment(category.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(category.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowCategoryView;
