import BaseStore                               from "./Base/BaseStore";
import { deserialize, serialize }              from "serializr";
import { Container }                           from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { observable }                          from "mobx";
import { globalPagerOptions, IPaginationData } from "../config/pagination";
import { Occupation }                             from "../Models/Occupation/Occupation";

export class OccupationStore extends BaseStore {
    public static readonly NAME_STORE: string = "OccupationStore";

    @observable
    private occupations: Occupation[] = [];

    @observable
    private occupation: Occupation;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getOccupation(): Occupation {
        return this.occupation;
    }

    public setOccupation(value: Occupation): this {
        this.occupation = value;

        return this;
    }

    public getOccupations(): Occupation[] {
        return this.occupations;
    }

    public setOccupations(value: Occupation[]): this {
        this.occupations = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<Occupation>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateOccupation(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(Occupation, item)),
            pages: dataResponse.pages
        } as IPaginationData<Occupation>;
    }

    public async getById(id: string): Promise<Occupation> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getOccupation(id);

        return deserialize(Occupation, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deleteOccupation(id);

        return success;
    }

    public async save(occupation: Occupation): Promise<Occupation> {
        const data                         = serialize(occupation),
              {data: {data: dataResponse}} = await this.getAjaxService().saveOccupation(data);

        return deserialize(Occupation, dataResponse);
    }
}
