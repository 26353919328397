import * as React               from "react";
import { TableCell, TableRow }  from "@material-ui/core";
import formatDate               from "../../../config/formatDate";
import Actions                  from "../Table/Actions";
import moment                   from "moment";
import { RecommendationPerson } from "../../../Models/RecomendationPerson/RecommendationPerson";

interface ITableRowGalleryViewProps {
    recommendationPerson: RecommendationPerson;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowRecommendationPersonView extends React.Component<ITableRowGalleryViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  recommendationPerson,
                  onEdit,
                  onDelete,
              }    = this.props;

        return (
            <TableRow>
                <TableCell>{recommendationPerson.getName()}</TableCell>
                <TableCell>
                    {
                        <img src={recommendationPerson.getImageFullPath()} alt="" className="img-fluid"/>
                    }
                </TableCell>
                <TableCell>{moment(recommendationPerson.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(recommendationPerson.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowRecommendationPersonView;
