import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import { Category }                        from "../../../Models/Product/Category";
import DeleteCategoryViewModel             from "../Delete/DeleteCategoryViewModel";
import TableRowCategoryView                from "../../../Views/Components/TableRowCategory/TableRowCategoryView";

interface ITableRowCategoryViewModelProps extends RouteComponentProps<any> {
    category: Category;
}

class TableRowCategoryViewModel extends React.Component<ITableRowCategoryViewModelProps, {}> {

    protected onEdit = () => {
        const {history, category} = this.props;
        history.push(renderRoute(PageNames.categoryEditPage, {id: category.getId()}));
    };

    protected onDelete = () => {
        const {category} = this.props;
        Container.get(ModalService).openModal(DeleteCategoryViewModel, {
            category
        });
    };

    public render(): React.ReactNode {
        const {category} = this.props;

        return (
            <TableRowCategoryView
                category={category}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowCategoryViewModel);
