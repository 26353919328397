import * as React                                                            from "react";
import { LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, } from "@material-ui/core";
import { withNamespaces, WithNamespaces }                                    from "react-i18next";
import AddIcon                                                               from "@material-ui/icons/Add";
import { Gallery }  from "../../../../Models/Gallery/Gallery";
import InjectPrefix from "../../../../Decorators/InjectPrefix";
import TableRowGalleryViewModel
                    from "../../../../ViewModel/Gallery/View/TableRowGalleryViewModel";
import { Button }   from "../../../Components/Button/Button";
import Pagination   from "../../../Components/Pagination/Pagination";

interface IGalleryViewProps extends WithNamespaces {
    galleries: Gallery[];
    spinner: boolean;
    addGallery: () => void;
    prefix?: string;
    pages: number;
    currentPage: number;
    getPage: (page: number) => void;
}

@InjectPrefix
class GalleryView extends React.Component<IGalleryViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderBody = (): React.ReactNode => {
        const {galleries, t} = this.props;

        return galleries.map((gallery: Gallery, key: number) => (
            <TableRowGalleryViewModel
                gallery={gallery}
                key={key}
            />
        ));
    };

    protected renderPagination = (): React.ReactNode => {
        const {
                  spinner,
                  pages,
                  currentPage,
                  getPage,
              } = this.props;

        return <Pagination
            className="mt-4"
            pages={pages}
            currentPage={currentPage}
            getPage={!spinner ? getPage : () => {}}
        />;
    };

    public render(): React.ReactNode {
        const {spinner, t, addGallery} = this.props,
              propsButtons             = {
                  color  : "primary" as "primary",
                  onClick: addGallery,
                  size   : "small" as "small",
                  spinner: false,
                  variant: "contained" as "contained"
              };

        return (
            <>
                <h3>
                    {t("galleries")}
                </h3>
                <div className="d-flex align-items-end flex-column mb-3">
                    <Button {...propsButtons}>
                        <AddIcon/> {t("edit.create")}
                    </Button>
                </div>
                <Table className={`${this.prefix}-table-info`}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("edit.title")}</TableCell>
                            <TableCell>{t("edit.first-image")}</TableCell>
                            <TableCell>{t("edit.createdAt")}</TableCell>
                            <TableCell>{t("edit.updatedAt")}</TableCell>
                            <TableCell align="right">{t("edit.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderBody()}
                    </TableBody>
                </Table>
                {this.renderPagination()}
                {
                    spinner &&
                    <LinearProgress className="mt-4 mb-4"/>
                }
            </>
        );
    }
}

export default withNamespaces("page")(GalleryView);
