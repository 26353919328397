import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import { Order }                           from "../../../Models/Order/Order";
import TableRowOrder                       from "../../../Views/Components/TableRowOrder/TableRowOrder";

interface ITableRowOrderViewModelProps extends RouteComponentProps<any> {
    order: Order;
}

class TableRowOrderViewModel extends React.Component<ITableRowOrderViewModelProps, {}> {
    protected onView = () => {
        const {history, order} = this.props;
        history.push(renderRoute(PageNames.orderDetailPage, {id: order.getId()}));
    };

    public render(): React.ReactNode {
        const {order} = this.props;

        return (
            <TableRowOrder
                order={order}
                onView={this.onView}
            />
        );
    }
}

export default withRouter(TableRowOrderViewModel);
