import { observable }   from "mobx";
import { serializable } from "serializr";

export class Login {
    @serializable
    @observable
    private username: string = "";

    @serializable
    @observable
    private password: string = "";

    public getUsername(): string {
        return this.username;
    }

    public setUsername(value: string) {
        this.username = value;
    }

    public getPassword(): string {
        return this.password;
    }

    public setPassword(value: string) {
        this.password = value;
    }
}
