import InjectPrefix from "../../../Decorators/InjectPrefix";
import { observer } from "mobx-react";
import * as React   from "react";
import {
    Pagination as BootstrapPagination,
    PaginationItem,
    PaginationLink,
}                   from "reactstrap";

interface IPaginationProps {
    prefix?: string;
    className?: string;
    pages: number;
    currentPage: number;
    getPage: (page: number) => void;
}

@InjectPrefix
@observer
class Pagination extends React.Component<IPaginationProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderPagesItems = (): React.ReactNode => {
        const {pages, currentPage, getPage} = this.props,
              items                         = [];

        for (let page = 1; page <= pages; page++) {
            items.push(
                <PaginationItem key={page} active={page === currentPage}>
                    <PaginationLink
                        href="javascript:void(0);"
                        onClick={() => getPage(page)}
                    >
                        {page}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        return items;
    };

    protected getNext     = (): React.ReactNode => {
        const {pages, currentPage, getPage} = this.props,
              have                          = currentPage !== pages && pages !== 0;

        if (!have) return (<React.Fragment/>);

        return (
            <PaginationItem>
                <PaginationLink
                    next
                    href="javascript:void(0);"
                    onClick={() => {
                        getPage(currentPage + 1);
                    }}/>
            </PaginationItem>
        );
    };
    protected getPrevious = (): React.ReactNode => {
        const {currentPage, getPage, pages} = this.props,
              have                   = currentPage !== 1 && pages !== 0;

        if (!have) return (<React.Fragment/>);

        return (
            <PaginationItem>
                <PaginationLink
                    previous
                    href="javascript:void(0);"
                    onClick={() => {
                        getPage(currentPage - 1);
                    }}/>
            </PaginationItem>
        );
    };

    public render(): React.ReactNode {
        const {className = ""} = this.props;

        return (
            <BootstrapPagination aria-label="Page navigation" className={`${this.prefix}-pagination ${className}`}>
                {this.getPrevious()}
                {this.renderPagesItems()}
                {this.getNext()}
            </BootstrapPagination>
        );
    }
}

export default Pagination;
