import { inject, observer }               from "mobx-react";
import * as React                         from "react";
import ConfirmationModal                  from "../../../Views/Components/Modal/ConfirmationModal";
import ModalService                       from "../../../Service/ModalService";
import { Container }                      from "typedi";
import { ModalStore }                     from "../../../Store/ModalStore";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { OccupationStore }                from "../../../Store/OccupationStore";
import { Occupation }                     from "../../../Models/Occupation/Occupation";

interface IDeleteOccupationViewModelProps extends WithNamespaces {
    ModalStore?: ModalStore;
    OccupationStore?: OccupationStore;
    occupation: Occupation;
}

@inject(ModalStore.NAME_STORE, OccupationStore.NAME_STORE)
@observer
class DeleteOccupationViewModel extends React.Component<IDeleteOccupationViewModelProps, {}> {
    private readonly modalService: ModalService;

    constructor(props: IDeleteOccupationViewModelProps) {
        super(props);

        this.modalService = Container.get(ModalService);
    }

    get occupationStore(): OccupationStore {
        return this.props.OccupationStore as OccupationStore;
    }

    get modalStore(): ModalStore {
        return this.props.ModalStore as ModalStore;
    }

    public action = async () => {
        const {occupation} = this.props;

        this.occupationStore.setSpinner(true);
        await this.occupationStore.deleteById(occupation.getId());
        const data = await this.occupationStore.getPaginate();

        this.occupationStore
            .setPages(data.pages)
            .setOccupations(data.data)
            .setSpinner(false);

        this.modalService.closeModal();
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <ConfirmationModal
                handleClose={this.modalService.closeModal}
                open={this.modalStore.getModal().getOpenModal()}
                action={this.action}
                spinner={this.occupationStore.getSpinner()}
                title={t("confirm.content")}
                content={t("confirm.title")}
            />
        );
    }
}

export default withNamespaces("modal")(DeleteOccupationViewModel);
