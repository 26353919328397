import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { ActionStore }                     from "../../../Store/ActionStore";
import ActionView                          from "../../../Views/Pages/Action/View/ActionView";

interface IAboutUsViewModelProps extends RouteComponentProps<any> {
    ActionStore?: ActionStore;
}

@inject(ActionStore.NAME_STORE)
@observer
class ActionViewModel extends React.Component<IAboutUsViewModelProps, {}> {
    get actionStore(): ActionStore {
        return this.props.ActionStore as ActionStore;
    }

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <ActionView
            />
        );
    }
}

export default withRouter(ActionViewModel);
