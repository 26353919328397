import { inject, observer }               from "mobx-react";
import * as React                         from "react";
import ConfirmationModal                  from "../../../Views/Components/Modal/ConfirmationModal";
import ModalService                       from "../../../Service/ModalService";
import { Container }                      from "typedi";
import { ModalStore }                     from "../../../Store/ModalStore";
import { withNamespaces, WithNamespaces } from "react-i18next";
import User                               from "../../../Models/User/User";
import { UserStore }                      from "../../../Store/UserStore";

interface IDeleteAboutUsViewModelProps extends WithNamespaces {
    ModalStore?: ModalStore;
    UserStore?: UserStore;
    user: User;
}

@inject(ModalStore.NAME_STORE, UserStore.NAME_STORE)
@observer
class DeleteUserViewModel extends React.Component<IDeleteAboutUsViewModelProps, {}> {
    private readonly modalService: ModalService;

    constructor(props: IDeleteAboutUsViewModelProps) {
        super(props);

        this.modalService = Container.get(ModalService);
    }

    get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    get modalStore(): ModalStore {
        return this.props.ModalStore as ModalStore;
    }

    public action = async () => {
        const {user} = this.props;

        this.userStore.setSpinner(true);
        await this.userStore.deleteById(user.getId());
        const data = await this.userStore.getPaginate();

        this.userStore
            .setPages(data.pages)
            .setUserCMSList(data.data)
            .setSpinner(false);

        this.modalService.closeModal();
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <ConfirmationModal
                handleClose={this.modalService.closeModal}
                open={this.modalStore.getModal().getOpenModal()}
                action={this.action}
                spinner={this.userStore.getSpinner()}
                title={t("confirm.content")}
                content={t("confirm.title")}
            />
        );
    }
}

export default withNamespaces("modal")(DeleteUserViewModel);
