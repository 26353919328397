import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import { Promotion }                       from "../../../Models/Promotion/Promotion";
import TableRowPromotionView               from "../../../Views/Components/TableRowPromotion/TableRowPromotionView";
import DeletePromotionViewModel            from "../Delete/DeletePromotionViewModel";

interface ITableRowPromotionViewModelProps extends RouteComponentProps<any> {
    promotion: Promotion;
}

class TableRowPromotionViewModel extends React.Component<ITableRowPromotionViewModelProps, {}> {

    protected onEdit = () => {
        const {history, promotion} = this.props;
        history.push(renderRoute(PageNames.promotionEditPage, {id: promotion.getId()}));
    };

    protected onDelete = () => {
        const {promotion} = this.props;
        Container.get(ModalService).openModal(DeletePromotionViewModel, {
            promotion
        });
    };

    public render(): React.ReactNode {
        const {promotion} = this.props;

        return (
            <TableRowPromotionView
                promotion={promotion}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowPromotionViewModel);
