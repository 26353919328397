import BaseStore                               from "./Base/BaseStore";
import { deserialize, serialize }              from "serializr";
import { Container }                           from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { observable }                          from "mobx";
import { globalPagerOptions, IPaginationData } from "../config/pagination";
import { RecommendationPerson }                from "../Models/RecomendationPerson/RecommendationPerson";
import { createFormData }                      from "../Utils/common";

export class RecommendationPersonStore extends BaseStore {
    public static readonly NAME_STORE: string = "RecommendationPersonStore";

    @observable
    private recommendationPersons: RecommendationPerson[] = [];

    @observable
    private recommendationPerson: RecommendationPerson;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getRecommendationPerson(): RecommendationPerson {
        return this.recommendationPerson;
    }

    public setRecommendationPerson(value: RecommendationPerson): this {
        this.recommendationPerson = value;

        return this;
    }

    public getRecommendationPersons(): RecommendationPerson[] {
        return this.recommendationPersons;
    }

    public setRecommendationPersons(value: RecommendationPerson[]): this {
        this.recommendationPersons = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<RecommendationPerson>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateRecommendationPerson(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(RecommendationPerson, item)),
            pages: dataResponse.pages
        } as IPaginationData<RecommendationPerson>;
    }

    public async getById(id: string): Promise<RecommendationPerson> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getRecommendationPerson(id);

        return deserialize(RecommendationPerson, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deleteRecommendationPerson(id);

        return success;
    }

    public async save(recommendationPerson: RecommendationPerson): Promise<RecommendationPerson> {
        const data        = serialize(recommendationPerson),
              formData    = createFormData(data),
              imageSelect = recommendationPerson.getImageSelect() ? recommendationPerson.getImageSelect().file : "";

        formData.append(`file`, imageSelect);

        const {data: {data: dataResponse}} = await this.getAjaxService().saveRecommendationPerson(formData);

        return deserialize(RecommendationPerson, dataResponse);
    }
}
