import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import { UserStore }                       from "../../../Store/UserStore";
import UserView                            from "../../../Views/Pages/User/View/UserView";

interface IUserViewModelProps extends RouteComponentProps<any> {
    UserStore?: UserStore;
}

@inject(UserStore.NAME_STORE)
@observer
class UserViewModel extends React.Component<IUserViewModelProps, {}> {
    get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.userStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.userStore.getPaginate();

        this.userStore
            .setPages(response.pages)
            .setUserCMSList(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <UserView
                users={this.userStore.getUserCMSList()}
                spinner={this.userStore.getSpinner()}
                add={() => history.push(renderRoute(PageNames.userAddPage))}
                getPage={this.getPage}
                pages={this.userStore.getPages()}
                currentPage={this.userStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(UserViewModel);
