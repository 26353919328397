import * as React                  from "react";
import {
    RouteComponentProps,
    withRouter
}                                  from "react-router";
import {
    PageNames,
    renderRoute
}                                  from "../../../Routes/routes";
import ModalService                from "../../../Service/ModalService";
import { Container }               from "typedi";
import TableRowColorPaletteView    from "../../../Views/Components/TableRowColorPalette/TableRowColorPaletteView";
import { ColorPalette }            from "../../../Models/Color/ColorPalette";
import DeleteColorPaletteViewModel from "../Delete/DeleteColorPaletteViewModel";

interface ITableRowColorPaletteViewModelProps extends RouteComponentProps<any> {
    colorPalette: ColorPalette;
}

class TableRowColorPaletteViewModel extends React.Component<ITableRowColorPaletteViewModelProps, {}> {
    protected onEdit = () => {
        const {colorPalette, history} = this.props;
        history.push(renderRoute(PageNames.colorPaletteEditPage, {id: colorPalette.getId()}));
    };

    protected onDelete = () => {
        const {colorPalette} = this.props;
        Container.get(ModalService).openModal(DeleteColorPaletteViewModel, {
            colorPalette
        });
    };

    public render(): React.ReactNode {
        const {colorPalette} = this.props;

        return (
            <TableRowColorPaletteView
                colorPalette={colorPalette}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowColorPaletteViewModel);
