import * as React                 from "react";
import { observable }             from "mobx";
import { inject, observer }       from "mobx-react";
import { withNamespaces }         from "react-i18next";
import { withRouter }             from "react-router";
import { LinearProgress }         from "@material-ui/core";
import { FormStore }              from "../../../Store/FormStore";
import { PageNames, renderRoute } from "../../../Routes/routes";
import { AboutUsStore }           from "../../../Store/AboutUsStore";
import { AboutUs }                from "../../../Models/AboutUs/AboutUs";
import IBaseProps                 from "../../Props/IBaseProps";
import CreateOrEditAboutUsView    from "../../../Views/Pages/AboutUs/CreateOrEdit/CreateOrEditAboutUsView";
import { SeoData }                from "../../../Models/Page/SeoData";

interface INewEditAboutUsViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    AboutUsStore?: AboutUsStore;
}

@inject(FormStore.NAME_STORE, AboutUsStore.NAME_STORE)
@observer
class CreateOrEditAboutUsViewModel extends React.Component<INewEditAboutUsViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    constructor(props: INewEditAboutUsViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }
    }

    public componentDidMount = async (): Promise<void> => {
        const {id} = this.props.match.params;

        if (!id) {
            this.aboutUsStore.setAboutUs(new AboutUs());
            return;
        }

        const aboutUs = await this.aboutUsStore.getById(id);

        if (!aboutUs.getSeoData()) aboutUs.setSeoData(new SeoData());

        this.aboutUsStore.setAboutUs(aboutUs);

        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get aboutUsStore(): AboutUsStore {
        return this.props.AboutUsStore as AboutUsStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const aboutUs   = await this.aboutUsStore.save(this.aboutUsStore.getAboutUs()),
              {history} = this.props;

        history.push(renderRoute(PageNames.aboutUsPage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.aboutUsPage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    public render(): React.ReactNode {
        if (this.loading || !this.aboutUsStore.getAboutUs())
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (this.aboutUsStore.getAboutUs())
            return (
                <CreateOrEditAboutUsView
                    currentTab={this.getCurrentTab()}
                    setCurrentTab={this.setCurrentTab}
                    currentTabSeo={this.formStore.getCurrentTabSeo()}
                    setCurrentTabSeo={this.formStore.setCurrentTabSeo}
                    aboutUs={this.aboutUsStore.getAboutUs()}
                    validate={this.validate}
                    spinner={this.getSpinner()}
                    save={this.save}
                    validator={this.formStore.getValidator()}
                    cancel={this.cancel}
                />
            );

        return (<React.Fragment/>);
    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditAboutUsViewModel));
