import * as React                 from "react";
import { observable }             from "mobx";
import { inject, observer }       from "mobx-react";
import { withNamespaces }         from "react-i18next";
import { withRouter }             from "react-router";
import { LinearProgress }         from "@material-ui/core";
import { Gallery }                from "../../../Models/Gallery/Gallery";
import { GalleryItem }            from "../../../Models/Gallery/GalleryItem";
import { FormStore }              from "../../../Store/FormStore";
import { PageNames, renderRoute } from "../../../Routes/routes";
import CreateOrEditPromotionView  from "../../../Views/Pages/Promotion/CreateOrEdit/CreateOrEditPromotionView";
import { PromotionStore }         from "../../../Store/PromotionStore";
import { Promotion }              from "../../../Models/Promotion/Promotion";
import { PromotionInformation }   from "../../../Models/Promotion/PromotionInformation";
import IBaseProps                 from "../../Props/IBaseProps";
import { SeoData }                from "../../../Models/Page/SeoData";
import { PageStore }              from "../../../Store/PageStore";

interface INewEditPromotionViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    PromotionStore?: PromotionStore;
    PageStore?: PageStore;
}

@inject(FormStore.NAME_STORE, PromotionStore.NAME_STORE, PageStore.NAME_STORE)
@observer
class CreateOrEditPromotionViewModel extends React.Component<INewEditPromotionViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    constructor(props: INewEditPromotionViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }
    }

    public componentDidMount = async (): Promise<void> => {
        const {id}           = this.props.match.params,
              pageSeoDefault = await this.pagesStore.getPageDefault(),
              seoDataDefault = (pageSeoDefault ? pageSeoDefault.getSeoData() : new SeoData());

        if (!id) {
            const promotion = new Promotion(),
                  gallery   = new Gallery();

            gallery.addItem(new GalleryItem());
            promotion.setGallery(gallery);
            promotion.addItem(new PromotionInformation());
            promotion.setSeoData(seoDataDefault);

            this.promotionStore.setPromotion(promotion);

            return;
        }

        const promotion = await this.promotionStore.getById(id);

        if (!promotion.getGallery()) {
            const gallery = new Gallery();

            gallery.addItem(new GalleryItem());
            promotion.setGallery(gallery);
        }

        if (!promotion.getSeoData() || !promotion.getSeoData().getDescription()) promotion.setSeoData(seoDataDefault);

        this.promotionStore.setPromotion(promotion);
        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get pagesStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    get promotionStore(): PromotionStore {
        return this.props.PromotionStore as PromotionStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const promotion = await this.promotionStore.save(this.promotionStore.getPromotion()),
              {history} = this.props;

        history.push(renderRoute(PageNames.promotionPage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.promotionPage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    public render(): React.ReactNode {
        if (this.loading || !this.promotionStore.getPromotion())
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (this.promotionStore.getPromotion())
            return (
                <CreateOrEditPromotionView
                    currentTab={this.getCurrentTab()}
                    setCurrentTab={this.setCurrentTab}
                    currentTabSeo={this.formStore.getCurrentTabSeo()}
                    setCurrentTabSeo={this.formStore.setCurrentTabSeo}
                    promotion={this.promotionStore.getPromotion()}
                    validate={this.validate}
                    spinner={this.getSpinner()}
                    save={this.save}
                    validator={this.formStore.getValidator()}
                    cancel={this.cancel}
                />
            );

        return (<React.Fragment/>);
    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditPromotionViewModel));
