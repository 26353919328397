import { inject, observer }               from "mobx-react";
import * as React                         from "react";
import ConfirmationModal                  from "../../../Views/Components/Modal/ConfirmationModal";
import ModalService                       from "../../../Service/ModalService";
import { Container }                      from "typedi";
import { ModalStore }                     from "../../../Store/ModalStore";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { ProductStore }                 from "../../../Store/ProductStore";
import { Product }                      from "../../../Models/Product/Product";

interface IDeleteProductViewModelProps extends WithNamespaces {
    ModalStore?: ModalStore;
    ProductStore?: ProductStore;
    product: Product;
}

@inject(ModalStore.NAME_STORE, ProductStore.NAME_STORE)
@observer
class DeleteProductViewModel extends React.Component<IDeleteProductViewModelProps, {}> {
    private readonly modalService: ModalService;

    constructor(props: IDeleteProductViewModelProps) {
        super(props);

        this.modalService = Container.get(ModalService);
    }

    get productStore(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    get modalStore(): ModalStore {
        return this.props.ModalStore as ModalStore;
    }

    public action = async () => {
        const {product} = this.props;

        this.productStore.setSpinner(true);
        await this.productStore.deleteById(product.getId());
        const data = await this.productStore.getPaginate();

        this.productStore
            .setPages(data.pages)
            .setProducts(data.data)
            .setSpinner(false);

        this.modalService.closeModal();
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <ConfirmationModal
                handleClose={this.modalService.closeModal}
                open={this.modalStore.getModal().getOpenModal()}
                action={this.action}
                spinner={this.productStore.getSpinner()}
                title={t("confirm.content")}
                content={t("confirm.title")}
            />
        );
    }
}

export default withNamespaces("modal")(DeleteProductViewModel);
