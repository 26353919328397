import "reflect-metadata";
import "./index.scss";
import * as React            from "react";
import * as ReactDOM         from "react-dom";
import { BrowserRouter }     from "react-router-dom";
import { I18nextProvider }   from "react-i18next";
import { Provider, onError } from "mobx-react";
import { Container }         from "typedi";
import Layout                from "./Views/Components/Layout/Layout";
import i18next               from "./Utils/i18n/i18n";
import { ApplicationStore }  from "./Store/ApplicationStore";

import DevTools from "mobx-react-devtools";

const applicationStore = Container.get(ApplicationStore);

(async () => {
    await applicationStore.initStorage();
    const app = (
        <Provider {...applicationStore.getStores()}>
            <I18nextProvider i18n={i18next} initialLanguage={"es"}>
                <BrowserRouter>
                    <Layout/>
                </BrowserRouter>
                {process.env.REACT_APP_ENV === "dev" ? <DevTools/> : undefined}
            </I18nextProvider>
        </Provider>
    );

    onError(error => {
        console.log("error mobx", error);
    });

    ReactDOM.render(app, document.getElementById("root") as HTMLElement);
})();

ReactDOM.render((<div> Loading... </div>), document.getElementById("root") as HTMLElement);
