import * as React                         from "react";
import Tabs                               from "@material-ui/core/Tabs";
import Tab                                from "@material-ui/core/Tab";
import {
    Checkbox,
    FormControlLabel,
    TextField,
} from "@material-ui/core";
import { Row, Col }                       from "reactstrap";
import SimpleReactValidator               from "simple-react-validator";
import { TabPanel }                       from "../../../Components/TabPanel/TabPanel";
import { GroupButtons }                   from "../../../Components/GroupButtons/GroupButtons";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { observer }                       from "mobx-react";
import { RecommendationPerson }           from "../../../../Models/RecomendationPerson/RecommendationPerson";
import UploadImage                        from "../../../Components/Form/Upload/UploadImage";
import { IImageSelect }                   from "../../../../Models/Gallery/GalleryItem";
import { Occupation }                     from "../../../../Models/Occupation/Occupation";
import { MultiCheckBox }                  from "../../../Components/MultiCheckBox/MultiCheckBox";

interface ICreateOrEditRecommendationPersonViewProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    currentTabSeo: number;
    setCurrentTabSeo: (tab: number) => void;
    recommendationPerson: RecommendationPerson;
    validate: () => boolean;
    spinner: boolean;
    save: () => void;
    cancel: () => void;
    validator: SimpleReactValidator;
    occupations: Occupation[];
    selectOccupations: Map<string, Occupation>;
    onChangeOccupation: (item: Occupation) => void;
}

@observer
class CreateOrEditRecommendationPersonView extends React.Component<ICreateOrEditRecommendationPersonViewProps, {}> {
    protected showImage = (recommendationPerson: RecommendationPerson): React.ReactNode => {
        const imageSelect: IImageSelect = recommendationPerson.getImageSelect(),
              fullPath                  = recommendationPerson.getImageFullPath();

        if (!imageSelect && !fullPath) return <React.Fragment/>;

        return (<img
            src={imageSelect ? imageSelect.base64 : fullPath}
            className="img-fluid"
        />);
    };

    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  recommendationPerson,
                  spinner,
                  cancel,
                  validate,
                  save,
                  validator,
                  occupations       = [],
                  selectOccupations = new Map<string, Occupation>(),
                  t,
                  onChangeOccupation,
              } = this.props;

        return (
            <div className={``}>
                <form noValidate autoComplete="off">
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setCurrentTab(newValue);
                        }} aria-label="tabs"
                    >
                        <Tab label={t("edit.general")}/>
                    </Tabs>
                    <hr/>
                    <TabPanel value={currentTab} index={0}>
                        <Row>
                            <Col xs={12} md={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label={t("edit.name")}
                                    className={""}
                                    value={recommendationPerson.getName() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        recommendationPerson.setName(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.name"), recommendationPerson.getName(), "required")}
                                <TextField
                                    fullWidth
                                    type={"number"}
                                    label={t("edit.phone")}
                                    className={""}
                                    value={recommendationPerson.getPhone() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        recommendationPerson.setPhone(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.name"), recommendationPerson.getPhone(), "required")}
                                <TextField
                                    fullWidth
                                    label={t("edit.direction")}
                                    className={""}
                                    value={recommendationPerson.getDirection() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        recommendationPerson.setDirection(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.name"), recommendationPerson.getDirection(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <MultiCheckBox<Occupation>
                                    onChange={onChangeOccupation}
                                    options={occupations}
                                    toStringItem={(item: Occupation) => item.getName()}
                                    toStringItemValue={(item: Occupation) => item.getName()}
                                    isChecked={(item: Occupation) => {
                                        return selectOccupations.has(item.getId());
                                    }}
                                    label={t("edit.occupations")}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={recommendationPerson.getActive()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                recommendationPerson.setActive(event.currentTarget.checked);
                                            }}
                                            value={recommendationPerson.getActive()}
                                            color="primary"
                                        />
                                    }
                                    label={t("edit.active")}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <UploadImage
                                    onDrop={(files: File[], picturesBase64: string[]) => {
                                        const file   = files[files.length - 1],
                                              base64 = picturesBase64[picturesBase64.length - 1];

                                        if (!(file && base64)) return;

                                        recommendationPerson.setImageSelect({
                                                                                base64,
                                                                                file
                                                                            });
                                    }}
                                />
                                {this.showImage(recommendationPerson)}
                            </Col>
                        </Row>
                    </TabPanel>
                    <hr/>
                    <GroupButtons
                        action={save}
                        spinner={spinner}
                        validate={validate}
                        cancel={cancel}
                    />
                </form>
            </div>
        );
    }
}

export default withNamespaces("page")(CreateOrEditRecommendationPersonView);
