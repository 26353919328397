import { Card }                 from "./Card";
import { object, serializable } from "serializr";

export class Chargelog {
    @serializable
    private status: string;

    @serializable
    private description: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private entity_description: string;

    @serializable
    private currency: string;

    @serializable
    private amount: string;

    @serializable(object(Card))
    private card: Card;

    public getStatus(): string {
        return this.status;
    }

    public setStatus(value: string) {
        this.status = value;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(value: string) {
        this.description = value;
    }

    public getEntityDescription(): string {
        return this.entity_description;
    }

    public setEntityDescription(value: string) {
        this.entity_description = value;
    }

    public getCurrency(): string {
        return this.currency;
    }

    public setCurrency(value: string) {
        this.currency = value;
    }

    public getAmount(): string {
        return this.amount;
    }

    public setAmount(value: string) {
        this.amount = value;
    }

    public getCard(): Card {
        return this.card;
    }

    public setCard(value: Card) {
        this.card = value;
    }
}