import * as React              from "react";
import { TableCell, TableRow } from "@material-ui/core";
import Actions                 from "../Table/Actions";
import { ContactUs }           from "../../../Models/ContactUs/ContactUs";
import moment                  from "moment";
import formatDate              from "../../../config/formatDate";

interface ITableRowGalleryViewProps {
    contactUs: ContactUs;
    onDelete?: () => void;
    onView?: () => void;
}

class TableRowContactUs extends React.Component<ITableRowGalleryViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  contactUs,
                  onDelete,
              } = this.props;

        return (
            <TableRow>
                <TableCell>{contactUs.getName()}</TableCell>
                <TableCell>{contactUs.getLastName()}</TableCell>
                <TableCell>{contactUs.getEmail()}</TableCell>
                <TableCell>{contactUs.getWishContact()}</TableCell>
                <TableCell>{contactUs.getMessage()}</TableCell>
                <TableCell>{moment(contactUs.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(contactUs.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowContactUs;
