import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import TableRowOccupationView              from "../../../Views/Components/TableRowOccupation/TableRowOccupationView";
import { Occupation }                      from "../../../Models/Occupation/Occupation";
import DeleteOccupationViewModel           from "../Delete/DeleteOccupationViewModel";

interface ITableRowOccupationViewModelProps extends RouteComponentProps<any> {
    occupation: Occupation;
}

class TableRowOccupationViewModel extends React.Component<ITableRowOccupationViewModelProps, {}> {
    protected onEdit = () => {
        const {occupation, history} = this.props;
        history.push(renderRoute(PageNames.occupationEditPage, {id: occupation.getId()}));
    };

    protected onDelete = () => {
        const {occupation} = this.props;
        Container.get(ModalService).openModal(DeleteOccupationViewModel, {
            occupation
        });
    };

    public render(): React.ReactNode {
        const {occupation} = this.props;

        return (
            <TableRowOccupationView
                occupation={occupation}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowOccupationViewModel);
