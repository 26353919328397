import * as React              from "react";
import { TableCell, TableRow } from "@material-ui/core";
import moment                  from "moment";
import {
    withNamespaces,
    WithNamespaces,
}                              from "react-i18next";
import Actions                 from "../Table/Actions";
import formatDate              from "../../../config/formatDate";
import { Order }               from "../../../Models/Order/Order";

interface ITableRowGalleryViewProps extends WithNamespaces {
    order: Order;
    onView: () => void;
}

class TableRowOrder extends React.Component<ITableRowGalleryViewProps, {}> {

    public render(): React.ReactNode {
        const {
                  order,
                  onView,
                  t,
              } = this.props;

        return (
            <TableRow>
                <TableCell>{order.getIdentifier()}</TableCell>
                <TableCell>{order.getUser() ? order.getUser().getFullName() : ""}</TableCell>
                <TableCell className={order.getStatus().toLowerCase()}>{t(order.getStatus())}</TableCell>
                <TableCell>{moment(order.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(order.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onView={onView}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default withNamespaces("order")(TableRowOrder);
