import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import RecommendationPersonView
                                           from "../../../Views/Pages/RecommendationPerson/View/RecommendationPersonView";
import { RecommendationPersonStore }       from "../../../Store/RecommendationPersonStore";

interface IRecommendationPersonViewModelProps extends RouteComponentProps<any> {
    RecommendationPersonStore?: RecommendationPersonStore;
}

@inject(RecommendationPersonStore.NAME_STORE)
@observer
class RecommendationPersonViewModel extends React.Component<IRecommendationPersonViewModelProps, {}> {
    get recommendationPersonStore(): RecommendationPersonStore {
        return this.props.RecommendationPersonStore as RecommendationPersonStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.recommendationPersonStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.recommendationPersonStore.getPaginate();

        this.recommendationPersonStore
            .setPages(response.pages)
            .setRecommendationPersons(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <RecommendationPersonView
                recommendationPersons={this.recommendationPersonStore.getRecommendationPersons()}
                spinner={this.recommendationPersonStore.getSpinner()}
                add={() => history.push(renderRoute(PageNames.recommendationPersonAddPage))}
                getPage={this.getPage}
                pages={this.recommendationPersonStore.getPages()}
                currentPage={this.recommendationPersonStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(RecommendationPersonViewModel);
