import { Score }                                         from "./Score";
import { custom, list, object, primitive, serializable } from "serializr";
import { observable }                                    from "mobx";
import { date }                                          from "../../Serializer/date";
import { IImageSelect }                                  from "../Gallery/GalleryItem";

export class RecommendationPerson {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private name: string = "";

    @serializable
    @observable
    private slug: string = "";

    @serializable
    @observable
    private image: string = "";

    @serializable
    @observable
    private phone: string = "";

    @serializable
    @observable
    private direction: string = "";

    @serializable(list(object(Score)))
    @observable
    private scores: Score[] = [];

    @serializable(list(primitive()))
    @observable
    private occupations: string[] = [];

    @serializable
    @observable
    private active: boolean;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    @observable
    private imageSelect: IImageSelect;

    public getImageSelect(): IImageSelect {
        return this.imageSelect;
    }

    public setImageSelect(value: IImageSelect) {
        this.imageSelect = value;
    }

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getSlug(): string {
        return this.slug;
    }

    public setSlug(value: string) {
        this.slug = value;
    }

    public getImage(): string {
        return this.image;
    }

    public setImage(value: string) {
        this.image = value;
    }

    public getScores(): Score[] {
        return this.scores;
    }

    public setScores(value: Score[]) {
        this.scores = value;
    }

    public getOccupations(): string[] {
        return this.occupations;
    }

    public setOccupations(value: string[]) {
        this.occupations = value;
    }

    public getActive(): boolean {
        return this.active;
    }

    public setActive(value: boolean) {
        this.active = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getImageFullPath(): string {
        return this.image ? `${process.env.REACT_APP_ENDPOINT_BACKEND}uploads/recommendation-person/${this.image}` : "";
    }

    public getPhone(): string {
        return this.phone;
    }

    public setPhone(value: string) {
        this.phone = value;
    }

    public getDirection(): string {
        return this.direction;
    }

    public setDirection(value: string) {
        this.direction = value;
    }
}
