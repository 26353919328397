import { custom, serializable } from "serializr";
import { observable }                         from "mobx";
import { date }                               from "../../Serializer/date";

export class ContactUs {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private name: string;

    @serializable
    @observable
    private lastName: string;

    @serializable
    @observable
    private email: string;

    @serializable
    @observable
    private phone: string;

    @serializable
    @observable
    private wishContact: string;

    @serializable
    @observable
    private message: string;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    public getId(): string {
        return this._id;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getLastName(): string {
        return this.lastName;
    }

    public setLastName(value: string) {
        this.lastName = value;
    }

    public getEmail(): string {
        return this.email;
    }

    public setEmail(value: string) {
        this.email = value;
    }

    public getPhone(): string {
        return this.phone;
    }

    public setPhone(value: string) {
        this.phone = value;
    }

    public getWishContact(): string {
        return this.wishContact;
    }

    public setWishContact(value: string) {
        this.wishContact = value;
    }

    public getMessage(): string {
        return this.message;
    }

    public setMessage(value: string) {
        this.message = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }
}
