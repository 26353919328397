import * as React                         from "react";
import Tabs                               from "@material-ui/core/Tabs";
import Tab                                from "@material-ui/core/Tab";
import {
    Checkbox,
    FormControlLabel,
    TextField,
}                                         from "@material-ui/core";
import { Row, Col }                       from "reactstrap";
import SimpleReactValidator               from "simple-react-validator";
import { TabPanel }                       from "../../../Components/TabPanel/TabPanel";
import { GroupButtons }                   from "../../../Components/GroupButtons/GroupButtons";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { observer }                       from "mobx-react";
import { MethodTransport }                from "../../../../Models/MethodTransport/MethodTransport";
import TablePriceByKmsViewModel
                                          from "../../../../ViewModel/MethodTransport/CreateOrEdit/TablePriceByKmsViewModel";

interface INewEditMethodTransportViewProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    methodTransport: MethodTransport;
    validate: () => boolean;
    spinner: boolean;
    save: () => void;
    cancel: () => void;
    validator: SimpleReactValidator;
}

@observer
class CreateOrEditMethodTransportView extends React.Component<INewEditMethodTransportViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  methodTransport,
                  spinner,
                  cancel,
                  validate,
                  save,
                  validator,
                  t,
              } = this.props;

        return (
            <div className={``}>
                <form noValidate autoComplete="off">
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setCurrentTab(newValue);
                        }} aria-label="tabs"
                    >
                        <Tab label={t("edit.general")}/>
                    </Tabs>
                    <hr/>
                    <TabPanel value={currentTab} index={0}>
                        <Row>
                            <Col xs={12} md={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label={t("edit.name")}
                                    className={""}
                                    value={methodTransport.getName() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        methodTransport.setName(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.name"), methodTransport.getName(), "required")}
                                <TextField
                                    fullWidth
                                    label={t("edit.minWeight")}
                                    className={""}
                                    value={methodTransport.getMinWeight() || 0}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        methodTransport.setMinWeight(parseFloat(evt.currentTarget.value) || 0);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.minWeight"), methodTransport.getMinWeight(), "required")}
                                <TextField
                                    fullWidth
                                    label={t("edit.maxWeight")}
                                    className={""}
                                    value={methodTransport.getMaxWeight() || 0}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        methodTransport.setMaxWeight(parseFloat(evt.currentTarget.value));
                                    }}
                                    type="number"
                                    margin="normal"
                                />
                                {validator.message(t("edit.maxWeight"), methodTransport.getMaxWeight(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={methodTransport.getActive()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                methodTransport.setActive(event.currentTarget.checked);
                                            }}
                                            value={methodTransport.getActive()}
                                            color="primary"
                                        />
                                    }
                                    label={t("edit.active")}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={methodTransport.getPickUp()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                methodTransport.setPickUp(event.currentTarget.checked);
                                            }}
                                            value={methodTransport.getPickUp()}
                                            color="primary"
                                        />
                                    }
                                    label={t("global:pickup")}
                                />
                            </Col>
                            <Col xs={12}>
                                <TablePriceByKmsViewModel methodTransport={methodTransport}/>
                            </Col>
                        </Row>
                    </TabPanel>
                    <hr/>
                    <GroupButtons
                        action={save}
                        spinner={spinner}
                        validate={validate}
                        cancel={cancel}
                    />
                </form>
            </div>
        );
    }
}

export default withNamespaces("page")(CreateOrEditMethodTransportView);
