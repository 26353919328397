import * as React                   from "react";
import { observable }               from "mobx";
import {
    inject,
    observer
}                                   from "mobx-react";
import { withNamespaces }           from "react-i18next";
import { withRouter }               from "react-router";
import { LinearProgress }           from "@material-ui/core";
import { FormStore }                from "../../../Store/FormStore";
import {
    PageNames,
    renderRoute
}                                   from "../../../Routes/routes";
import { ColorPaletteStore }        from "../../../Store/ColorPaletteStore";
import { ColorPalette }             from "../../../Models/Color/ColorPalette";
import IBaseProps                   from "../../Props/IBaseProps";
import CreateOrEditColorPaletteView from "../../../Views/Pages/ColorPalette/CreateOrEdit/CreateOrEditColorPaletteView";

interface INewEditColorPaletteViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    ColorPaletteStore?: ColorPaletteStore;
}

@inject(FormStore.NAME_STORE, ColorPaletteStore.NAME_STORE)
@observer
class CreateOrEditColorPaletteViewModel extends React.Component<INewEditColorPaletteViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    constructor(props: INewEditColorPaletteViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }
    }

    public componentDidMount = async (): Promise<void> => {
        const {id} = this.props.match.params;

        if (!id) {
            this.colorPaletteStore.setColorPalette(new ColorPalette());
            return;
        }

        this.colorPaletteStore.setColorPalette(await this.colorPaletteStore.getById(id));

        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get colorPaletteStore(): ColorPaletteStore {
        return this.props.ColorPaletteStore as ColorPaletteStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const colorPalette = await this.colorPaletteStore.save(this.colorPaletteStore.getColorPalette()),
              {history}    = this.props;

        history.push(renderRoute(PageNames.colorPalettePage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.colorPalettePage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    public render(): React.ReactNode {
        if (this.loading || !this.colorPaletteStore.getColorPalette())
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (this.colorPaletteStore.getColorPalette())
            return (
                <CreateOrEditColorPaletteView
                    currentTab={this.getCurrentTab()}
                    setCurrentTab={this.setCurrentTab}
                    currentTabSeo={this.formStore.getCurrentTabSeo()}
                    setCurrentTabSeo={this.formStore.setCurrentTabSeo}
                    colorPalette={this.colorPaletteStore.getColorPalette()}
                    validate={this.validate}
                    spinner={this.getSpinner()}
                    save={this.save}
                    validator={this.formStore.getValidator()}
                    cancel={this.cancel}
                />
            );

        return (<React.Fragment/>);
    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditColorPaletteViewModel));
