import * as React                 from "react";
import { observable }             from "mobx";
import { inject, observer }       from "mobx-react";
import { withNamespaces }         from "react-i18next";
import { withRouter }             from "react-router";
import NewEditGalleryView         from "../../../Views/Pages/Gallery/NewEditGalleryView";
import { Gallery }                from "../../../Models/Gallery/Gallery";
import { GalleryItem }            from "../../../Models/Gallery/GalleryItem";
import { ERROR_CLASS, FormStore } from "../../../Store/FormStore";
import { GalleryStore }           from "../../../Store/GalleryStore";
import { PageNames, renderRoute } from "../../../Routes/routes";
import IBaseProps                 from "../../Props/IBaseProps";
import { LinearProgress }         from "@material-ui/core";

interface INewEditGalleryViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    GalleryStore?: GalleryStore;
}

@inject(FormStore.NAME_STORE, GalleryStore.NAME_STORE)
@observer
class NewEditGalleryViewModel extends React.Component<INewEditGalleryViewModelProps, any> {
    @observable
    private currentTab: number = 0;

    @observable
    private gallery: Gallery;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    constructor(props: INewEditGalleryViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);
    }

    public componentDidMount = async (): Promise<void> => {
        const {id} = this.props.match.params;

        if (!id) {
            this.gallery = new Gallery();
            this.gallery.setPage(true);
            this.gallery.addItem(new GalleryItem());

            return;
        }

        this.loading = true;
        this.gallery = await this.galleryStore.getById(id);
        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get galleryStore(): GalleryStore {
        return this.props.GalleryStore as GalleryStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const gallery   = await this.galleryStore.save(this.gallery),
              {history} = this.props;

        history.push(renderRoute(PageNames.galleryPage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.galleryPage));
    };

    protected validate = () => {
        const valid = this.formStore.getValidator().allValid();

        if (!valid) {
            this.formStore.getValidator().showMessages();
            this.forceUpdate(() => {
                this.formStore.setErrorDOMElements(document.querySelector(`.${ERROR_CLASS}`));
                this.formStore.scrollOnError();
            });
        }

        return valid;
    };

    public render(): React.ReactNode {
        if (!this.gallery) {
            return (<LinearProgress className="mt-4 mb-4"/>);
        }

        return (
            <NewEditGalleryView
                currentTab={this.getCurrentTab()}
                setCurrentTab={this.setCurrentTab}
                gallery={this.gallery}
                validate={this.validate}
                spinner={this.getSpinner()}
                save={this.save}
                validator={this.formStore.getValidator()}
                cancel={this.cancel}
            />
        );
    }
}

export default withNamespaces("forms")(withRouter(NewEditGalleryViewModel));
