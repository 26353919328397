import { observable }                          from "mobx";
import { Product }                             from "./Product";
import { removeItem, reorder, updateSaveItem } from "../../Utils/array";

export class ProductRelationshipInternal {
    @observable
    private product: Product;

    @observable
    private productsRelationship: Product[] = [];

    public getProduct(): Product {
        return this.product;
    }

    public setProduct(value: Product) {
        this.product = value;
    }

    public getProductsRelationship(): Product[] {
        return this.productsRelationship;
    }

    public setProductsRelationship(value: Product[]) {
        this.productsRelationship = value;
    }

    public addProductsRelationship(product: Product): this {
        updateSaveItem(this.productsRelationship, product, "_id" as keyof Product);

        return this;
    }

    public removeProductRelationShip(product: Product): this {
        removeItem(this.productsRelationship, product, "_id" as keyof Product);

        return this;
    }

    public reorderProductRelationShip(startIndex: number, endIndex: number) {
        this.productsRelationship = reorder(this.productsRelationship, startIndex, endIndex);
    }
}