import * as React                      from "react";
import { observable }                  from "mobx";
import { inject, observer }            from "mobx-react";
import { withNamespaces }              from "react-i18next";
import { withRouter }                  from "react-router";
import { LinearProgress }              from "@material-ui/core";
import { FormStore }                   from "../../../Store/FormStore";
import { PageNames, renderRoute }      from "../../../Routes/routes";
import { MethodTransportStore }        from "../../../Store/MethodTransportStore";
import { MethodTransport }             from "../../../Models/MethodTransport/MethodTransport";
import IBaseProps                      from "../../Props/IBaseProps";
import { PageStore }                   from "../../../Store/PageStore";
import CreateOrEditMethodTransportView
                                       from "../../../Views/Pages/MethodTransport/CreateOrEdit/CreateOrEditMethodTransportView";

interface INewEditMethodTransportViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    MethodTransportStore?: MethodTransportStore;
    PageStore?: PageStore;
}

@inject(FormStore.NAME_STORE, MethodTransportStore.NAME_STORE)
@observer
class CreateOrEditMethodTransportViewModel extends React.Component<INewEditMethodTransportViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    constructor(props: INewEditMethodTransportViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }
    }

    public componentDidMount = async (): Promise<void> => {
        const {id} = this.props.match.params;

        if (!id) {
            const methodTransport = new MethodTransport();

            this.methodTransportStore.setMethodTransport(methodTransport);

            return;
        }

        const methodTransport = await this.methodTransportStore.getById(id);

        this.methodTransportStore.setMethodTransport(methodTransport);
        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get methodTransportStore(): MethodTransportStore {
        return this.props.MethodTransportStore as MethodTransportStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const category  = await this.methodTransportStore.save(this.methodTransportStore.getMethodTransport()),
              {history} = this.props;

        history.push(renderRoute(PageNames.methodTransportPage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.methodTransportPage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    public render(): React.ReactNode {
        if (this.loading || !this.methodTransportStore.getMethodTransport())
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (this.methodTransportStore.getMethodTransport())
            return (
                <CreateOrEditMethodTransportView
                    currentTab={this.getCurrentTab()}
                    setCurrentTab={this.setCurrentTab}
                    methodTransport={this.methodTransportStore.getMethodTransport()}
                    validate={this.validate}
                    spinner={this.getSpinner()}
                    save={this.save}
                    validator={this.formStore.getValidator()}
                    cancel={this.cancel}
                />
            );

        return (<React.Fragment/>);
    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditMethodTransportViewModel));
