import { observable }                           from "mobx";
import {
    primitive,
    serializable,
    list,
    custom, object,
}                                               from "serializr";
import { contains, removeItem, updateSaveItem } from "../../Utils/array";
import { date }                                 from "../../Serializer/date";
import Role                                     from "./Role";
import { BillElectronic }                       from "./BillElectronic";
import { Direction }                            from "./Direction";

export default class User {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private active: boolean;

    @serializable
    @observable
    private email: string;

    @serializable
    @observable
    public firstName: string;

    @serializable
    @observable
    public lastName: string;

    @serializable
    @observable
    private username: string;

    @serializable
    @observable
    private password: string;

    @serializable(list(object(Role)))
    @observable
    private roles: Role[] = [];

    @serializable(list(object(Direction)))
    @observable
    private directions: Direction[] = [];

    @serializable(list(object(BillElectronic)))
    @observable
    private billElectronics: BillElectronic[] = [];


    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    public hasRole(role: Role | string): boolean {
        // @ts-ignore
        if (typeof role === "string") role = {name: role} as Role;

        return !!contains(this.roles, role, "name" as keyof Role);
    }

    public getRoles(): Role[] {
        return this.roles;
    }

    public setRoles(roles: Role[]): this {
        this.roles = roles;

        return this;
    }

    public addRole(role: Role): this {
        updateSaveItem(this.roles, role, "name" as keyof Role);

        return this;
    }

    public removeRole(role: Role): this {
        removeItem(this.roles, role, "name" as keyof Role);

        return this;
    }

    public getId(): string {
        return this._id || "";
    }

    public getEmail(): string {
        return this.email || "";
    }

    public setFirstName(value: string) {
        this.firstName = value;
    }

    public getFirstName(): string {
        return this.firstName || "";
    }

    public setLastName(value: string) {
        this.lastName = value;
    }

    public getLastName(): string {
        return this.lastName || "";
    }

    public setEmail(value: string) {
        this.email = value;
    }

    public getUsername(): string {
        return this.username || "";
    }

    public setUsername(value: string) {
        this.username = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getActive(): boolean {
        return this.active;
    }

    public setActive(value: boolean) {
        this.active = value;
    }

    public getPassword(): string {
        return this.password || "";
    }

    public setPassword(value: string) {
        this.password = value;
    }

    public getDirections(): Direction[] {
        return this.directions;
    }

    public getBillElectronics(): BillElectronic[] {
        return this.billElectronics;
    }

    public getFullName(): string {
        return `${this.getFirstName()} ${this.getLastName()}`;
    }
}
