import * as React              from "react";
import { TableCell, TableRow } from "@material-ui/core";
import formatDate              from "../../../config/formatDate";
import Actions                 from "../Table/Actions";
import moment                  from "moment";
import { Occupation }             from "../../../Models/Occupation/Occupation";

interface ITableRowOccupationViewProps {
    occupation: Occupation;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowOccupationView extends React.Component<ITableRowOccupationViewProps, {}> {

    public render(): React.ReactNode {
        const {
                  occupation,
                  onEdit,
                  onDelete,
              }    = this.props;

        return (
            <TableRow>
                <TableCell>{occupation.getName()}</TableCell>
                <TableCell>{moment(occupation.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(occupation.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowOccupationView;
