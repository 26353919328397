import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { observer }                       from "mobx-react";
import * as React                         from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { Order }                          from "../../../Models/Order/Order";
import Stepper                            from "@material-ui/core/Stepper";
import Step                               from "@material-ui/core/Step";
import StepLabel                          from "@material-ui/core/StepLabel";
import { OrderStatus }                    from "../../../config/orderStatus";

interface IStatusProps extends WithNamespaces {
    order: Order;
    prefix?: string;
}

@InjectPrefix
@observer
class Status extends React.Component<IStatusProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {t, order} = this.props;
        let steps        = [
            OrderStatus.confirm
        ];

        if (!order.getMethodTransport().getPickUp()) {
            steps.push(OrderStatus.shipped);
        }

        steps.push(OrderStatus.delivered);

        if (order.getStatus() === OrderStatus.canceled) {
            steps = [OrderStatus.canceled];
        }

        return (
            <Stepper activeStep={steps.indexOf(order.getStatus())} alternativeLabel>
                {steps.map((orderStatus: OrderStatus) => (
                    <Step key={orderStatus}>
                        <StepLabel>{t(orderStatus)}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    }
}

export default withNamespaces("order")(Status);