export function contains<T extends { [key: string]: any }, K extends keyof T>(arr: T[], element: T, key?: K): T | undefined {
    return arr.find(item => {

        if (key && item.hasOwnProperty(key)) {
            return item[key] === element[key];
        }

        if (key && typeof item.key === "function") {
            return item.key() === element.key();
        }

        return item == element;
    });
}

export function updateSaveItem<T, K extends keyof T>(arr: T[], element: T, attr?: K): T[] {
    const found: T | undefined = contains(arr, element, attr);

    if (!found) {
        arr.push(element);

        return arr;
    }

    const index = getIndex(arr, found);

    if (~index) arr[index] = element;

    return arr;

}

export function getIndex<T>(arr: T[], element: T) {
    return arr.indexOf(element);
}

export function removeItem<T, K extends keyof T>(arr: T[], element: T, key?: K): T[] {
    const found: T | undefined = contains(arr, element, key);

    if (!found) {
        return arr;
    }

    const index = getIndex(arr, found);

    if (~index) {
        arr.splice(index, 1);
    }

    return arr;
}

// a little function to help us with reordering the result
export const reorder = <T = any>(list: T[], startIndex: number, endIndex: number) => {
    const result    = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
