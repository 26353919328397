import BaseStore                               from "./Base/BaseStore";
import { deserialize, serialize }              from "serializr";
import { createFormData }                      from "../Utils/common";
import { Container }                           from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { observable }                          from "mobx";
import { globalPagerOptions, IPaginationData } from "../config/pagination";
import { AboutUs }                             from "../Models/AboutUs/AboutUs";

export class AboutUsStore extends BaseStore {
    public static readonly NAME_STORE: string = "AboutUsStore";

    @observable
    private aboutUss: AboutUs[] = [];

    @observable
    private aboutUs: AboutUs;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getAboutUs(): AboutUs {
        return this.aboutUs;
    }

    public setAboutUs(value: AboutUs): this {
        this.aboutUs = value;

        return this;
    }

    public getAboutUss(): AboutUs[] {
        return this.aboutUss;
    }

    public setAboutUss(value: AboutUs[]): this {
        this.aboutUss = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<AboutUs>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateAboutUs(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(AboutUs, item)),
            pages: dataResponse.pages
        } as IPaginationData<AboutUs>;
    }

    public async getById(id: string): Promise<AboutUs> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getAboutUsId(id);

        return deserialize(AboutUs, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deleteAboutUsId(id);

        return success;
    }

    public async save(aboutUs: AboutUs): Promise<AboutUs> {
        const data                         = serialize(aboutUs),
              {data: {data: dataResponse}} = await this.getAjaxService().saveAboutUs(data);

        return deserialize(AboutUs, dataResponse);
    }
}
