import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import { Page }                            from "../../../Models/Page/Page";
import TableRowPageView                    from "../../../Views/Components/TableRowPage/TableRowPageView";
import DeletePageViewModel                 from "../Delete/DeletePageViewModel";

interface ITableRowPageViewModelProps extends RouteComponentProps<any> {
    page: Page;
}

class TableRowPageViewModel extends React.Component<ITableRowPageViewModelProps, {}> {
    protected onEdit = () => {
        const {history, page} = this.props;
        history.push(renderRoute(PageNames.pageEditPage, {id: page.getId()}));
    };

    protected onDelete = () => {
        const {page} = this.props;
        Container.get(ModalService).openModal(DeletePageViewModel, {
            page
        });
    };

    public render(): React.ReactNode {
        const {page} = this.props;

        return (
            <TableRowPageView
                page={page}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowPageViewModel);
