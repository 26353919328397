import * as React    from "react";
import HomeView      from "../Views/Pages/HomeView";
import { inject }    from "mobx-react";
import { UserStore } from "../Store/UserStore";
import User          from "../Models/User/User";

interface HomeViewModelProps {
    UserStore: UserStore;
}

@inject(UserStore.NAME_STORE)
class HomeViewModel extends React.Component<HomeViewModelProps, {}> {
    get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    public componentDidMount = (): void => {
        this.userStore.getUserMeApi();
    };

    public render(): React.ReactNode {
        return (
            <HomeView
                user={this.props.UserStore.getUser() as User}
            />
        );
    }
}

export default HomeViewModel;
