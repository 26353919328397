import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { observer }                       from "mobx-react";
import * as React                         from "react";
import { TextField }                      from "@material-ui/core";
import { GalleryItem, IImageSelect }      from "../../../Models/Gallery/GalleryItem";
import { Row, Col }                       from "reactstrap";
import DragHandleIcon                     from "@material-ui/icons/DragHandle";
import DeleteForeverIcon                  from "@material-ui/icons/DeleteForever";
import { WithNamespaces, withNamespaces } from "react-i18next";
import UploadImage                        from "../Form/Upload/UploadImage";
import SimpleReactValidator               from "simple-react-validator";

interface IRowGalleryProps extends WithNamespaces {
    item: GalleryItem;
    itemSelected: number;
    dragHandleProps: any;
    prefix?: string;
    commonProps: {
        removeItem: (item: GalleryItem) => void;
        validator: SimpleReactValidator;
    };
}

const dragElementClass = "drag-element";

@InjectPrefix
@observer
class RowGallery extends React.Component<IRowGalleryProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected showImage = (galleryItem: GalleryItem): React.ReactNode => {
        const imageSelect = galleryItem.getImageSelect(),
              fullPath    = galleryItem.getImageFullPath();

        if (!imageSelect && !fullPath) return <React.Fragment/>;

        return (<img
            src={imageSelect ? imageSelect.base64 : fullPath}
            className="img-fluid"
        />);
    };

    public render(): React.ReactNode {
        const {
                  item: galleryItem,
                  dragHandleProps,
                  itemSelected,
                  t,
                  commonProps
              }                           = this.props,
              scale                       = itemSelected * 0.05 + 1,
              shadow                      = itemSelected * 15 + 1,
              {onTouchStart, onMouseDown} = dragHandleProps,
              {removeItem}                = commonProps;

        galleryItem.setOrder(itemSelected);

        return (
            <Row className={`${this.prefix}-gallery-row-image`}
                 style={{
                     boxShadow: `rgba(0, 0, 0, 0.3) 0px ${shadow}px ${2 * shadow}px 0px`,
                     transform: `scale(${scale})`
                 }}
                 onTouchStart={(e) => {
                     const targetElement = (e.target as HTMLElement);

                     if (targetElement.classList.contains(dragElementClass))
                         onTouchStart(e);
                 }}
                 onMouseDown={(e) => {
                     const targetElement = (e.target as HTMLElement);

                     if (targetElement.classList.contains(dragElementClass))
                         onMouseDown(e);
                 }}
            >
                <Col xs={1} className={dragElementClass}>
                    <div className={dragElementClass}>
                        <DragHandleIcon className={dragElementClass}/>
                    </div>
                </Col>
                <Col xs={4}>
                    <TextField
                        fullWidth
                        label={t("gallery.title")}
                        className={""}
                        value={galleryItem.getTitle()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            galleryItem.setTitle(evt.currentTarget.value);
                        }}
                        margin="normal"
                    />
                </Col>
                <Col xs={3}>
                    <TextField
                        fullWidth
                        label={t("gallery.subtitle")}
                        className={""}
                        value={galleryItem.getSubtitle()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            galleryItem.setSubtitle(evt.currentTarget.value);
                        }}
                        margin="normal"
                    />
                </Col>
                <Col xs={3}>
                    <UploadImage
                        onDrop={(files: File[], picturesBase64: string[]) => {
                            const file   = files[files.length - 1],
                                  base64 = picturesBase64[picturesBase64.length - 1];

                            if (!(file && base64)) return;

                            galleryItem.setImageSelect({
                                                           base64,
                                                           file
                                                       });
                        }}
                    />
                    {this.showImage(galleryItem)}
                </Col>
                <Col xs={1}>
                    <a onClick={() => removeItem(galleryItem)} href="javascript:void(0);" className={"remove-item"}>
                        <DeleteForeverIcon color={"primary"}/>
                    </a>
                </Col>
            </Row>
        );
    }
}

export default withNamespaces("page")(RowGallery);
