import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { observer }                       from "mobx-react";
import * as React                         from "react";
import { TextField }                      from "@material-ui/core";
import { Row, Col }                       from "reactstrap";
import DragHandleIcon                     from "@material-ui/icons/DragHandle";
import DeleteForeverIcon                  from "@material-ui/icons/DeleteForever";
import { WithNamespaces, withNamespaces } from "react-i18next";
import SimpleReactValidator               from "simple-react-validator";
import { PromotionInformation }           from "../../../Models/Promotion/PromotionInformation";

interface IRowInformationProps extends WithNamespaces {
    item: PromotionInformation;
    itemSelected: number;
    dragHandleProps: any;
    prefix?: string;
    commonProps: {
        removeItem: (item: PromotionInformation) => void;
        validator: SimpleReactValidator;
    };
}

const dragElementClass = "drag-element";

@InjectPrefix
@observer
class RowInformation extends React.Component<IRowInformationProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  item: promotion,
                  dragHandleProps,
                  itemSelected,
                  t,
                  commonProps
              }                           = this.props,
              scale                       = itemSelected * 0.05 + 1,
              shadow                      = itemSelected * 15 + 1,
              {onTouchStart, onMouseDown} = dragHandleProps,
              {removeItem}                = commonProps;

        promotion.setOrder(itemSelected);

        return (
            <Row className={`${this.prefix}-gallery-row-image`}
                 style={{
                     boxShadow: `rgba(0, 0, 0, 0.3) 0px ${shadow}px ${2 * shadow}px 0px`,
                     transform: `scale(${scale})`
                 }}
                 onTouchStart={(e) => {
                     const targetElement = (e.target as HTMLElement);

                     if (targetElement.classList.contains(dragElementClass))
                         onTouchStart(e);
                 }}
                 onMouseDown={(e) => {
                     const targetElement = (e.target as HTMLElement);

                     if (targetElement.classList.contains(dragElementClass))
                         onMouseDown(e);
                 }}
            >
                <Col xs={1} className={dragElementClass}>
                    <div className={dragElementClass}>
                        <DragHandleIcon className={dragElementClass}/>
                    </div>
                </Col>
                <Col xs={5}>
                    <TextField
                        fullWidth
                        label={t("promotion.title")}
                        className={""}
                        value={promotion.getTitle() || ""}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            promotion.setTitle(evt.currentTarget.value);
                        }}
                        margin="normal"
                    />
                </Col>
                <Col xs={5}>
                    <TextField
                        fullWidth
                        label={t("promotion.description")}
                        className={""}
                        value={promotion.getDescription() || ""}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            promotion.setDescription(evt.currentTarget.value);
                        }}
                        margin="normal"
                    />
                </Col>
                <Col xs={1}>
                    <a onClick={() => removeItem(promotion)} href="javascript:void(0);" className={"remove-item"}>
                        <DeleteForeverIcon color={"primary"}/>
                    </a>
                </Col>
            </Row>
        );
    }
}

export default withNamespaces("page")(RowInformation);
