import * as React             from "react";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Chip,
}                             from "@material-ui/core";
import {
    withNamespaces,
    WithNamespaces,
}                             from "react-i18next";
import SearchIcon             from "@material-ui/icons/Search";
import InjectPrefix           from "../../../../Decorators/InjectPrefix";
import Pagination             from "../../../Components/Pagination/Pagination";
import { Order }              from "../../../../Models/Order/Order";
import TableRowOrderViewModel from "../../../../ViewModel/Order/View/TableRowOrderViewModel";
import { OrderFilter }        from "../../../../Models/Order/OrderFilter";
import { OrderStatus }        from "../../../../config/orderStatus";
import { observer }           from "mobx-react";
import { Col, Row }           from "reactstrap";
import { MethodsPay }         from "../../../../config/MethodsPay";
import { MethodTransport }    from "../../../../Models/MethodTransport/MethodTransport";

interface IOrderViewProps extends WithNamespaces {
    spinner: boolean;
    prefix?: string;
    orderFilter: OrderFilter;
    methodsTransport: MethodTransport[];
    getFilter: () => void;
}

@InjectPrefix
@observer
class OrderFilterView extends React.Component<IOrderViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t,
                  orderFilter,
                  getFilter,
                  spinner,
                  methodsTransport
              }            = this.props,
              propsButtons = {
                  color  : "primary" as "primary",
                  onClick: () => { if (!spinner) getFilter(); },
                  size   : "small" as "small",
                  variant: "contained" as "contained"
              };

        return (
            <Row className="mb-3 mt-3 align-items-center">
                <Col xs={4}>
                    <TextField
                        id="form-name"
                        label={t("edit.name-client")}
                        fullWidth
                        value={orderFilter.getNameClient()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            orderFilter.setNameClient(evt.target.value);
                        }}
                        inputProps={{
                            autoComplete: "off",
                        }}
                        margin="normal"
                        variant="outlined"
                    />
                </Col>
                <Col xs={4}>
                    <FormControl fullWidth variant="outlined" className="wish-contact">
                        <InputLabel htmlFor="order-status" variant="outlined">
                            {t("edit.status")}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            required
                            fullWidth
                            multiple
                            value={orderFilter.getOrderStatus()}
                            onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                                orderFilter.setOrderStatus(event.target.value as OrderStatus[]);
                            }}
                            renderValue={() => (
                                <div>
                                    {orderFilter.getOrderStatus().map((value: OrderStatus) => (
                                        <Chip key={value} label={t(`order:${value}`)}/>
                                    ))}
                                </div>
                            )}
                            inputProps={{
                                id  : "order-status",
                                name: "order-status",
                            }}
                        >
                            {
                                Object.values(OrderStatus).map((item: string) => {
                                    return (
                                        <MenuItem value={item} key={item}>
                                            {t(`order:${item}`)}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={4}>
                    <FormControl fullWidth variant="outlined" className="wish-contact">
                        <InputLabel htmlFor="order-status" variant="outlined">
                            {t("edit.method-pay")}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            required
                            fullWidth
                            multiple
                            value={orderFilter.getMethodPay()}
                            onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                                orderFilter.setMethodPay(event.target.value as MethodsPay[]);
                            }}
                            inputProps={{
                                id  : "order-status",
                                name: "order-status",
                            }}
                            renderValue={() => (
                                <div>
                                    {orderFilter.getMethodPay().map((value: string) => (
                                        <Chip
                                            key={value}
                                            // @ts-ignore
                                            label={t(`methods-pay:${value}`)}
                                        />
                                    ))}
                                </div>
                            )}
                        >
                            {
                                Object.values(MethodsPay).map((item: string) => {
                                    return (
                                        <MenuItem value={item} key={item}>
                                            {t(`methods-pay:${item}`)}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={4}>
                    <FormControl fullWidth variant="outlined" className="wish-contact">
                        <InputLabel htmlFor="order-status" variant="outlined">
                            {t("edit.method-transport")}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            required
                            fullWidth
                            multiple
                            value={orderFilter.getMethodsTransportId()}
                            onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                                orderFilter.setMethodsTransportId(event.target.value as string[]);
                            }}
                            renderValue={() => (
                                <div>
                                    {orderFilter.getMethodsTransportId().map((value: string) => {
                                        const methodTransport = methodsTransport.find(item => item.getId() === value);

                                        return (
                                            <Chip
                                                key={value}
                                                // @ts-ignore
                                                label={methodTransport ? methodTransport.getName() : ""}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                            inputProps={{
                                id  : "order-status",
                                name: "order-status",
                            }}
                        >
                            {
                                methodsTransport.map((item: MethodTransport) => {
                                    return (
                                        <MenuItem value={item.getId()} key={item.getId()}>
                                            {item.getName()}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </Col>
                <Col xs={4} className="justify-content-end">
                    <Button {...propsButtons}>
                        <SearchIcon/> {t("edit.search")}
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default withNamespaces("page")(OrderFilterView);
