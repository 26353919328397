import InjectPrefix from "../../../Decorators/InjectPrefix";
import * as React   from "react";
import {
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox
}                   from "@material-ui/core";
import { observer } from "mobx-react";

interface IMultiCheckBoxProps<T> {
    onChange: (item: T) => void;
    options: T[];
    toStringItem: (item: T) => string;
    toStringItemValue: (item: T) => string;
    isChecked: (item: T) => boolean;
    prefix?: string;
    label: string;
    wrapComponentLabel?: Element | React.ReactNode | JSX.Element | React.ComponentType;
    wrapComponentGroup?: Element | React.ReactNode | JSX.Element | React.ComponentType;
}

@InjectPrefix
@observer
export class MultiCheckBox<T> extends React.Component<IMultiCheckBoxProps<T>, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        const {
                  label,
                  options,
                  onChange,
                  toStringItem,
                  toStringItemValue,
                  isChecked,
              } = this.props;

        // tslint:disable-next-line:variable-name
        const WrapComponentLabel: typeof React.Component = (this.props.wrapComponentLabel || React.Fragment) as typeof React.Component,
              // tslint:disable-next-line:variable-name
              WrapComponentGroup: typeof React.Component = (this.props.wrapComponentGroup || React.Fragment) as typeof React.Component;

        // @ts-ignore
        return (
            <div className={`${this.prefix}-multi-checkbox`}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        {label}
                    </FormLabel>
                    <FormGroup>
                        <WrapComponentGroup>
                            {options.map((item: T, key: number) => {
                                const title = toStringItem(item),
                                      value = toStringItemValue(item);

                                return (
                                    /* // @ts-ignore */
                                    <WrapComponentLabel key={`${title}-${key}`}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={isChecked(item)}
                                                onChange={() => onChange(item)}
                                                value={value}/>
                                            }
                                            label={title}
                                        />
                                    </WrapComponentLabel>
                                );
                            })}
                        </WrapComponentGroup>
                    </FormGroup>
                </FormControl>
            </div>
        );
    }
}