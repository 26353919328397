import * as React                 from "react";
import { observable }             from "mobx";
import { inject, observer }       from "mobx-react";
import { withNamespaces }         from "react-i18next";
import { withRouter }             from "react-router";
import { LinearProgress }         from "@material-ui/core";
import { FormStore }              from "../../../Store/FormStore";
import { PageNames, renderRoute } from "../../../Routes/routes";
import { UserStore }              from "../../../Store/UserStore";
import IBaseProps                 from "../../Props/IBaseProps";
import User                       from "../../../Models/User/User";
import Role                       from "../../../Models/User/Role";
import CreateOrEditUserView       from "../../../Views/Pages/User/CreateOrEdit/CreateOrEditUserView";

interface ICreateOrEditUserViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    UserStore?: UserStore;
}

@inject(FormStore.NAME_STORE, UserStore.NAME_STORE)
@observer
class CreateOrEditUserViewModel extends React.Component<ICreateOrEditUserViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    @observable
    private roles: Role[];

    constructor(props: ICreateOrEditUserViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }
    }

    public componentDidMount = async (): Promise<void> => {
        const {id} = this.props.match.params;
        this.setRoles(await this.userStore.getRolesAssignable());

        if (!id) {
            this.userStore.setUserCMS(new User());

            return;
        }

        const user = await this.userStore.getById(id);

        this.userStore.setUserCMS(user);

        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const user      = await this.userStore.save(this.userStore.getUserCMS()),
              {history} = this.props;

        history.push(renderRoute(PageNames.userPage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public setRoles = (roles: Role[]) => {
        this.roles = roles;
    };

    public getRoles = (): Role[] => {
        return this.roles;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.userPage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    protected onChangeRole = (role: Role) => {
        const user = this.userStore.getUserCMS();

        if (user.hasRole(role)) user.removeRole(role);
        else user.addRole(role);
    };

    public render(): React.ReactNode {
        if (this.loading || !this.userStore.getUserCMS())
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (!this.userStore.getUserCMS())
            return (<React.Fragment/>);

        return (
            <CreateOrEditUserView
                currentTab={this.getCurrentTab()}
                setCurrentTab={this.setCurrentTab}
                user={this.userStore.getUserCMS()}
                validate={this.validate}
                spinner={this.getSpinner()}
                save={this.save}
                validator={this.formStore.getValidator()}
                cancel={this.cancel}
                assignableRoles={this.getRoles()}
                onChangeRole={this.onChangeRole}
            />
        );


    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditUserViewModel));
