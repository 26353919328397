import { AxiosRequestConfig } from "axios";

export const CONFIG_GET_ACCESS_TOKEN = <AxiosRequestConfig> {
   baseURL    : process.env.REACT_APP_ENDPOINT_BACKEND,
   headers    : {
       "content-type": "application/x-www-form-urlencoded"
   }
};

export const PARAMS_GET_ACCESS_TOKEN = {
    client_id    : process.env.REACT_APP_API_BACKEND_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_BACKEND_CLIENT_SECRET,
    grant_type   : process.env.REACT_APP_API_BACKEND_GRANT_TYPE,
};

export const CONFIG_CALL_GET_API = <AxiosRequestConfig> {
    baseURL    : process.env.REACT_APP_ENDPOINT_BACKEND,
    headers    : {
        "authorization" : "Bearer %s",
        "content-type": "application/x-www-form-urlencoded"
    }
};

export const CONFIG_CALL_POST_API = <AxiosRequestConfig> {
    baseURL    : process.env.REACT_APP_ENDPOINT_BACKEND,
    headers    : {
        "authorization" : "Bearer %s",
        "content-type": "application/json"
    }
};
