import * as React                         from "react";
import { observer }                       from "mobx-react";
import { Login }                          from "../../../Models/Form/Login";
import { TextField }                      from "@material-ui/core";
import { WithNamespaces, withNamespaces } from "react-i18next";
import SimpleReactValidator               from "simple-react-validator";
import InjectPrefix                       from "../../../Decorators/InjectPrefix";
import { ERROR_CLASS }                    from "../../../Store/FormStore";
import ErrorOutlineIcon                   from "@material-ui/icons/ErrorOutline";
import { Button }                         from "../Button/Button";

interface ILoginFormViewProps extends WithNamespaces {
    login: Login;
    onSubmit: (element: React.Component) => void;
    validator: SimpleReactValidator;
    prefix?: string;
    invalidCredentials: boolean;
    spinner: boolean;
}

@InjectPrefix
@observer
class LoginFormView extends React.Component<ILoginFormViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t,
                  login,
                  onSubmit,
                  validator,
                  spinner,
                  invalidCredentials
              } = this.props;

        return (
            <form className={`${this.prefix}-form-login`} onSubmit={() => onSubmit(this)}>
                <div className="text-center">
                    <img
                        src="/assets/img/components/logo/logo.png"
                        alt="logo"
                        className="img-fluid logo d-inline-block"
                    />
                </div>
                <TextField
                    autoComplete="off"
                    autoFocus
                    id="username"
                    label="Username"
                    placeholder="Username"
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    value={login.getUsername()}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        login.setUsername(evt.target.value);
                    }}
                />
                {validator.message("username", login.getUsername(), "required")}
                <TextField
                    autoComplete="off"
                    id="password"
                    label="Password"
                    placeholder="Password"
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    value={login.getPassword()}
                    type="password"
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        login.setPassword(evt.target.value);
                    }}
                />
                {validator.message("password", login.getPassword(), "required")}
                {invalidCredentials && (
                    <div className={`${this.prefix}-error-color ${ERROR_CLASS}`}>
                        <ErrorOutlineIcon/> {t("invalid-credentials-login")}
                    </div>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className="mt-4"
                    onClick={() => onSubmit(this)}
                    spinner={spinner}
                    text={t("login")}
                />
            </form>
        );
    }
}

export default withNamespaces("forms")(LoginFormView);
