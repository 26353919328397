import BaseStore          from "./Base/BaseStore";
import {
    deserialize,
    serialize
}                         from "serializr";
import { createFormData } from "../Utils/common";
import { Container }      from "typedi";
import AjaxService        from "../Service/AjaxService";
import { observable }     from "mobx";
import {
    globalPagerOptions,
    IPaginationData
}                         from "../config/pagination";
import { ColorPalette }   from "../Models/Color/ColorPalette";

export class ColorPaletteStore extends BaseStore {
    public static readonly NAME_STORE: string = "ColorPaletteStore";

    @observable
    private colorPalettes: ColorPalette[] = [];

    @observable
    private colorPalette: ColorPalette;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getColorPalette(): ColorPalette {
        return this.colorPalette;
    }

    public setColorPalette(value: ColorPalette): this {
        this.colorPalette = value;

        return this;
    }

    public getColorPalettes(): ColorPalette[] {
        return this.colorPalettes;
    }

    public setColorPalettes(value: ColorPalette[]): this {
        this.colorPalettes = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<ColorPalette>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateColorPalette(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(ColorPalette, item)),
            pages: dataResponse.pages
        } as IPaginationData<ColorPalette>;
    }

    public async getById(id: string): Promise<ColorPalette> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getColorPaletteId(id);

        return deserialize(ColorPalette, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deleteColorPaletteId(id);

        return success;
    }

    public async save(colorPalette: ColorPalette): Promise<ColorPalette> {
        const data                         = serialize(colorPalette),
              {data: {data: dataResponse}} = await this.getAjaxService().saveColorPalette(data);

        return deserialize(ColorPalette, dataResponse);
    }

    public async getSimpleAll(): Promise<ColorPalette[]> {
        const {data: {data}} = await this.getAjaxService().getSimpleAll();

        return data.map((item: any) => deserialize(ColorPalette, item));
    }
}
