import * as React            from "react";
import {
    inject,
    observer
}                            from "mobx-react";
import {
    RouteComponentProps,
    withRouter
}                            from "react-router";
import {
    PageNames,
    renderRoute
}                            from "../../../Routes/routes";
import ColorPaletteView      from "../../../Views/Pages/ColorPalette/View/ColorPaletteView";
import { ColorPaletteStore } from "../../../Store/ColorPaletteStore";

interface IColorPaletteViewModelProps extends RouteComponentProps<any> {
    ColorPaletteStore?: ColorPaletteStore;
}

@inject(ColorPaletteStore.NAME_STORE)
@observer
class ColorPaletteViewModel extends React.Component<IColorPaletteViewModelProps, {}> {
    get colorPaletteStore(): ColorPaletteStore {
        return this.props.ColorPaletteStore as ColorPaletteStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.colorPaletteStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.colorPaletteStore.getPaginate();

        this.colorPaletteStore
            .setPages(response.pages)
            .setColorPalettes(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <ColorPaletteView
                colorPalettes={this.colorPaletteStore.getColorPalettes()}
                spinner={this.colorPaletteStore.getSpinner()}
                add={() => history.push(renderRoute(PageNames.colorPaletteAddPage))}
                getPage={this.getPage}
                pages={this.colorPaletteStore.getPages()}
                currentPage={this.colorPaletteStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(ColorPaletteViewModel);
