import * as React           from "react";
import Tabs                 from "@material-ui/core/Tabs";
import Tab                  from "@material-ui/core/Tab";
import {
    TextField,
}                           from "@material-ui/core";
import {
    Row,
    Col
}                                from "reactstrap";
import SimpleReactValidator      from "simple-react-validator";
import { TabPanel }              from "../../../Components/TabPanel/TabPanel";
import { GroupButtons }          from "../../../Components/GroupButtons/GroupButtons";
import {
    withNamespaces,
    WithNamespaces
}                                from "react-i18next";
import { observer }              from "mobx-react";
import { ColorPalette }          from "../../../../Models/Color/ColorPalette";
import ColorsCollectionViewModel
                                 from "../../../../ViewModel/ColorPalette/CreateOrEdit/ColorsCollection/ColorsCollectionViewModel";

interface INewEditColorPaletteViewProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    currentTabSeo: number;
    setCurrentTabSeo: (tab: number) => void;
    colorPalette: ColorPalette;
    validate: () => boolean;
    spinner: boolean;
    save: () => void;
    cancel: () => void;
    validator: SimpleReactValidator;
}

@observer
class CreateOrEditColorPaletteView extends React.Component<INewEditColorPaletteViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  colorPalette,
                  spinner,
                  cancel,
                  validate,
                  save,
                  validator,
                  t
              } = this.props;

        return (
            <div className={``}>
                <form noValidate autoComplete="off">
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setCurrentTab(newValue);
                        }} aria-label="tabs"
                    >
                        <Tab label={t("edit.general")}/>
                        <Tab label={t("colors")}/>
                    </Tabs>
                    <hr/>
                    <TabPanel value={currentTab} index={0}>
                        <Row>
                            <Col xs={12} md={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label={t("edit.name")}
                                    className={""}
                                    value={colorPalette.getName() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        colorPalette.setName(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.name"), colorPalette.getName(), "required")}
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <ColorsCollectionViewModel colorPalette={colorPalette}/>
                    </TabPanel>
                    <hr/>
                    <GroupButtons
                        action={save}
                        spinner={spinner}
                        validate={validate}
                        cancel={cancel}
                    />
                </form>
            </div>
        );
    }
}

export default withNamespaces("page")(CreateOrEditColorPaletteView);
