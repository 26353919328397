import * as React      from "react";
import LocationMapView from "../../Views/Components/Maps/LocationMapView";
import { Order }       from "../../Models/Order/Order";

interface LocationMapViewModelProps {
    order: Order;
}

class LocationMapViewModel extends React.Component<LocationMapViewModelProps, {}> {
    public render(): React.ReactNode {
        const {order} = this.props;

        return (
            <LocationMapView
                keyGoogleMaps={process.env.REACT_APP_GOOGLE_MAP_KEY as string}
                order={order}
            />
        );
    }
}

export default LocationMapViewModel;
