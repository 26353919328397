import { observer }                                                    from "mobx-react";
import * as React                                                      from "react";
import { MethodTransport }                                             from "../../../Models/MethodTransport/MethodTransport";
import { WithNamespaces, withNamespaces }                              from "react-i18next";
import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import InjectPrefix                                                    from "../../../Decorators/InjectPrefix";
import { PriceByRange }                                                from "../../../Models/MethodTransport/PriceByRange";
import { Button }                                                      from "../Button/Button";

interface ITablePriceByKmsProps extends WithNamespaces {
    methodTransport: MethodTransport;
    prefix?: string;
}

@InjectPrefix
@observer
class TablePriceByKms extends React.Component<ITablePriceByKmsProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderList = (): React.ReactNode [] => {
        const {
                  methodTransport,
                  t,
              } = this.props;

        return methodTransport.getPriceByKmRange().map((item: PriceByRange) => {
            return (
                <TableRow className="item" key={item.getUuid()}>
                    <TableCell className="">
                        <TextField
                            fullWidth
                            label={t("initKm")}
                            className={""}
                            value={item.getInitKm() || 0}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                item.setInitKm(parseFloat(evt.currentTarget.value) || 0);
                            }}
                            margin="normal"
                        />
                    </TableCell>
                    <TableCell className="">
                        <TextField
                            fullWidth
                            label={t("endKm")}
                            className={""}
                            value={item.getEndKm() || 0}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                item.setEndKm(parseFloat(evt.currentTarget.value) || 0);
                            }}
                            margin="normal"
                        />
                    </TableCell>
                    <TableCell className="">
                        <TextField
                            fullWidth
                            label={t("priceByKm")}
                            className={""}
                            value={item.getPriceByKm() || 0}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                item.setPriceByKm(parseFloat(evt.currentTarget.value) || 0);
                            }}
                            margin="normal"
                        />
                    </TableCell>
                    <TableCell className="">
                        <Button
                            key={"delete"}
                            variant={"outlined"}
                            color={"primary"}
                            colorSpinner={"primary"}
                            size={"medium"}
                            text={"Eliminar"}
                            onClick={() => methodTransport.removePriceByKmRange(item)}
                            spinner={false}
                        />
                    </TableCell>
                </TableRow>
            );
        });
    };

    public render(): React.ReactNode {
        const {
                  methodTransport,
                  t,
              } = this.props;

        return (
           <section className="mt-5">
               <Table className={`${this.prefix}-table-products`}>
                   <TableHead className="table-head">
                       <TableRow>
                           <TableCell>
                               {t("initKm")}
                           </TableCell>
                           <TableCell>
                               {t("endKm")}
                           </TableCell>
                           <TableCell>
                               {t("priceByKm")}
                           </TableCell>
                           <TableCell>
                               {t("remove")}
                           </TableCell>
                       </TableRow>
                   </TableHead>
                   <TableBody>
                       {this.renderList()}
                   </TableBody>
               </Table>
               <Button
                   variant={"outlined"}
                   color={"primary"}
                   colorSpinner={"primary"}
                   size={"medium"}
                   text={"Agregar"}
                   onClick={() => methodTransport.addPriceByKmRange(new PriceByRange())}
                   spinner={false}
               />
           </section>
        );
    }
}

export default withNamespaces("global")(TablePriceByKms);