import { custom, serializable } from "serializr";
import { observable }           from "mobx";
import { date }                 from "../../Serializer/date";
import { OrderStatus }          from "../../config/orderStatus";

class StatusHistory {
    @serializable
    @observable
    private status: OrderStatus;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;


    public getStatus(): OrderStatus {
        return this.status;
    }

    public setStatus(value: OrderStatus) {
        this.status = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }
}

export { StatusHistory };
