import { observable }          from "mobx";
import BaseStore               from "./Base/BaseStore";
import { Login }               from "../Models/Form/Login";
import SimpleReactValidator    from "simple-react-validator";
import * as React              from "react";
import { prefix }              from "../Utils/prefix";
import { TranslationFunction } from "i18next";
import { scrollToElement }     from "../Utils/common";
import ErrorOutlineIcon        from "@material-ui/icons/ErrorOutline";

export const ERROR_CLASS = "element-error";

export class FormStore extends BaseStore {
    public static readonly NAME_STORE: string = "FormStore";

    @observable
    private login: Login;

    @observable
    private validator: SimpleReactValidator;

    private errorDOMElement: HTMLElement | null;

    @observable
    private currentTabSeo: number = 0;

    public getCurrentTabSeo = (): number => {
        return this.currentTabSeo;
    };

    public setCurrentTabSeo = (value: number) => {
        this.currentTabSeo = value;
    };

    public setErrorDOMElements = (elements: HTMLElement | null) => {
        this.errorDOMElement = elements;
    };

    public scrollOnError = () => {
        //  animate scroll if some error appears
        if (this.errorDOMElement) scrollToElement(this.errorDOMElement, 1000, 180);
    };

    public initValidator(t: TranslationFunction) {
        this.validator = new SimpleReactValidator({
                                                      // autoForceUpdate: this,
                                                      element : ((message: string) => (
                                                          <div
                                                              className={`${prefix}-error-color ${ERROR_CLASS}`}>
                                                              <ErrorOutlineIcon/> {message}</div>
                                                      )),
                                                      locale  : "es",
                                                      messages: {
                                                          required: t("forms:field-required")
                                                      }
                                                  });
    }

    public getValidator = (): SimpleReactValidator => {
        return this.validator;
    };

    public getLogin(): Login {
        return this.login;
    }

    public setLogin(value: Login) {
        this.login = value;
    }

    protected init() {
        this.needPersistData = false;
    }

    public actionValidate(ele: React.Component) {
        const valid = this.getValidator().allValid();

        if (!valid) {
            this.getValidator().showMessages();
            ele.forceUpdate(() => {
                this.setErrorDOMElements(document.querySelector(`.${ERROR_CLASS}`));
                this.scrollOnError();
            });
        }

        return valid;
    }
}
