import { custom, object, serializable } from "serializr";
import { observable }                   from "mobx";
import { date }                         from "../../Serializer/date";
import { SeoData }                      from "../Page/SeoData";

export class AboutUs {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private name: string;

    @serializable
    @observable
    private active: boolean;

    @serializable
    @observable
    private description: string;

    @serializable(object(SeoData))
    @observable
    private seoData: SeoData;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    constructor() {
        this.setSeoData(new SeoData());
    }

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getActive(): boolean {
        return this.active;
    }

    public setActive(value: boolean) {
        this.active = value;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(value: string) {
        this.description = value;
    }

    public getSeoData(): SeoData {
        return this.seoData;
    }

    public setSeoData(value: SeoData) {
        this.seoData = value;
    }
}
