import * as React                         from "react";
import Tabs                               from "@material-ui/core/Tabs";
import Tab                                from "@material-ui/core/Tab";
import {
    Checkbox,
    FormControlLabel,
    TextField,
}                                         from "@material-ui/core";
import { Row, Col }                       from "reactstrap";
import SimpleReactValidator               from "simple-react-validator";
import { TabPanel }                       from "../../../Components/TabPanel/TabPanel";
import { GroupButtons }                   from "../../../Components/GroupButtons/GroupButtons";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { observer }                       from "mobx-react";
import User                               from "../../../../Models/User/User";
import Role                               from "../../../../Models/User/Role";
import { MultiCheckBox }                  from "../../../Components/MultiCheckBox/MultiCheckBox";

interface ICreateOrEditUserViewProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    user: User;
    validate: () => boolean;
    spinner: boolean;
    save: () => void;
    cancel: () => void;
    validator: SimpleReactValidator;
    assignableRoles: Role[];
    onChangeRole: (role: Role) => void;
}

interface IWrapComponentLabel {
    children: React.ReactNode;
}

const wrapComponentLabel = (props: IWrapComponentLabel) => {
    return (
        <Col xs={12} md={6} xl={6}>
            {props.children}
        </Col>
    );
};

@observer
class CreateOrEditUserView extends React.Component<ICreateOrEditUserViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  user,
                  spinner,
                  cancel,
                  validate,
                  save,
                  validator,
                  assignableRoles,
                  onChangeRole,
                  t
              } = this.props;

        return (
            <div className={``}>
                <form noValidate autoComplete="off">
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setCurrentTab(newValue);
                        }} aria-label="tabs"
                    >
                        <Tab label={t("edit.general")}/>
                    </Tabs>
                    <hr/>
                    <TabPanel value={currentTab} index={0}>
                        <Row>
                            <Col xs={12} md={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label={t("edit.username")}
                                    className={""}
                                    value={user.getUsername()}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        user.setUsername(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.username"), user.getUsername(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={user.getActive()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                user.setActive(event.currentTarget.checked);
                                            }}
                                            value={user.getActive()}
                                            color="primary"
                                        />
                                    }
                                    label={t("edit.active")}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label={t("edit.name")}
                                    className={""}
                                    value={user.getFirstName()}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        user.setFirstName(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.name"), user.getFirstName(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label={t("edit.lastname")}
                                    className={""}
                                    value={user.getLastName()}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        user.setLastName(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.lastname"), user.getLastName(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label={t("edit.email")}
                                    className={""}
                                    value={user.getEmail()}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        user.setEmail(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.email"), user.getEmail(), "required|email")}
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label={t("edit.password")}
                                    className={""}
                                    type="password"
                                    value={user.getPassword()}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        user.setPassword(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                            </Col>
                            <Col xs={12} md={12} xl={12}>
                                <MultiCheckBox<Role>
                                    onChange={onChangeRole}
                                    options={assignableRoles}
                                    toStringItem={(item: Role) => t(`role:${item.getName()}`)}
                                    toStringItemValue={(item: Role) => item.getName()}
                                    isChecked={(item: Role) => {
                                        return user.hasRole(item);
                                    }}
                                    label={t("edit.roles")}
                                    wrapComponentLabel={wrapComponentLabel}
                                    wrapComponentGroup={Row}
                                />
                            </Col>
                        </Row>
                    </TabPanel>
                    <hr/>
                    <GroupButtons
                        action={save}
                        spinner={spinner}
                        validate={validate}
                        cancel={cancel}
                    />
                </form>
            </div>
        );
    }
}

export default withNamespaces("page")(CreateOrEditUserView);
