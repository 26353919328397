import * as React     from "react";
import GoogleMapReact from "google-map-react";
import { Order }      from "../../../Models/Order/Order";
import InjectPrefix   from "../../../Decorators/InjectPrefix";

interface LocationMapViewProps {
    keyGoogleMaps: string;
    order: Order;
    prefix?: string;
}

// tslint:disable-next-line:variable-name
class Pointer extends React.Component<{
    text: string;
    lat: number;
    lng: number;
}, {}> {
    public render(): React.ReactNode {
        const {text} = this.props;

        return (
            <div className="marker"/>
        );
    }
}

@InjectPrefix
class LocationMapView extends React.Component<LocationMapViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {keyGoogleMaps, order} = this.props,
              direction              = order.getDirection(),
              center                 = {
                  lat: parseFloat(direction.getLat()),
                  lng: parseFloat(direction.getLng())
              },
              zoom                   = 18;

        if (!direction.getLat() || !direction.getLng()) return (<React.Fragment/>);

        return (
            <div className={`${this.prefix}-c-maps`}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: keyGoogleMaps}}
                    defaultCenter={center}
                    defaultZoom={zoom}
                >
                    <Pointer
                        lat={parseFloat(direction.getLat())}
                        lng={parseFloat(direction.getLng())}
                        text="Destino"
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default LocationMapView;
