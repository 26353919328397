import BaseStore                               from "./Base/BaseStore";
import { deserialize, serialize }              from "serializr";
import { createFormData }                      from "../Utils/common";
import { Container }                           from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { observable }                          from "mobx";
import { globalPagerOptions, IPaginationData } from "../config/pagination";
import { Product }                             from "../Models/Product/Product";
import { ProductFilter }                       from "../Models/Product/ProductFilter";
import { persist }                             from "mobx-persist";
import { ProductRelationship }                 from "../Models/Product/ProductRelationship";

export class ProductStore extends BaseStore {
    public static readonly NAME_STORE: string = "ProductStore";

    @observable
    private products: Product[] = [];

    @observable
    private productsSearch: Product[] = [];

    @observable
    private product: Product;

    @observable
    private productRelationship: ProductRelationship;

    @persist("object", ProductFilter)
    @observable
    private productFilter: ProductFilter;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getProduct(): Product {
        return this.product;
    }

    public setProduct(value: Product) {
        this.product = value;
    }

    public getProductRelationship(): ProductRelationship {
        return this.productRelationship;
    }

    public setProductRelationship(value: ProductRelationship) {
        this.productRelationship = value;
    }

    public getProductFilter(): ProductFilter {
        return this.productFilter;
    }

    public setProductFilter(value: ProductFilter) {
        this.productFilter = value;
    }

    public getProducts(): Product[] {
        return this.products;
    }

    public setProducts(value: Product[]) {
        this.products = value;

        return this;
    }

    public getProductsSearch(): Product[] {
        return this.productsSearch;
    }

    public setProductsSearch(value: Product[]) {
        this.productsSearch = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = true;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(productFilter?: ProductFilter): Promise<IPaginationData<Product>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateProducts(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE,
            productFilter ? serialize(productFilter) : productFilter
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(Product, item)),
            pages: dataResponse.pages
        } as IPaginationData<Product>;
    }

    public async getById(id: string): Promise<Product> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getProductId(id);

        if (!dataResponse) { // @ts-ignore
            return;
        }

        return deserialize(Product, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deleteProductId(id);

        return success;
    }

    public async save(product: Product): Promise<Product> {
        const data     = serialize(product),
              formData = createFormData(data);

        if (product.getImageSelect())
            formData.append("imageSelect", product.getImageSelect().file);

        const {data: {data: dataResponse}} = await this.getAjaxService().saveProduct(formData);

        return deserialize(Product, dataResponse);
    }

    public async getProductRelationshipByProductId(productId: string): Promise<ProductRelationship | undefined> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getProductRelationshipByProductId(productId);

        if (dataResponse)
            return deserialize(ProductRelationship, dataResponse);

        return;
    }

    public async saveProductRelationshipByProductId(productRelationship: ProductRelationship): Promise<ProductRelationship> {
        const {data: {data: dataResponse}} = await this.getAjaxService().saveProductRelationship(createFormData(serialize(productRelationship)));

        return deserialize(ProductRelationship, dataResponse);
    }
}
