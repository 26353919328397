import * as React                          from "react";
import LoginView                           from "../../Views/Pages/Login/LoginView";
import { ConfigurationStore }              from "../../Store/ConfigurationStore";
import { inject, observer }                from "mobx-react";
import { FormStore }                       from "../../Store/FormStore";
import { Login }                           from "../../Models/Form/Login";
import AuthService                         from "../../Service/AuthService";
import { Container }                       from "typedi";
import { observable }                      from "mobx";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../Routes/routes";
import { UserStore }                       from "../../Store/UserStore";

interface ILoginViewModelProps extends RouteComponentProps<any> {
    ConfigurationStore?: ConfigurationStore;
    FormStore?: FormStore;
    UserStore?: UserStore;
}

@inject(ConfigurationStore.NAME_STORE, FormStore.NAME_STORE, UserStore.NAME_STORE)
@observer
class LoginViewModel extends React.Component<ILoginViewModelProps, {}> {
    private readonly authService: AuthService;

    @observable
    private invalidCredentials: boolean = false;

    get configurationStore(): ConfigurationStore {
        return this.props.ConfigurationStore as ConfigurationStore;
    }

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    protected getInvalidCredentials(): boolean {
        return this.invalidCredentials;
    }

    protected setInvalidCredentials(value: boolean) {
        this.invalidCredentials = value;
    }

    constructor(props: ILoginViewModelProps) {
        super(props);

        if (this.userStore.getUser()) this.redirectToDashboard();

        this.configurationStore
            .getConfiguration()
            .setShowHeader(false)
            .setShowFooter(false);

        this.formStore.setLogin(new Login());

        this.authService = Container.get(AuthService);
    }

    protected onSubmit = async (): Promise<boolean> => {
        this.setInvalidCredentials(false);

        const user = await this.authService.getAccessToken(this.formStore.getLogin());

        if (!user) {
            this.setInvalidCredentials(true);

            return false;
        }

        this.redirectToDashboard();
        return true;
    };

    private redirectToDashboard = () => this.props.history.push(renderRoute(PageNames.homePage));


    public render(): React.ReactNode {
        return (
            <LoginView
                login={this.formStore.getLogin()}
                onSubmit={this.onSubmit}
                invalidCredentials={this.getInvalidCredentials()}
            />
        );
    }
}

export default withRouter(LoginViewModel);
