import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import PromotionView                       from "../../../Views/Pages/Promotion/View/PromotionView";
import { PromotionStore }                  from "../../../Store/PromotionStore";

interface IPromotionViewModelProps extends RouteComponentProps<any> {
    PromotionStore?: PromotionStore;
}

@inject(PromotionStore.NAME_STORE)
@observer
class PromotionViewModel extends React.Component<IPromotionViewModelProps, {}> {
    get promotionStore(): PromotionStore {
        return this.props.PromotionStore as PromotionStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.promotionStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.promotionStore.getPaginate();

        this.promotionStore
            .setPages(response.pages)
            .setPromotions(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <PromotionView
                promotions={this.promotionStore.getPromotions()}
                spinner={this.promotionStore.getSpinner()}
                add={() => history.push(renderRoute(PageNames.promotionAddPage))}
                getPage={this.getPage}
                pages={this.promotionStore.getPages()}
                currentPage={this.promotionStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(PromotionViewModel);
