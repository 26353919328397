import * as React       from "react";
import {
    TableCell,
    TableRow
}                       from "@material-ui/core";
import formatDate       from "../../../config/formatDate";
import Actions          from "../Table/Actions";
import moment           from "moment";
import { ColorPalette } from "../../../Models/Color/ColorPalette";

interface ITableRowGalleryViewProps {
    colorPalette: ColorPalette;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowColorPaletteView extends React.Component<ITableRowGalleryViewProps, {}> {

    public render(): React.ReactNode {
        const {
                  colorPalette,
                  onEdit,
                  onDelete,
              } = this.props;

        return (
            <TableRow>
                <TableCell>{colorPalette.getName()}</TableCell>
                <TableCell>{moment(colorPalette.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(colorPalette.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowColorPaletteView;
