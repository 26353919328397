import * as React             from "react";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Chip,
}                             from "@material-ui/core";
import {
    withNamespaces,
    WithNamespaces,
}                             from "react-i18next";
import SearchIcon             from "@material-ui/icons/Search";
import InjectPrefix           from "../../../../Decorators/InjectPrefix";
import Pagination             from "../../../Components/Pagination/Pagination";
import { Order }              from "../../../../Models/Order/Order";
import TableRowOrderViewModel from "../../../../ViewModel/Order/View/TableRowOrderViewModel";
import { OrderFilter }        from "../../../../Models/Order/OrderFilter";
import { OrderStatus }        from "../../../../config/orderStatus";
import { observer }           from "mobx-react";
import { Col, Row }           from "reactstrap";
import { MethodsPay }         from "../../../../config/MethodsPay";
import { MethodTransport }    from "../../../../Models/MethodTransport/MethodTransport";
import OrderFilterView        from "./OrderFilterView";

interface IOrderViewProps extends WithNamespaces {
    orders: Order[];
    spinner: boolean;
    prefix?: string;
    pages: number;
    currentPage: number;
    getPage: (page: number) => void;
    orderFilter: OrderFilter;
    methodsTransport: MethodTransport[];
    getFilter: () => void;
}

@InjectPrefix
@observer
class OrderView extends React.Component<IOrderViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderBody = (): React.ReactNode => {
        const {orders} = this.props;

        return orders.map((order: Order, key: number) => (
            <TableRowOrderViewModel
                order={order}
                key={key}
            />
        ));
    };

    protected renderPagination = (): React.ReactNode => {
        const {
                  spinner,
                  pages,
                  currentPage,
                  getPage,
              } = this.props;

        return <Pagination
            className="mt-4"
            pages={pages}
            currentPage={currentPage}
            getPage={!spinner ? getPage : () => {}}
        />;
    };

    public render(): React.ReactNode {
        const {
                  spinner,
                  t,
                  orderFilter,
                  methodsTransport,
                  getFilter,
              }            = this.props,
              propsButtons = {
                  color  : "primary" as "primary",
                  // onClick: add,
                  size   : "small" as "small",
                  spinner: false,
                  variant: "contained" as "contained"
              };

        return (
            <>
                <h3>
                    {t("order.order")}
                </h3>
                {spinner ? <React.Fragment/> : <OrderFilterView
                    orderFilter={orderFilter}
                    methodsTransport={methodsTransport}
                    spinner={spinner}
                    getFilter={getFilter}
                />}
                <Table className={`${this.prefix}-table-info ${this.prefix}-table-order`}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("edit.identifier")}</TableCell>
                            <TableCell>{t("edit.name-client")}</TableCell>
                            <TableCell>{t("edit.status")}</TableCell>
                            <TableCell>{t("edit.createdAt")}</TableCell>
                            <TableCell>{t("edit.updatedAt")}</TableCell>
                            <TableCell align="right">{t("edit.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderBody()}
                    </TableBody>
                </Table>
                {this.renderPagination()}
                {
                    spinner
                    ? <LinearProgress className="mt-4 mb-4"/>
                    : <React.Fragment/>
                }
            </>
        );
    }
}

export default withNamespaces("page")(OrderView);
