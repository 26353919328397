import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import Error                              from "../Components/Error/Error";

interface IErrorProps extends WithNamespaces {
    backgroundImage: string;
    title: string;
    content: string;
    buttonTitle: string;
    buttonLink: string;
}

class PageView extends React.Component<IErrorProps, any> {
    public render(): React.ReactNode {
        return (
            <Error
                {...this.props}
            />
        );
    }
}

export default withNamespaces("pages")(PageView);
