import * as React                                from "react";
import Tabs                                      from "@material-ui/core/Tabs";
import Tab                                       from "@material-ui/core/Tab";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Row, Col }                              from "reactstrap";
import SimpleReactValidator                      from "simple-react-validator";
import { TabPanel }                              from "../../../Components/TabPanel/TabPanel";
import { GroupButtons }                          from "../../../Components/GroupButtons/GroupButtons";
import GalleryImagesViewModel
                                                 from "../../../../ViewModel/Gallery/CreateOrEdit/GalleryImagesViewModel";
import { Promotion }                             from "../../../../Models/Promotion/Promotion";
import DateFnsUtils                              from "@date-io/moment";
import moment                                    from "moment";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    MaterialUiPickersDate,
}                                                from "@material-ui/pickers";
import formatDate                                from "../../../../config/formatDate";
import { withNamespaces, WithNamespaces }        from "react-i18next";
import InformationViewModel
                                                 from "../../../../ViewModel/Promotion/CreateOrEdit/InformationViewModel";
import { observer }                              from "mobx-react";
import WysiwygViewModel                          from "../../../../ViewModel/WYSIWYG/WysiwygViewModel";
import CreateUpdatePageSeo                       from "../../../Components/PageSeo/CreateUpdatePageSeo";
import { validateNumber }                        from "../../../../Utils/common";

interface INewEditGalleryViewProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    currentTabSeo: number;
    setCurrentTabSeo: (tab: number) => void;
    promotion: Promotion;
    validate: () => boolean;
    spinner: boolean;
    save: () => void;
    cancel: () => void;
    validator: SimpleReactValidator;
}

@observer
class CreateOrEditPromotionView extends React.Component<INewEditGalleryViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  currentTabSeo,
                  setCurrentTabSeo,
                  promotion,
                  spinner,
                  cancel,
                  validate,
                  save,
                  validator,
                  t
              }       = this.props,
              gallery = promotion.getGallery();

        return (
            <div className={``}>
                <form noValidate autoComplete="off">
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setCurrentTab(newValue);
                        }} aria-label="tabs"
                    >
                        <Tab label={t("edit.general")}/>
                        <Tab label={t("edit.images")}/>
                        <Tab label={t("edit.information")}/>
                        <Tab label={t("SEO")}/>
                    </Tabs>
                    <hr/>
                    <TabPanel value={currentTab} index={0}>
                        <Row>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.name")}
                                    className={""}
                                    value={promotion.getName() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        promotion.setName(evt.currentTarget.value);
                                        gallery.setName(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.name"), gallery.getName(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={promotion.getActive()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                promotion.setActive(event.currentTarget.checked);
                                            }}
                                            value={promotion.getActive()}
                                            color="primary"
                                        />
                                    }
                                    label={t("edit.active")}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format={formatDate.calendarDate}
                                        margin="normal"
                                        id="date-picker-inline"
                                        label={t("edit.dateInit")}
                                        value={moment(promotion.getDateInit())}
                                        onChange={(date: MaterialUiPickersDate) => {
                                            promotion.setDateInit(moment(date as unknown as Date).toDate());
                                            this.forceUpdate();
                                        }}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                {validator.message(t("edit.dateInit"), promotion.getDateInit(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format={formatDate.calendarDate}
                                        margin="normal"
                                        id="date-picker-inline"
                                        label={t("edit.dateEnd")}
                                        value={moment(promotion.getDateEnd())}
                                        onChange={(date: MaterialUiPickersDate) => {
                                            promotion.setDateEnd(moment(date as unknown as Date).toDate());
                                            this.forceUpdate();
                                        }}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                {validator.message(t("edit.dateEnd"), promotion.getDateEnd(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.actualPrice")}
                                    className={""}
                                    value={validateNumber(promotion.getActualPrice())}
                                    type="number"
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        promotion.setActualPrice(parseInt(evt.currentTarget.value, 10));
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.actualPrice"), gallery.getName(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.lastPrice")}
                                    className={""}
                                    value={validateNumber(promotion.getLastPrice())}
                                    type="number"
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        promotion.setLastPrice(parseInt(evt.currentTarget.value, 10));
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.lastPrice"), gallery.getName(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.shortDescription")}
                                    className={""}
                                    value={promotion.getShortDescription() || ""}
                                    multiline
                                    rowsMax="4"
                                    rows="4"
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        promotion.setShortDescription(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.shortDescription"), promotion.getShortDescription(), "required")}
                            </Col>
                            <Col xs={12} md={12} xl={12}>
                                <WysiwygViewModel
                                    onChange={(item: string) => promotion.setDescription(item)}
                                    value={promotion.getDescription()}
                                />
                                {validator.message(t("edit.description"), gallery.getName(), "required")}
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <GalleryImagesViewModel
                            gallery={gallery}
                            validator={validator}
                        />
                    </TabPanel>
                    <TabPanel value={currentTab} index={2}>
                        <InformationViewModel
                            promotion={promotion}
                            validator={validator}
                        />
                    </TabPanel>
                    <TabPanel value={currentTab} index={3}>
                        <CreateUpdatePageSeo
                            currentTab={currentTabSeo}
                            setCurrentTab={setCurrentTabSeo}
                            validator={validator}
                            seoData={promotion.getSeoData()}
                        />
                    </TabPanel>
                    <hr/>
                    <GroupButtons
                        action={save}
                        spinner={spinner}
                        validate={validate}
                        cancel={cancel}
                    />
                </form>
            </div>
        );
    }
}

export default withNamespaces("page")(CreateOrEditPromotionView);
