import { withNamespaces, WithNamespaces }                                  from "react-i18next";
import InjectPrefix                                                        from "../../../../Decorators/InjectPrefix";
import * as React                                                          from "react";
import { Button }                                                          from "../../../Components/Button/Button";
import { Box, Card, CardActions, CardContent, LinearProgress, Typography } from "@material-ui/core";
import { CronJobStatus }                                                   from "../../../../Models/CronJobStatus/CronJobStatus";
import { CronJobList }                                                     from "../../../../config/cronJobList";
import { LinearProgressProps }                                             from "@material-ui/core/LinearProgress";
import { CronJobStatus as Status }                                         from "../../../../config/cronJobStatus";

interface IActionItemViewProps extends WithNamespaces {
    prefix?: string;
    getActionName: string;
    cronJobStatus: CronJobStatus | undefined;
    spinner: boolean;
    actionName: CronJobList;
    executeAction: () => void;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

@InjectPrefix
class ActionItemView extends React.Component<IActionItemViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderProgress = () => {
        const {cronJobStatus} = this.props;

        if (this.isDoneProcess() || !cronJobStatus) return (<React.Fragment/>);

        return (
            <LinearProgressWithLabel value={cronJobStatus.getData().percentage}/>
        );
    };

    protected isDoneProcess = () => {
        const {cronJobStatus} = this.props;
        if (cronJobStatus) {
            const {dateInit, dateEnd} = cronJobStatus.getData();
        }

        return !cronJobStatus || cronJobStatus.getStatus() === Status.DONE;
    };

    public render(): React.ReactNode {
        const {
                  t,
                  actionName,
                  getActionName,
                  executeAction,
                  spinner
              } = this.props;

        return (
            <Card>
                <CardContent>
                    <Typography variant="h5" className="mb-2 d-block">
                        {t(getActionName)}
                    </Typography>
                    <Typography variant="body2" component={"p" as unknown as React.FunctionComponent}>
                        {t(`description.${actionName}`)}
                        <br/>
                        {this.renderProgress()}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        variant={"outlined"}
                        color={"secondary"}
                        colorSpinner={"secondary"}
                        size={"medium"}
                        text={t("global:execute")}
                        onClick={executeAction}
                        spinner={!this.isDoneProcess()}
                    />
                </CardActions>
            </Card>
        );
    }
}

export default withNamespaces("actions")(ActionItemView);
