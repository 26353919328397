import * as React                         from "react";
import SimpleReactValidator               from "simple-react-validator";
import { GroupButtons }                   from "../../../Components/GroupButtons/GroupButtons";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { observer }                       from "mobx-react";
import { Page }                           from "../../../../Models/Page/Page";
import InjectPrefix from "../../../../Decorators/InjectPrefix";
import CreateUpdatePageSeo from "../../../Components/PageSeo/CreateUpdatePageSeo";

// tslint:disable-next-line:variable-name
const TagInput = require("reactjs-tag-input").TagInput;

interface IPageViewProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    page: Page;
    validate: () => boolean;
    spinner: boolean;
    save: () => void;
    cancel: () => void;
    prefix?: string;
    validator: SimpleReactValidator;
}

@InjectPrefix
@observer
class CreateOrEditPageView extends React.Component<IPageViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  page,
                  spinner,
                  cancel,
                  validate,
                  save,
                  validator,
                  t
              }       = this.props;

        return (
            <div className={``}>
                <form noValidate autoComplete="off">
                    <CreateUpdatePageSeo
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        validator={validator}
                        page={page}
                    />
                    <hr/>
                    <GroupButtons
                        action={save}
                        spinner={spinner}
                        validate={validate}
                        cancel={cancel}
                    />
                </form>
            </div>
        );
    }
}

export default withNamespaces("page")(CreateOrEditPageView);
