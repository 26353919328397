import * as React                 from "react";
import { observable }             from "mobx";
import { inject, observer }       from "mobx-react";
import { withNamespaces }         from "react-i18next";
import { withRouter }             from "react-router";
import { LinearProgress }         from "@material-ui/core";
import { Gallery }                from "../../../Models/Gallery/Gallery";
import { GalleryItem }            from "../../../Models/Gallery/GalleryItem";
import { FormStore }              from "../../../Store/FormStore";
import { PageNames, renderRoute } from "../../../Routes/routes";
import CreateOrEditTutorialView
                                  from "../../../Views/Pages/Tutorial/CreateOrEdit/CreateOrEditTutorialView";
import { TutorialStore }          from "../../../Store/TutorialStore";
import { Tutorial }               from "../../../Models/Tutorial/Tutorial";
import IBaseProps                 from "../../Props/IBaseProps";
import { SeoData }                from "../../../Models/Page/SeoData";
import { PageStore }              from "../../../Store/PageStore";

interface INewEditTutorialViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    TutorialStore?: TutorialStore;
    PageStore?: PageStore;
}

@inject(FormStore.NAME_STORE, TutorialStore.NAME_STORE, PageStore.NAME_STORE)
@observer
class CreateOrEditTutorialViewModel extends React.Component<INewEditTutorialViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    constructor(props: INewEditTutorialViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }

        const gallery  = new Gallery(),
              tutorial = new Tutorial();

        gallery.addItem(new GalleryItem());
        tutorial.setGallery(gallery);

        this.tutorialStore.setTutorial(tutorial);
    }

    get pagesStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    public componentDidMount = async (): Promise<void> => {
        const {id}           = this.props.match.params,
              pageSeoDefault = await this.pagesStore.getPageDefault(),
              seoDataDefault = (pageSeoDefault ? pageSeoDefault.getSeoData() : new SeoData());

        if (!id) {
            this.tutorialStore.getTutorial().setSeoData(seoDataDefault);

            return;
        }

        const tutorial = await this.tutorialStore.getById(id);

        if (!tutorial.getGallery()) {
            const gallery = new Gallery();

            gallery.addItem(new GalleryItem());
            tutorial.setGallery(gallery);
        }

        if (!tutorial.getSeoData() || !tutorial.getSeoData().getDescription()) tutorial.setSeoData(seoDataDefault);

        this.tutorialStore.setTutorial(tutorial);


        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get tutorialStore(): TutorialStore {
        return this.props.TutorialStore as TutorialStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const tutorial  = await this.tutorialStore.save(this.tutorialStore.getTutorial()),
              {history} = this.props;

        history.push(renderRoute(PageNames.tutorialPage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.tutorialPage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    public render(): React.ReactNode {
        if (this.loading)
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (this.tutorialStore.getTutorial())
            return (
                <CreateOrEditTutorialView
                    currentTab={this.getCurrentTab()}
                    setCurrentTab={this.setCurrentTab}
                    currentTabSeo={this.formStore.getCurrentTabSeo()}
                    setCurrentTabSeo={this.formStore.setCurrentTabSeo}
                    tutorial={this.tutorialStore.getTutorial()}
                    validate={this.validate}
                    spinner={this.getSpinner()}
                    save={this.save}
                    validator={this.formStore.getValidator()}
                    cancel={this.cancel}
                />
            );

        return (<React.Fragment/>);
    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditTutorialViewModel));
