import * as React                 from "react";
import { observable }             from "mobx";
import { inject, observer }       from "mobx-react";
import { withNamespaces }         from "react-i18next";
import { withRouter }             from "react-router";
import { LinearProgress }         from "@material-ui/core";
import { FormStore }              from "../../../Store/FormStore";
import { PageNames, renderRoute } from "../../../Routes/routes";
import { CategoryStore }          from "../../../Store/CategoryStore";
import { Category }               from "../../../Models/Product/Category";
import IBaseProps                 from "../../Props/IBaseProps";
import { PageStore }              from "../../../Store/PageStore";
import CreateOrEditCategoryView   from "../../../Views/Pages/Category/CreateOrEdit/CreateOrEditCategoryView";

interface INewEditCategoryViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    CategoryStore?: CategoryStore;
    PageStore?: PageStore;
}

@inject(FormStore.NAME_STORE, CategoryStore.NAME_STORE)
@observer
class CreateOrEditCategoryViewModel extends React.Component<INewEditCategoryViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    @observable
    private categories: Category[] = [];

    constructor(props: INewEditCategoryViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }
    }

    public componentDidMount = async (): Promise<void> => {
        const {id} = this.props.match.params;
        this.setCategories(await this.categoryStore.getAllCategories());

        if (!id) {
            const category = new Category();

            this.categoryStore.setCategory(category);

            return;
        }

        const category = await this.categoryStore.getById(id);

        this.categoryStore.setCategory(category);
        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get categoryStore(): CategoryStore {
        return this.props.CategoryStore as CategoryStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected getCategories = (): Category[] => {
        return this.categories;
    };

    protected setCategories = (value: Category[]) => {
        this.categories = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const category  = await this.categoryStore.save(this.categoryStore.getCategory()),
              {history} = this.props;

        history.push(renderRoute(PageNames.categoryPage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.categoryPage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    public render(): React.ReactNode {
        if (this.loading || !this.categoryStore.getCategory())
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (this.categoryStore.getCategory())
            return (
                <CreateOrEditCategoryView
                    currentTab={this.getCurrentTab()}
                    setCurrentTab={this.setCurrentTab}
                    category={this.categoryStore.getCategory()}
                    validate={this.validate}
                    spinner={this.getSpinner()}
                    save={this.save}
                    validator={this.formStore.getValidator()}
                    cancel={this.cancel}
                    categories={this.getCategories()}
                />
            );

        return (<React.Fragment/>);
    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditCategoryViewModel));
