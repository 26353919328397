import * as React         from "react";
import InjectPrefix       from "../../../Decorators/InjectPrefix";
import { Paper }          from "@material-ui/core";
import { Login }          from "../../../Models/Form/Login";
import { observer }       from "mobx-react";
import LoginFormViewModel from "../../../ViewModel/Form/LoginFormViewModel";

interface ILoginViewProps {
    prefix?: string;
    login: Login;
    onSubmit: () => Promise<boolean>;
    invalidCredentials: boolean;
}

@InjectPrefix
@observer
class LoginView extends React.Component<ILoginViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  login,
                  onSubmit,
                  invalidCredentials
              } = this.props;

        return (
            <div className={`${this.prefix}-p-login`}>
                <Paper className="form-login">
                    <LoginFormViewModel
                        login={login}
                        onSubmit={onSubmit}
                        invalidCredentials={invalidCredentials}
                    />
                </Paper>
            </div>
        );
    }
}

export default LoginView;
