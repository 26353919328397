const variables: any = require("../../styles/core/variables.scss");

const scssConfiguration = {
    prefix: variables.prefix,
    primaryColor: variables.primaryColor,
    quaternaryColor: variables.quaternaryColor,
    secondaryColor: variables.secondaryColor,
    tertiaryColor: variables.tertiaryColor
};

export default scssConfiguration;
