import {
    serializable,
    raw
} from "serializr";

export class Result {
    @serializable
    // tslint:disable-next-line:variable-name
    private token: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private time_local_tran: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private systems_trace_audit_number: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private success: boolean;

    @serializable
    // tslint:disable-next-line:variable-name
    private retrieval_ref_num: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private resp_code: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private reserved_private4: string;

    @serializable(raw())
    // tslint:disable-next-line:variable-name
    private proc_code?: any;

    @serializable(raw())
    // tslint:disable-next-line:variable-name
    private network_international_id?: any;

    @serializable
    // tslint:disable-next-line:variable-name
    private mti: string;

    @serializable
    // tslint:disable-next-line:variable-name
    private merchant_id: number;
    @serializable
    // tslint:disable-next-line:variable-name
    private date_local_tran: string;

    @serializable(raw())
    // tslint:disable-next-line:variable-name
    private card_acceptor_terminal_id?: any;

    @serializable
    // tslint:disable-next-line:variable-name
    private authorization_id_resp: string;

}