import * as React                                           from "react";
import { observer }                                         from "mobx-react";
import moment                                               from "moment";
import {
    withNamespaces,
    WithNamespaces,
}                                                           from "react-i18next";
import { Order }                                            from "../../../../Models/Order/Order";
import TableProducts                                        from "../../../Components/Table/TableProducts";
import TableProductsFooter                                  from "../../../Components/Table/TableProductsFooter";
import InjectPrefix                                         from "../../../../Decorators/InjectPrefix";
import Status                                               from "../../../Components/Order/Status";
import formatDate                                           from "../../../../config/formatDate";
import DetailsOrder                                         from "../../../Components/Order/DetailsOrder";
import LocationMapViewModel                                 from "../../../../ViewModel/Maps/LocationMapViewModel";
import { OrderStatus }                                      from "../../../../config/orderStatus";
import { Button }                                           from "../../../Components/Button/Button";
import { Col, Row }                                         from "reactstrap";
import { StatusHistory }                                    from "../../../../Models/Order/StatusHistory";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import SendEmailViewModel                                   from "../../../../ViewModel/Order/SendEmailViewModel";

interface IOrderDetailViewProps extends WithNamespaces {
    order: Order;
    spinner: boolean;
    changeStatus: (status: OrderStatus) => void;
    prefix?: string;
}

@InjectPrefix
@observer
class OrderDetailView extends React.Component<IOrderDetailViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderChangeStatus = () => {
        const {
                  t,
                  spinner,
                  changeStatus,
                  order
              }                      = this.props;
        let buttons: React.ReactNode = Object.values(OrderStatus).map(item => {
            if (!order.getMethodTransport().getPickUp() && item === OrderStatus.readyToPickup)
                return <React.Fragment/>;

            if (order.getMethodTransport().getPickUp() && item === OrderStatus.shipped)
                return <React.Fragment/>;

            return (
                <Button
                    variant={"outlined"}
                    color={"primary"}
                    colorSpinner={"primary"}
                    size={"medium"}
                    text={t(`order:${item}`)}
                    onClick={() => changeStatus(item)}
                    spinner={spinner}
                    key={item}
                />
            );
        });

        if (spinner) {
            buttons = (
                <Button
                    variant={"outlined"}
                    color={"primary"}
                    colorSpinner={"primary"}
                    size={"medium"}
                    text={""}
                    onClick={() => false}
                    spinner={true}
                />
            );
        }

        return (
            <div className={`button-wraps`}>
                {buttons}
            </div>
        );
    };

    public render(): React.ReactNode {
        const {
                  order,
                  t
              }         = this.props,
              createAt  = moment(order.getCreatedAt()),
              direction = order.getDirection();

        return (
            <div className={`${this.prefix}-p-order-detail`}>
                <Status order={order}/>
                <hr/>
                <h4 className="title">
                    {t("order.order")} {order.getIdentifier()} | <span>
                    {t("order.order-date")} {createAt.format(formatDate.fullDate)}
                    </span>
                </h4>
                <DetailsOrder order={order}/>
                <SendEmailViewModel order={order}/>
                {this.renderChangeStatus()}
                <TableProducts
                    productItemCars={order.getProductStore()}
                    order={order}
                    hideTotal
                    notEdit
                    children={
                        <TableProductsFooter
                            hideTransport={order.getMethodTransport().getPickUp()}
                            shipping={order.getCalculateShipping() ? order.getCalculateShipping().getPrice() : 0}
                            subTotal={order.getSubTotal()}
                            total={order.getTotal()}
                        />
                    }
                />
                <Row>
                    {
                        direction
                        ? <Col xs={6}>
                            <LocationMapViewModel order={order}/>
                        </Col>
                        : <React.Fragment/>
                    }
                    <Col xs={6}>
                        <h5>Historico de estados</h5>
                        <Table>
                            <TableHead className="table-head">
                                <TableRow>
                                    <TableCell>
                                        {t("edit.status")}
                                    </TableCell>
                                    <TableCell>
                                        {t("edit.createdAt")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order.getStoryOrder().map((status: StatusHistory, key: number) => {
                                    return (
                                        <TableRow className="row-subtotal" key={`${status.getStatus()}-${key}`}>
                                            <TableCell>
                                                {t(`order:${status.getStatus()}`)}
                                            </TableCell>
                                            <TableCell>
                                                {moment(status.getCreatedAt()).format(formatDate.fullDate)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default withNamespaces("page")(OrderDetailView);
