import * as React              from "react";
import { TableCell, TableRow } from "@material-ui/core";
import formatDate              from "../../../config/formatDate";
import Actions                 from "../Table/Actions";
import moment                  from "moment";
import { Promotion }           from "../../../Models/Promotion/Promotion";

interface ITableRowGalleryViewProps {
    promotion: Promotion;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowPromotionView extends React.Component<ITableRowGalleryViewProps, {}> {

    public render(): React.ReactNode {
        const {
                  promotion,
                  onEdit,
                  onDelete,
              }    = this.props,
              item = promotion.getGallery().getFirstItem();

        return (
            <TableRow>
                <TableCell>{promotion.getName()}</TableCell>
                <TableCell>
                    {
                        item ? <img src={item.getImageFullPath()} alt="" className="img-fluid"/> : ""
                    }
                </TableCell>
                <TableCell>{moment(promotion.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(promotion.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowPromotionView;
