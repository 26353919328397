import * as React              from "react";
import {
    TextField,
    FormControl,
    Tab,
    Tabs,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox
}                              from "@material-ui/core";
import {
    Row,
    Col
}                              from "reactstrap";
import SimpleReactValidator    from "simple-react-validator";
import { TabPanel }            from "../../../Components/TabPanel/TabPanel";
import { GroupButtons }        from "../../../Components/GroupButtons/GroupButtons";
import {
    withNamespaces,
    WithNamespaces
}                              from "react-i18next";
import { observer }            from "mobx-react";
import { Product }             from "../../../../Models/Product/Product";
import InjectPrefix            from "../../../../Decorators/InjectPrefix";
import { ProductRelationship } from "../../../../Models/Product/ProductRelationship";
import ProductRelationshipViewModel
                               from "../../../../ViewModel/Product/CreateOrEdit/ProductRelationshipViewModel";
import { ColorPalette }        from "../../../../Models/Color/ColorPalette";

interface ICreateOrEditProductViewProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    product: Product;
    validate: () => boolean;
    spinner: boolean;
    save: () => void;
    cancel: () => void;
    validator: SimpleReactValidator;
    productRelationship: ProductRelationship;
    colorPalettes: ColorPalette[]
    prefix?: string;
}

@InjectPrefix
@observer
class CreateOrEditProductView extends React.Component<ICreateOrEditProductViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected showImage = (product: Product): React.ReactNode => {
        const imageSelect = product.getImageSelect(),
              fullPath    = product.getImageFullPath();

        if (!imageSelect && !fullPath) return <React.Fragment/>;

        return (<img
            src={imageSelect ? imageSelect.base64 : fullPath}
            className="img-fluid product-image"
        />);
    };

    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  product,
                  spinner,
                  cancel,
                  validate,
                  save,
                  validator,
                  t,
                  productRelationship,
                  colorPalettes
              } = this.props;

        return (
            <div className={`${this.prefix}-p-product`}>
                <form noValidate autoComplete="off">
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setCurrentTab(newValue);
                        }} aria-label="tabs"
                    >
                        <Tab label={t("edit.general")}/>
                        <Tab label={t("edit.products-relationship")}/>
                    </Tabs>
                    <hr/>
                    <TabPanel value={currentTab} index={0}>
                        <Row>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.name")}
                                    className={""}
                                    value={product.getName() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        product.setName(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                    inputProps={{readOnly: true}}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.code")}
                                    className={""}
                                    value={product.getCode() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        product.setCode(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                    inputProps={{readOnly: true}}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.order")}
                                    className={""}
                                    value={product.getOrder() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        product.setOrder(parseFloat(evt.currentTarget.value));
                                    }}
                                    margin="normal"
                                    type="number"
                                />
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={product.getInStock()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                product.setInStock(event.currentTarget.checked);
                                            }}
                                            value={product.getInStock()}
                                            color="primary"
                                        />
                                    }
                                    label={t("edit.inStock")}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.description")}
                                    className={""}
                                    value={product.getDescription() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        product.setDescription(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                    multiline
                                    inputProps={{readOnly: true}}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.price")}
                                    className={""}
                                    value={product.getPrice() || ""}
                                    type="number"
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        product.setPrice(parseInt(evt.currentTarget.value, 10));
                                    }}
                                    margin="normal"
                                    inputProps={{readOnly: true}}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.brand")}
                                    className={""}
                                    value={product.getBrand() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        product.setBrand(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                    inputProps={{readOnly: true}}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <TextField
                                    fullWidth
                                    label={t("edit.subcategory")}
                                    className={""}
                                    value={product.getSubCategory() ? product.getSubCategory().getName() : ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    }}
                                    margin="normal"
                                    inputProps={{readOnly: true}}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <InputLabel id="palette-color-label">Paleta de Color</InputLabel>
                                <Select
                                    // @ts-ignore
                                    labelId="palette-color-label"
                                    variant="outlined"
                                    fullWidth
                                    value={product.getColorPaletteId() || ""}
                                    onChange={(event: React.ChangeEvent<{ name?: string; value: string }>) => {
                                        product.setColorPaletteId(event.target.value);
                                    }}
                                    inputProps={{
                                        id  : "name-color-palette",
                                        name: "color-palette",
                                    }}
                                >
                                    <MenuItem value={""}>
                                        Ninguno
                                    </MenuItem>
                                    {
                                        colorPalettes.map((item: ColorPalette) => {
                                            return (
                                                <MenuItem value={item.getId()} key={item.getId()}>
                                                    {item.getName()}
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <div>
                                    {this.showImage(product)}
                                </div>
                            </Col>

                        </Row>
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <Row>
                            <Col xs={12}>
                                <ProductRelationshipViewModel
                                    productRelationship={productRelationship}
                                    product={product}
                                />
                            </Col>
                        </Row>
                    </TabPanel>
                    <hr/>
                    <GroupButtons
                        action={save}
                        spinner={spinner}
                        validate={validate}
                        cancel={cancel}
                    />
                </form>
            </div>
        );
    }
}

export default withNamespaces("page")(CreateOrEditProductView);
