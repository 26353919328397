import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import InjectPrefix         from "../../../Decorators/InjectPrefix";
import { Gallery }          from "../../../Models/Gallery/Gallery";
import { GalleryItem }      from "../../../Models/Gallery/GalleryItem";
import { Button }           from "../Button/Button";
import { observer }         from "mobx-react";
import DraggableList        from "react-draggable-list";
import RowGallery           from "./RowGallery";
import SimpleReactValidator from "simple-react-validator";

interface IGalleryImagesViewProps extends WithNamespaces {
    prefix?: string;
    gallery: Gallery;
    addItem: (ele: React.Component) => void;
    removeItem: (item: GalleryItem, ele: React.Component) => void;
    validator: SimpleReactValidator;
}

@InjectPrefix
@observer
class GalleryImagesView extends React.Component<IGalleryImagesViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected removeItem = (item: GalleryItem) => {
        const {removeItem} = this.props;

        removeItem(item, this);
    };

    protected renderProvided = (gallery: Gallery, validator: SimpleReactValidator): React.ReactElement<HTMLElement> => {
        return (
            <DraggableList
                itemKey={(item: GalleryItem) => item.getKey()}
                template={RowGallery as (new (props: any, context?: any) => React.Component)}
                list={gallery.getItems()}
                onMoveEnd={items => gallery.setItems(items as GalleryItem[])}
                commonProps={{
                    removeItem: this.removeItem,
                    validator,
                }}
            />
        );
    };

    public render(): React.ReactNode {
        const {
                  addItem,
                  gallery,
                  validator
              } = this.props;

        return (
            <div className={`${this.prefix}-gallery-image`}>
                {this.renderProvided(gallery, validator)}
                <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"medium"}
                    text={"Add"}
                    onClick={() => addItem(this)}
                    spinner={false}
                />
            </div>
        );
    }
}

export default withNamespaces("page")(GalleryImagesView);
