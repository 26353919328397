import * as React           from "react";
import { Gallery }          from "../../../Models/Gallery/Gallery";
import GalleryImagesView    from "../../../Views/Components/Gallery/GalleryImagesView";
import { observer }         from "mobx-react";
import { GalleryItem }      from "../../../Models/Gallery/GalleryItem";
import SimpleReactValidator from "simple-react-validator";

interface IGalleryImagesViewModelProps {
    gallery: Gallery;
    validator: SimpleReactValidator;
}

@observer
class GalleryImagesViewModel extends React.Component<IGalleryImagesViewModelProps, {}> {

    protected addItem = (ele: React.Component) => {
        const {gallery} = this.props;

        gallery.addItem(new GalleryItem());
        ele.forceUpdate();
    };

    protected removeItem = (item: GalleryItem, ele: React.Component) => {
        const {gallery} = this.props;

        gallery.removeItem(item);
        ele.forceUpdate();
    };

    public render(): React.ReactNode {
        const {gallery, validator} = this.props;

        return (
            <GalleryImagesView
                gallery={gallery}
                addItem={this.addItem}
                removeItem={this.removeItem}
                validator={validator}
            />
        );
    }
}

export default GalleryImagesViewModel;
