import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import TutorialView                       from "../../../Views/Pages/Tutorial/View/TutorialView";
import { TutorialStore }                  from "../../../Store/TutorialStore";

interface ITutorialViewModelProps extends RouteComponentProps<any> {
    TutorialStore?: TutorialStore;
}

@inject(TutorialStore.NAME_STORE)
@observer
class TutorialViewModel extends React.Component<ITutorialViewModelProps, {}> {
    get TutorialStore(): TutorialStore {
        return this.props.TutorialStore as TutorialStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.TutorialStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.TutorialStore.getPaginate();

        this.TutorialStore
            .setPages(response.pages)
            .setTutorials(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <TutorialView
                tutorials={this.TutorialStore.getTutorials()}
                spinner={this.TutorialStore.getSpinner()}
                add={() => history.push(renderRoute(PageNames.tutorialAddPage))}
                getPage={this.getPage}
                pages={this.TutorialStore.getPages()}
                currentPage={this.TutorialStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(TutorialViewModel);
