import { ApplicationStore } from "../ApplicationStore";

export default abstract class BaseStore {
    public static readonly NAME_STORE: string;

    protected needPersistData: boolean;
    private _applicationStore: ApplicationStore;

    get applicationStore(): ApplicationStore {
        return this._applicationStore;
    }

    set applicationStore(value: ApplicationStore) {
        this._applicationStore = value;
    }

    protected init() {}

    public constructor(applicationStore: ApplicationStore) {
        this.applicationStore = applicationStore;
        this.init();

        if (typeof this.needPersistData === "undefined")
            throw `The storage: ${this.constructor.name} need set property this.needPersistData to false / true`;
    }

    public getNeedPersistData(): boolean {
        return this.needPersistData;
    }
}
