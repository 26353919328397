import BaseStore                               from "./Base/BaseStore";
import { deserialize, serialize }              from "serializr";
import { createFormData }                      from "../Utils/common";
import { Container }                           from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { GalleryItem }                         from "../Models/Gallery/GalleryItem";
import { observable }                          from "mobx";
import { globalPagerOptions, IPaginationData } from "../config/pagination";
import { Tutorial }                            from "../Models/Tutorial/Tutorial";

export class TutorialStore extends BaseStore {
    public static readonly NAME_STORE: string = "TutorialStore";

    @observable
    private tutorials: Tutorial[] = [];

    @observable
    private tutorial: Tutorial;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getTutorial(): Tutorial {
        return this.tutorial;
    }

    public setTutorial(value: Tutorial): this {
        this.tutorial = value;

        return this;
    }

    public getTutorials(): Tutorial[] {
        return this.tutorials;
    }

    public setTutorials(value: Tutorial[]): this {
        this.tutorials = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<Tutorial>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateTutorials(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(Tutorial, item)),
            pages: dataResponse.pages
        } as IPaginationData<Tutorial>;
    }

    public async getById(id: string): Promise<Tutorial> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getTutorialId(id);

        return deserialize(Tutorial, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deleteTutorialId(id);

        return success;
    }

    public async save(tutorial: Tutorial): Promise<Tutorial> {
        const data     = serialize(tutorial),
              formData = createFormData(data);

        tutorial.getGallery().getItems().map((galleryItem: GalleryItem, key: number) => {
            const image = galleryItem.getImageSelect() ? galleryItem.getImageSelect().file : "";
            formData.append(`images[${key}]`, image);
        });

        const {data: {data: dataResponse}} = await this.getAjaxService().saveTutorial(formData);

        return deserialize(Tutorial, dataResponse);
    }
}
