import * as React               from "react";
import { observer }             from "mobx-react";
import SimpleReactValidator     from "simple-react-validator";
import { Promotion }            from "../../../Models/Promotion/Promotion";
import { PromotionInformation } from "../../../Models/Promotion/PromotionInformation";
import PromotionInformationView from "../../../Views/Components/Promotion/PromotionInformationView";

interface IInformationViewModelProps {
    promotion: Promotion;
    validator: SimpleReactValidator;
}

@observer
class InformationViewModel extends React.Component<IInformationViewModelProps, {}> {

    protected addItem = (ele: React.Component) => {
        const {promotion} = this.props;

        promotion.addItem(new PromotionInformation());
        ele.forceUpdate();
    };

    protected removeItem = (item: PromotionInformation, ele: React.Component) => {
        const {promotion} = this.props;

        promotion.removeItem(item);
        ele.forceUpdate();
    };

    public render(): React.ReactNode {
        const {promotion, validator} = this.props;

        return (
            <PromotionInformationView
                promotion={promotion}
                addItem={this.addItem}
                removeItem={this.removeItem}
                validator={validator}
            />
        );
    }
}

export default InformationViewModel;
