import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import OccupationView                       from "../../../Views/Pages/Occupation/View/OccupationView";
import { OccupationStore }                  from "../../../Store/OccupationStore";

interface IOccupationViewModelProps extends RouteComponentProps<any> {
    OccupationStore?: OccupationStore;
}

@inject(OccupationStore.NAME_STORE)
@observer
class OccupationViewModel extends React.Component<IOccupationViewModelProps, {}> {
    get occupationStore(): OccupationStore {
        return this.props.OccupationStore as OccupationStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.occupationStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.occupationStore.getPaginate();

        this.occupationStore
            .setPages(response.pages)
            .setOccupations(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <OccupationView
                occupations={this.occupationStore.getOccupations()}
                spinner={this.occupationStore.getSpinner()}
                add={() => history.push(renderRoute(PageNames.occupationAddPage))}
                getPage={this.getPage}
                pages={this.occupationStore.getPages()}
                currentPage={this.occupationStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(OccupationViewModel);
