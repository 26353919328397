import * as React              from "react";
import { TableCell, TableRow } from "@material-ui/core";
import formatDate              from "../../../config/formatDate";
import Actions                 from "../Table/Actions";
import moment                  from "moment";
import { Tutorial }            from "../../../Models/Tutorial/Tutorial";

interface ITableRowGalleryViewProps {
    tutorial: Tutorial;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowTutorialView extends React.Component<ITableRowGalleryViewProps, {}> {

    public render(): React.ReactNode {
        const {
                  tutorial,
                  onEdit,
                  onDelete,
              }    = this.props,
              item = tutorial.getGallery().getFirstItem();

        return (
            <TableRow>
                <TableCell>{tutorial.getName()}</TableCell>
                <TableCell>
                    {
                        item ? <img src={item.getImageFullPath()} alt="" className="img-fluid"/> : ""
                    }
                </TableCell>
                <TableCell>{moment(tutorial.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(tutorial.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowTutorialView;
