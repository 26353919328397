import { withNamespaces, WithNamespaces } from "react-i18next";
import * as React                         from "react";
import { Button }                         from "../Button/Button";
import VisibilityIcon                     from "@material-ui/icons/Visibility";
import EditIcon                           from "@material-ui/icons/Edit";
import DeleteForeverIcon                  from "@material-ui/icons/DeleteForever";

interface IActionsProps extends WithNamespaces {
    onEdit?: () => void;
    onDelete?: () => void;
    onView?: () => void;
}

class Actions extends React.Component<IActionsProps, {}> {
    public render(): React.ReactNode {
        const {

                  t,
                  onEdit,
                  onDelete,
                  onView,
              }            = this.props,
              propsButtons = {
                  color  : "primary" as "primary",
                  size   : "small" as "small",
                  spinner: false,
                  variant: "outlined" as "outlined",
              };

        return (
            <div className="actions">
                {onView
                 ? <Button {...propsButtons} onClick={onView}>
                     <VisibilityIcon fontSize={"small"}/> {t("edit.see")}
                 </Button>
                 : <React.Fragment/>}
                {onEdit
                 ? <Button {...propsButtons} onClick={onEdit}>
                     <EditIcon fontSize={"small"}/> {t("edit.edit")}
                 </Button>
                 : <React.Fragment/>}
                {onDelete
                 ? <Button {...propsButtons} onClick={onDelete}>
                     <DeleteForeverIcon fontSize={"small"}/> {t("edit.delete")}
                 </Button>
                 : <React.Fragment/>}
            </div>
        );
    }
}

export default withNamespaces("page")(Actions);
