import * as React                         from "react";
import Tabs                               from "@material-ui/core/Tabs";
import Tab                                from "@material-ui/core/Tab";
import {
    Checkbox,
    FormControlLabel,
    TextField,
}                                         from "@material-ui/core";
import { Row, Col }                       from "reactstrap";
import SimpleReactValidator               from "simple-react-validator";
import { TabPanel }                       from "../../../Components/TabPanel/TabPanel";
import { GroupButtons }                   from "../../../Components/GroupButtons/GroupButtons";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { observer }                       from "mobx-react";
import WysiwygViewModel                   from "../../../../ViewModel/WYSIWYG/WysiwygViewModel";
import { AboutUs }                        from "../../../../Models/AboutUs/AboutUs";
import CreateUpdatePageSeo                from "../../../Components/PageSeo/CreateUpdatePageSeo";


interface INewEditGalleryViewProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    currentTabSeo: number;
    setCurrentTabSeo: (tab: number) => void;
    aboutUs: AboutUs;
    validate: () => boolean;
    spinner: boolean;
    save: () => void;
    cancel: () => void;
    validator: SimpleReactValidator;
}

@observer
class CreateOrEditAboutUsView extends React.Component<INewEditGalleryViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  currentTabSeo,
                  setCurrentTabSeo,
                  aboutUs,
                  spinner,
                  cancel,
                  validate,
                  save,
                  validator,
                  t
              } = this.props;

        return (
            <div className={``}>
                <form noValidate autoComplete="off">
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setCurrentTab(newValue);
                        }} aria-label="tabs"
                    >
                        <Tab label={t("edit.general")}/>
                        <Tab label={t("SEO")}/>
                    </Tabs>
                    <hr/>
                    <TabPanel value={currentTab} index={0}>
                        <Row>
                            <Col xs={12} md={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label={t("edit.name")}
                                    className={""}
                                    value={aboutUs.getName() || ""}
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                        aboutUs.setName(evt.currentTarget.value);
                                    }}
                                    margin="normal"
                                />
                                {validator.message(t("edit.name"), aboutUs.getName(), "required")}
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={aboutUs.getActive()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                aboutUs.setActive(event.currentTarget.checked);
                                            }}
                                            value={aboutUs.getActive()}
                                            color="primary"
                                        />
                                    }
                                    label={t("edit.active")}
                                />
                            </Col>
                            <Col xs={12} md={12} xl={12}>
                                <WysiwygViewModel
                                    onChange={(item: string) => aboutUs.setDescription(item)}
                                    value={aboutUs.getDescription()}
                                />
                                {validator.message(t("edit.description"), aboutUs.getDescription(), "required")}
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <CreateUpdatePageSeo
                            currentTab={currentTabSeo}
                            setCurrentTab={setCurrentTabSeo}
                            validator={validator}
                            seoData={aboutUs.getSeoData()}
                        />
                    </TabPanel>
                    <hr/>
                    <GroupButtons
                        action={save}
                        spinner={spinner}
                        validate={validate}
                        cancel={cancel}
                    />
                </form>
            </div>
        );
    }
}

export default withNamespaces("page")(CreateOrEditAboutUsView);
