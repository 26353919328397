import InjectPrefix           from "../../../Decorators/InjectPrefix";
import { observer }           from "mobx-react";
import * as React             from "react";
import { Order }              from "../../../Models/Order/Order";
import {
    withNamespaces,
    WithNamespaces
}                             from "react-i18next";
import {
    Col,
    Row
}                             from "reactstrap";
import { Direction }          from "../../../Models/User/Direction";
import { Place as PlaceIcon } from "@material-ui/icons";
import NumberFormat           from "react-number-format";

interface IDetailsOrderProps extends WithNamespaces {
    order: Order;
    prefix?: string;
}

@InjectPrefix
@observer
class DetailsOrder extends React.Component<IDetailsOrderProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderDirection = (direction: Direction): React.ReactNode => {
        const {t} = this.props;
        return (
            <Col xs={12} sm={6}>
                <h5>Direccion de envio</h5>
                <ul className="list-details">
                    <li>
                        <span className="title">{t("forms:fullName")}:</span> {direction.getFullName()}
                    </li>
                    <li>
                        <span className="title">{t("forms:province")}:</span> {direction.getProvincie()}
                    </li>
                    <li>
                        <span className="title">{t("forms:canton")}:</span> {direction.getCanton()}
                    </li>
                    <li>
                        <span className="title">{t("forms:phone")}:</span> {direction.getPhone()}
                    </li>
                    <li>
                        <span className="title">{t("forms:exact-direction")}:</span> {direction.getDirection()}
                    </li>
                </ul>
                <section className="waze-container">
                    <a
                        target="_blank"
                        href={`https://waze.com/ul?ll=${direction.getLat()},${direction.getLng()}&z=10`}
                        className={`${this.prefix}-primary-color`}
                    >
                        Waze: <PlaceIcon/>
                    </a>
                </section>
            </Col>
        );
    };

    protected renderUser = (order: Order): React.ReactNode => {
        const {t}  = this.props,
              user = order.getUser();

        return (
            <Col xs={12} sm={6}>
                <h5>Usuario</h5>
                <ul className="list-details row">
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("forms:fullName")}:</span> {user.getFullName()}
                    </li>
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("forms:email")}:</span> {user.getEmail()}
                    </li>
                </ul>
                <h5>Metodo de Transporte</h5>
                <p>{order.getMethodTransport().getName()}</p>
                <h5>Metodo de Pago</h5>
                <p>{t(`methods-pay:${order.getMethodPay()}`)}</p>
            </Col>
        );
    };

    protected renderBillElectronic = (): React.ReactNode => {
        const {t, order}     = this.props,
              billElectronic = order.getBillElectronic();

        return (
            <Col xs={12}>
                <h5>Datos Facturacion Electronica</h5>
                <ul className="list-details">
                    <li>
                        <span className="title">{t("forms:type-dni")}:</span> {t(`dni:${billElectronic.getTypeDni()}`)}
                    </li>
                    <li>
                        <span className="title">{t("forms:dni")}:</span> {billElectronic.getDni()}
                    </li>
                    <li>
                        <span className="title">{t("forms:fullName")}:</span> {billElectronic.getFullName()}
                    </li>
                    <li>
                        <span className="title">{t("forms:phone")}:</span> {billElectronic.getPhone()}
                    </li>
                    <li>
                        <span className="title">{t("forms:exact-direction")}:</span> {billElectronic.getDirection()}
                    </li>
                </ul>
            </Col>
        );
    };

    protected renderSuccessPaymentGreenPay = () => {
        const {t, order}     = this.props,
              successPayment = order.getSuccessPayment();

        if (!successPayment) return;

        return (
            <Col xs={12}>
                <h5>{t("successPayment:title")}</h5>
                <ul className="list-details row">
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:chargeId")}:</span> {successPayment.getAuthorization()}
                    </li>
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:amount")}:</span>
                        <NumberFormat
                            displayType="text"
                            value={successPayment.getAmount().toFixed(2)}
                            thousandSeparator={true}
                            prefix={"¢"}
                            renderText={(formattedValue: string) => formattedValue}
                        />
                    </li>
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:description")}:</span> {successPayment.getOrderId()}
                    </li>
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:last4")}:</span> {successPayment.getLast4()}
                    </li>
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:brand")}:</span> {successPayment.getBrand()}
                    </li>
                </ul>
            </Col>
        );
    }

    protected renderSuccessPayment = () => {
        const {t, order}     = this.props,
              successPayment = order.getSuccessPayment();

        if (!successPayment) return;

        if (successPayment.isGreenPay()) return this.renderSuccessPaymentGreenPay();

        const chargeLog = successPayment.getChargeLog(),
              card      = chargeLog.getCard();

        return (
            <Col xs={12}>
                <h5>{t("successPayment:title")}</h5>
                <ul className="list-details row">
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:chargeId")}:</span> {successPayment.getChargeId()}
                    </li>
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:amount")}:</span> {chargeLog.getAmount()}
                    </li>
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:description")}:</span> {chargeLog.getDescription()}
                    </li>
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:last4")}:</span> {card.getLast4()}
                    </li>
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:brand")}:</span> {card.getBrand()}
                    </li>
                    <li className="col-12 col-sm-6">
                        <span className="title">{t("successPayment:exp_year")}:</span> {card.getExpYear()}
                    </li>
                </ul>
            </Col>
        );
    };

    public render(): React.ReactNode {
        const {
                  order,
                  t,
              }         = this.props,
              direction = order.getMethodTransport().getPickUp() ? undefined : order.getDirection();

        return (
            <Row className={`${this.prefix}-c-details-order`}>
                {this.renderUser(order)}
                {this.renderSuccessPayment()}
                {direction ? this.renderDirection(direction) : ""}
                {order.getIsBillElectronic() ? this.renderBillElectronic() : <React.Fragment/>}
                <Col xs={12}>
                    <h5 className="m-0">Estado Actual: {t(`order:${order.getStatus()}`)}</h5>
                </Col>
            </Row>
        );
    }
}

export default withNamespaces("page")(DetailsOrder);
