import * as React           from "react";
import { Container }        from "typedi";
import { ApplicationStore } from "../Store/ApplicationStore";
import { UserStore }        from "../Store/UserStore";
import { Error }            from "tslint/lib/error";
import User                 from "../Models/User/User";
import Middleware           from "../Decorators/Middleware";
import BaseMiddleware       from "./Base/BaseMiddleware";
import { Redirect }         from "react-router";
import Role                 from "../Models/User/Role";

@Middleware
export class RoleMiddleware extends BaseMiddleware {
    public static getAlias(): string {
        return "RoleMiddleware";
    }

    validate(options: any): boolean {
        const user: User | undefined = Container.get(ApplicationStore).getStore(UserStore).getUser();

        if (!user) throw new Error(`The user need is logged`);
        if (!options.roles) throw new Error(`The Middleware "RoleMiddleware" need a roles on options`);

        for (const role of options.roles) {
            // @ts-ignore
            if (user.hasRole({name: role} as Role)) return true;
        }

        return false;
    }

    reject(): React.ReactNode {
        return <Redirect to={"error-403"}/>;
    }
}