import React                      from "react";
import Drawer                     from "@material-ui/core/Drawer";
import Button                     from "@material-ui/core/Button";
import List                       from "@material-ui/core/List";
import ListItem                   from "@material-ui/core/ListItem";
import ListItemText               from "@material-ui/core/ListItemText";
import Icon                       from "@material-ui/core/Icon";
import { Link }                   from "react-router-dom";
import { withNamespaces }         from "react-i18next";
import { withRouter }             from "react-router";
import { PageNames, renderRoute } from "../../../Routes/routes";
import InjectPrefix               from "../../../Decorators/InjectPrefix";
import IBaseProps                 from "../../../ViewModel/Props/IBaseProps";
import cx                         from "classnames";
import { Roles }                  from "../../../config/security";
import User                       from "../../../Models/User/User";
import { observer }               from "mobx-react";

interface MenuProps extends IBaseProps {
    openMenuLeft: boolean;
    setOpenMenuLeft: (evt: boolean) => void;
    prefix?: string;
    currentPage: string;
    user: User | undefined;
}

const pages = [
    {
        page: PageNames.actionPage,
        role: Roles.ADMIN,
    },
    {
        page: PageNames.homePage,
        role: Roles.ADMIN,
    },
    {
        page: PageNames.orderPage,
        role: Roles.ORDER,
    },
    {
        page: PageNames.galleryPage,
        role: Roles.GALLERY,
    },
    {
        page: PageNames.promotionPage,
        role: Roles.PROMOTION,
    },
    {
        page: PageNames.tutorialPage,
        role: Roles.TUTORIAL,
    },
    {
        page: PageNames.aboutUsPage,
        role: Roles.ABOUT_US,
    },
    {
        page: PageNames.userPage,
        role: Roles.USER,
    },
    {
        page: PageNames.occupationPage,
        role: Roles.OCCUPATION,
    },
    {
        page: PageNames.recommendationPersonPage,
        role: Roles.RECOMMENDATION_PERSON,
    },
    {
        page: PageNames.pagePage,
        role: Roles.PAGE,
    },
    {
        page: PageNames.contactUsPage,
        role: Roles.CONTACT_US,
    },
    {
        page: PageNames.productPage,
        role: Roles.PRODUCT,
    },
    {
        page: PageNames.categoryPage,
        role: Roles.CATEGORY_PRODUCT,
    },
    {
        page: PageNames.methodTransportPage,
        role: Roles.METHOD_TRANSPORT,
    },
    {
        page: PageNames.colorPalettePage,
        role: Roles.COLOR_PALETTE,
    },
];


@InjectPrefix
@observer
class Menu extends React.Component<MenuProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) return;

        this.props.setOpenMenuLeft(open);
    };

    private getSideList = (): React.ReactNode => {
        const {t, currentPage, user} = this.props;

        if (!user) return (<React.Fragment/>);

        return (
            <div
                role="presentation"
                onClick={() => this.toggleDrawer(false)}
                onKeyDown={this.toggleDrawer(false)}
                className={`${this.prefix}-header-mobile-nav`}
            >
                <List className="w-100">
                    {pages
                        .filter(item => {
                            return user.hasRole(item.role);
                        })
                        .map(item => (
                                 <ListItem button key={item.page} onClick={() => this.goTo(renderRoute(item.page))}>
                                     <ListItemText primary={
                                         <Link
                                             to={renderRoute(item.page)}
                                             className={cx({active: item.page === currentPage})}
                                         >
                                             {t(`menu:${item.page}`)}
                                         </Link>
                                     }/>
                                 </ListItem>
                             )
                        )}
                </List>
            </div>
        );
    };

    private goTo(route: string) {
        this.props.history.push(route);
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        return (
            <>
                <Drawer open={this.props.openMenuLeft} onClose={this.toggleDrawer(false)}>
                    {this.getSideList()}
                </Drawer>
                <Button onClick={this.toggleDrawer(true)} className={""}>
                    <Icon>menu</Icon>
                </Button>
            </>
        );
    }
}

// @ts-ignore
export default withRouter(withNamespaces("header")(Menu));
