import {
    Container,
    Service
}                              from "typedi";
import {
    AxiosInstance,
    AxiosPromise,
    AxiosResponse
}                              from "axios";
import AxiosService            from "./AxiosService";
import API_CONST               from "../config/API_CONST";
import { OrderStatus }         from "../config/orderStatus";
import { CONFIG_CALL_GET_API } from "../config/axios.config";

@Service()
export default class AjaxService {
    private getAxiosService(): AxiosService {
        return Container.get(AxiosService);
    }

    private getAxios(): AxiosInstance {
        return this.getAxiosService().axios;
    }

    protected replaceURL(url: string, params: object = {}) {
        for (const key in params) {
            url = url.replace(`:${key}`, params[key]);
        }

        return url;
    }

    public getMe(): AxiosPromise<any> {
        return this.getAxios().get(API_CONST.GET_ME);
    }

    public async saveGallery(gallery: any): Promise<AxiosResponse> {
        this.getAxiosService().setMultipart(true);
        const data = await this.getAxios().post(API_CONST.POST_GALLERY, gallery);
        this.getAxiosService().setMultipart(false);

        return data;
    }

    public async getPaginateGalleries(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_GALLERIES, {page, size}));
    }

    public async getGalleryId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_GALLERY_BY_ID, {id}));
    }

    public async deleteGalleryId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_GALLERY_BY_ID, {id}));
    }

    public async savePromotion(promotion: any): Promise<AxiosResponse> {
        this.getAxiosService().setMultipart(true);
        const data = await this.getAxios().post(API_CONST.POST_PROMOTION, promotion);
        this.getAxiosService().setMultipart(false);

        return data;
    }

    public async getPaginatePromotions(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_PROMOTIONS, {page, size}));
    }

    public async getPromotionId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PROMOTION_BY_ID, {id}));
    }

    public async deletePromotionId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_PROMOTION_BY_ID, {id}));
    }

    public async saveTutorial(item: any): Promise<AxiosResponse> {
        this.getAxiosService().setMultipart(true);
        const data = await this.getAxios().post(API_CONST.POST_TUTORIAL, item);
        this.getAxiosService().setMultipart(false);

        return data;
    }

    public async getPaginateTutorials(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_TUTORIALS, {page, size}));
    }

    public async getTutorialId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_TUTORIAL_BY_ID, {id}));
    }

    public async deleteTutorialId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_TUTORIAL_BY_ID, {id}));
    }

    public async getPaginateContactUs(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_CONTACT_US, {page, size}));
    }

    public async getContactUsId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_CONTACT_US_BY_ID, {id}));
    }

    public async deleteContactUsId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_CONTACT_US_BY_ID, {id}));
    }

    public async saveAboutUs(item: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_ABOUT_US, item);
    }

    public async getPaginateAboutUs(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_ABOUT_US, {page, size}));
    }

    public async getAboutUsId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_ABOUT_US_BY_ID, {id}));
    }

    public async deleteAboutUsId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_ABOUT_US_BY_ID, {id}));
    }

    public async saveUser(item: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_USER, item);
    }

    public async updateUser(item: any, id: string): Promise<AxiosResponse> {
        return this.getAxios().put(this.replaceURL(API_CONST.PUT_USER, {id}), item);
    }

    public async getPaginateUsers(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_USER, {page, size}));
    }

    public async getUserId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_USER_BY_ID, {id}));
    }

    public async deleteUserId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_USER_BY_ID, {id}));
    }

    public async savePage(promotion: any): Promise<AxiosResponse> {
        this.getAxiosService().setMultipart(true);
        const data = await this.getAxios().post(API_CONST.POST_PAGE, promotion);
        this.getAxiosService().setMultipart(false);

        return data;
    }

    public async getPaginatePages(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_PAGE, {page, size}));
    }

    public getPageId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGE_BY_ID, {id}));
    }

    public getPageName(name: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGE_BY_NAME, {name}));
    }

    public deletePageId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_PAGE_BY_ID, {id}));
    }

    public getPaginateOccupation(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_OCCUPATION, {page, size}));
    }

    public getOccupation(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_OCCUPATION_BY_ID, {id}));
    }

    public deleteOccupation(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_OCCUPATION_BY_ID, {id}));
    }

    public saveOccupation(data: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_OCCUPATION, data);
    }

    public getPaginateRecommendationPerson(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_RECOMMENDATION_PERSON, {page, size}));
    }

    public getRecommendationPerson(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_RECOMMENDATION_PERSON_BY_ID, {id}));
    }

    public deleteRecommendationPerson(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_RECOMMENDATION_PERSON_BY_ID, {id}));
    }

    public async saveRecommendationPerson(data: any): Promise<AxiosResponse> {
        this.getAxiosService().setMultipart(true);
        const response = await this.getAxios().post(API_CONST.POST_RECOMMENDATION_PERSON, data);
        this.getAxiosService().setMultipart(false);

        return response;
    }

    public getRoles(): Promise<AxiosResponse> {
        return this.getAxios().get(API_CONST.GET_ROlES);
    }

    public getPaginateProducts(page: number, size: number, params: any): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_PRODUCT, {page, size}), {
            params
        });
    }

    public getProductId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PRODUCT_BY_ID, {id}));
    }

    public deleteProductId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_PRODUCT_BY_ID, {id}));
    }

    public async saveProduct(data: any): Promise<AxiosResponse> {
        this.getAxiosService().setMultipart(true);
        const response = await this.getAxios().post(API_CONST.POST_PRODUCT, data);
        this.getAxiosService().setMultipart(false);

        return response;
    }

    public getPaginateCategories(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_CATEGORY, {page, size}));
    }

    public getCategoryId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_CATEGORY_BY_ID, {id}));
    }

    public deleteCategoryId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_CATEGORY_BY_ID, {id}));
    }

    public saveCategory(data: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_CATEGORY, data);
    }

    public getAllSubCategories(): Promise<AxiosResponse> {
        return this.getAxios().get(API_CONST.GET_ALL_SUBCATEGORIES);
    }

    public getAllCategories(): Promise<AxiosResponse> {
        return this.getAxios().get(API_CONST.GET_ALL_CATEGORIES);
    }

    public getPaginateMethodTransports(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_METHOD_TRANSPORT, {page, size}));
    }

    public getActiveMethodTransports(): Promise<AxiosResponse> {
        return this.getAxios().get(API_CONST.GET_ACTIVE_METHOD_TRANSPORT);
    }

    public getMethodTransportId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_METHOD_TRANSPORT_BY_ID, {id}));
    }

    public deleteMethodTransportId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_METHOD_TRANSPORT_BY_ID, {id}));
    }

    public saveMethodTransport(data: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_METHOD_TRANSPORT, data);
    }

    public getPaginateOrders(page: number, size: number, params: any): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_ORDER, {page, size}), {
            params
        });
    }

    public getOrderId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_ORDER_ID, {id}));
    }

    public updateStatusOrderId(orderId: string, status: OrderStatus): Promise<AxiosResponse> {
        return this.getAxios().put(API_CONST.PUT_STATUS_ORDER, {_id: orderId, status});
    }

    public sendEmailOrder(orderId: string, email: string): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_SEND_EMAIL_ORDER, {_id: orderId, email});
    }

    public getProductRelationshipById(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PRODUCT_RELATIONSHIP_BY_ID, {id}));
    }

    public getProductRelationshipByProductId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PRODUCT_RELATIONSHIP_BY_PRODUCT_ID, {id}));
    }

    public async saveProductRelationship(data: any): Promise<AxiosResponse> {
        this.getAxiosService().setMultipart(true);
        const response = await this.getAxios().post(API_CONST.POST_PRODUCT_RELATIONSHIP, data);
        this.getAxiosService().setMultipart(false);

        return response;
    }

    public getActionByName(name: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_ACTION, {name}));
    }

    public postAction(name: string): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_ACTION, {name});
    }

    public getColorPaletteId(id: string): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_COLOR_PALETTE_BY_ID, {id}));
    }

    public getSimpleAll(): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_SIMPLE_ALL_COLOR_PALETTE_BY_ID));
    }

    public deleteColorPaletteId(id: string): Promise<AxiosResponse> {
        return this.getAxios().delete(this.replaceURL(API_CONST.DELETE_COLOR_PALETTE_BY_ID, {id}));
    }

    public saveColorPalette(data: any): Promise<AxiosResponse> {
        return this.getAxios().post(API_CONST.POST_COLOR_PALETTE, data);
    }

    public getPaginateColorPalette(page: number, size: number): Promise<AxiosResponse> {
        return this.getAxios().get(this.replaceURL(API_CONST.GET_PAGINATE_COLOR_PALETTE, {page, size}));
    }
}
