import { inject, observer }               from "mobx-react";
import * as React                         from "react";
import ConfirmationModal                  from "../../../Views/Components/Modal/ConfirmationModal";
import ModalService                       from "../../../Service/ModalService";
import { Container }                      from "typedi";
import { ModalStore }                     from "../../../Store/ModalStore";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { AboutUsStore }                  from "../../../Store/AboutUsStore";
import { AboutUs }                       from "../../../Models/AboutUs/AboutUs";

interface IDeleteAboutUsViewModelProps extends WithNamespaces {
    ModalStore?: ModalStore;
    AboutUsStore?: AboutUsStore;
    aboutUs: AboutUs;
}

@inject(ModalStore.NAME_STORE, AboutUsStore.NAME_STORE)
@observer
class DeleteAboutUsViewModel extends React.Component<IDeleteAboutUsViewModelProps, {}> {
    private readonly modalService: ModalService;

    constructor(props: IDeleteAboutUsViewModelProps) {
        super(props);

        this.modalService = Container.get(ModalService);
    }

    get aboutUsStore(): AboutUsStore {
        return this.props.AboutUsStore as AboutUsStore;
    }

    get modalStore(): ModalStore {
        return this.props.ModalStore as ModalStore;
    }

    public action = async () => {
        const {aboutUs} = this.props;

        this.aboutUsStore.setSpinner(true);
        await this.aboutUsStore.deleteById(aboutUs.getId());
        const data = await this.aboutUsStore.getPaginate();

        this.aboutUsStore
            .setPages(data.pages)
            .setAboutUss(data.data)
            .setSpinner(false);

        this.modalService.closeModal();
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <ConfirmationModal
                handleClose={this.modalService.closeModal}
                open={this.modalStore.getModal().getOpenModal()}
                action={this.action}
                spinner={this.aboutUsStore.getSpinner()}
                title={t("confirm.content")}
                content={t("confirm.title")}
            />
        );
    }
}

export default withNamespaces("modal")(DeleteAboutUsViewModel);
