import * as React           from "react";
import { observable }       from "mobx";
import {
    inject,
    observer
}                           from "mobx-react";
import { withNamespaces }   from "react-i18next";
import { withRouter }       from "react-router";
import { LinearProgress }   from "@material-ui/core";
import { FormStore }        from "../../../Store/FormStore";
import {
    PageNames,
    renderRoute
}                           from "../../../Routes/routes";
import { Page }             from "../../../Models/Page/Page";
import IBaseProps           from "../../Props/IBaseProps";
import { PageStore }        from "../../../Store/PageStore";
import CreateOrEditPageView from "../../../Views/Pages/Page/CreateOrEdit/CreateOrEditPageView";
import { pages }            from "../../../config/Pages";

interface INewEditPagesViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    PageStore?: PageStore;
}

@inject(FormStore.NAME_STORE, PageStore.NAME_STORE)
@observer
class CreateOrEditPagesViewModel extends React.Component<INewEditPagesViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    constructor(props: INewEditPagesViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }

        this.pagesStore.setPage(new Page());
    }

    public componentDidMount = async (): Promise<void> => {
        const {id} = this.props.match.params;

        if (!id) {
            const page = await this.pagesStore.getPageDefault();

            if (!page) return;

            this.pagesStore.getPage().setSeoData(page.getSeoData());

            return;
        }

        const page = await this.pagesStore.getById(id);

        if (page.getName() === pages.home) {
            this.pagesStore.populateExtraDataHome(page);
        }

        this.pagesStore.setPage(page);

        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get pagesStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const page      = await this.pagesStore.save(this.pagesStore.getPage()),
              {history} = this.props;

        history.push(renderRoute(PageNames.pagePage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.pagePage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    public render(): React.ReactNode {
        if (this.loading)
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (this.pagesStore.getPage())
            return (
                <CreateOrEditPageView
                    currentTab={this.getCurrentTab()}
                    setCurrentTab={this.setCurrentTab}
                    page={this.pagesStore.getPage()}
                    validate={this.validate}
                    spinner={this.getSpinner()}
                    save={this.save}
                    validator={this.formStore.getValidator()}
                    cancel={this.cancel}
                />
            );

        return (<React.Fragment/>);
    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditPagesViewModel));
