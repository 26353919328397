import * as React              from "react";
import { observable }          from "mobx";
import {
    inject,
    observer
}                              from "mobx-react";
import { withNamespaces }      from "react-i18next";
import { withRouter }          from "react-router";
import { LinearProgress }      from "@material-ui/core";
import { FormStore }           from "../../../Store/FormStore";
import {
    PageNames,
    renderRoute
}                              from "../../../Routes/routes";
import { ProductStore }        from "../../../Store/ProductStore";
import { Product }             from "../../../Models/Product/Product";
import IBaseProps              from "../../Props/IBaseProps";
import CreateOrEditProductView from "../../../Views/Pages/Product/CreateOrEdit/CreateOrEditProductView";
import { CategoryStore }       from "../../../Store/CategoryStore";
import { ProductRelationship } from "../../../Models/Product/ProductRelationship";
import { ColorPaletteStore }   from "../../../Store/ColorPaletteStore";
import { ColorPalette }        from "../../../Models/Color/ColorPalette";

interface INewEditProductViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    ProductStore?: ProductStore;
    ColorPaletteStore?: ColorPaletteStore;
    CategoryStore?: CategoryStore;
}

@inject(FormStore.NAME_STORE, ProductStore.NAME_STORE, CategoryStore.NAME_STORE, ColorPaletteStore.NAME_STORE)
@observer
class CreateOrEditProductViewModel extends React.Component<INewEditProductViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    @observable
    private colorPalettes: ColorPalette[] = [];

    constructor(props: INewEditProductViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }
    }

    protected initColorPalettes = async () => {
        this.colorPalettes = await this.colorPaletteStore.getSimpleAll();
    }

    public componentDidMount = async (): Promise<void> => {
        const {id} = this.props.match.params;

        if (!id) {
            const product = new Product();

            this.product.setProduct(product);

            return;
        }

        const product             = await this.product.getById(id),
              productRelationShip = await this.product.getProductRelationshipByProductId(id);

        this.product.setProduct(product);
        this.product.setProductRelationship(productRelationShip || new ProductRelationship());
        this.product.getProductRelationship().setProductId(product.getId());
        await this.initColorPalettes();
        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get categoryStore(): CategoryStore {
        return this.props.CategoryStore as CategoryStore;
    }

    get colorPaletteStore(): ColorPaletteStore {
        return this.props.ColorPaletteStore as ColorPaletteStore;
    }

    get product(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const product   = await this.product.save(this.product.getProduct()),
              {history} = this.props;

        await this.product.saveProductRelationshipByProductId(this.product.getProductRelationship());

        history.push(renderRoute(PageNames.productPage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.productPage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    public render(): React.ReactNode {
        if (this.loading || !this.product.getProduct() || !this.product.getProductRelationship())
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (this.product.getProduct())
            return (
                <CreateOrEditProductView
                    currentTab={this.getCurrentTab()}
                    setCurrentTab={this.setCurrentTab}
                    product={this.product.getProduct()}
                    validate={this.validate}
                    spinner={this.getSpinner()}
                    save={this.save}
                    validator={this.formStore.getValidator()}
                    cancel={this.cancel}
                    productRelationship={this.product.getProductRelationship()}
                    colorPalettes={this.colorPalettes}
                />
            );

        return (<React.Fragment/>);
    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditProductViewModel));
