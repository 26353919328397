export enum pages {
    contact              = "PAGE_CONTACT",
    default              = "PAGE_DEFAULT",
    home                 = "PAGE_HOME",
    products             = "PAGE_PRODUCTS",
    promotions           = "PAGE_PROMOTION",
    tutorials            = "PAGE_TUTORIALS",
    recommendationPerson = "PAGE_RECOMMENDATION_PERSON",
    whoAre               = "PAGE_WHO_ARE",
}
