import * as React from "react";

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

// tslint:disable-next-line:variable-name
export const TabPanel: React.FunctionComponent<ITabPanelProps> = (props: ITabPanelProps) => {
    const {children, value, index} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            {children}
        </div>
    );
};
