import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import { Tutorial }                        from "../../../Models/Tutorial/Tutorial";
import TableRowTutorialView                from "../../../Views/Components/TableRowTutorial/TableRowTutorialView";
import DeleteTutorialViewModel             from "../Delete/DeleteTutorialViewModel";

interface ITableRowTutorialViewModelProps extends RouteComponentProps<any> {
    tutorial: Tutorial;
}

class TableRowTutorialViewModel extends React.Component<ITableRowTutorialViewModelProps, {}> {

    protected onEdit = () => {
        const {history, tutorial} = this.props;
        history.push(renderRoute(PageNames.tutorialEditPage, {id: tutorial.getId()}));
    };

    protected onDelete = () => {
        const {tutorial} = this.props;
        Container.get(ModalService).openModal(DeleteTutorialViewModel, {
            tutorial
        });
    };

    public render(): React.ReactNode {
        const {tutorial} = this.props;

        return (
            <TableRowTutorialView
                tutorial={tutorial}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowTutorialViewModel);
