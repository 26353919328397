import {
    custom,
    list,
    object,
    serializable
}                     from "serializr";
import { observable } from "mobx";
import { Color }      from "./Color";
import { date }       from "../../Serializer/date";
import {
    removeItem,
    reorder
}                     from "../../Utils/array";

export class ColorPalette {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private name: string = "";

    @observable
    @serializable(list(object(Color)))
    private colors: Color[] = [];

    @serializable
    @observable
    private message: string;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    public getId(): string {
        return this._id;
    }

    public setId(value: string): this {
        this._id = value;

        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string): this {
        this.name = value;

        return this;
    }

    public getColors(): Color[] {
        return this.colors;
    }

    public setColors(value: Color[]): this {
        this.colors = value;

        return this;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public removeColor(color: Color | number) {
        if (typeof color === "number") {
            this.getColors().splice(color, 1);
            return;
        }

        this.setColors(removeItem(this.getColors(), color as Color, "uuid" as keyof Color));
    }

    public addColor(color: Color) {
        this.colors.push(color);
    }

    public reorderColor(startIndex: number, endIndex: number) {
        this.setColors(reorder(this.getColors(), startIndex, endIndex));
    }
}