import { action, observable } from "mobx";
import AccessToken            from "../Models/Security/AccessToken";
import { persist }            from "mobx-persist";
import { UserStore }          from "./UserStore";
import AxiosService           from "../Service/AxiosService";
import { Container }          from "typedi";
import BaseStore              from "./Base/BaseStore";

export class TokenStore extends BaseStore {
    public static readonly NAME_STORE: string = "TokenStore";

    @persist("object", AccessToken)
    @observable
    private accessToken: AccessToken | undefined;

    protected init() {
        this.needPersistData = true;
    }

    @action
    public setAccessToken(accessToken: AccessToken | undefined) {
        this.accessToken = accessToken;
    }

    private getAxiosService(): AxiosService {
        return Container.get(AxiosService);
    }

    public getAccessToken = (): AccessToken | undefined => {
        return this.accessToken;
    };

    public logout = async () => {
        const token = this.getAccessToken();
        if (token)
            await this.getAxiosService().logout(token);

        this.applicationStore.getStore(UserStore).setUser(undefined);
        this.setAccessToken(undefined);
        window.location.href = "/login";
    };
}
