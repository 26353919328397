import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import User                                from "../../../Models/User/User";
import DeleteUserViewModel                 from "../Delete/DeleteUserViewModel";
import TableRowUserView                    from "../../../Views/Components/TableRowUser/TableRowUserView";

interface ITableRowAboutUsViewModelProps extends RouteComponentProps<any> {
    user: User;
}

class TableRowUserViewModel extends React.Component<ITableRowAboutUsViewModelProps, {}> {
    protected onEdit = () => {
        const {user, history} = this.props;
        history.push(renderRoute(PageNames.userEditPage, {id: user.getId()}));
    };

    protected onDelete = () => {
        const {user} = this.props;
        Container.get(ModalService).openModal(DeleteUserViewModel, {
            user
        });
    };

    public render(): React.ReactNode {
        const {user} = this.props;

        return (
            <TableRowUserView
                user={user}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowUserViewModel);
