import BaseStore                  from "./Base/BaseStore";
import { deserialize, serialize } from "serializr";
import { Container }              from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { observable }                          from "mobx";
import { globalPagerOptions, IPaginationData } from "../config/pagination";
import { Order }                               from "../Models/Order/Order";
import { OrderStatus }                         from "../config/orderStatus";
import { OrderFilter }                         from "../Models/Order/OrderFilter";

export class OrderStore extends BaseStore {
    public static readonly NAME_STORE: string = "OrderStore";

    @observable
    private orders: Order[] = [];

    @observable
    private order: Order;

    @observable
    private orderFilter: OrderFilter = new OrderFilter();

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getOrderFilter = (): OrderFilter => {
        return this.orderFilter;
    };

    public setOrderFilter = (value: OrderFilter) => {
        this.orderFilter = value;
    };

    public getOrder(): Order {
        return this.order;
    }

    public setOrder(value: Order): this {
        this.order = value;

        return this;
    }

    public getOrders(): Order[] {
        return this.orders;
    }

    public setOrders(value: Order[]): this {
        this.orders = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<Order>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateOrders(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE,
            serialize(this.getOrderFilter()),
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(Order, item)),
            pages: dataResponse.pages
        } as IPaginationData<Order>;
    }

    public async getById(id: string): Promise<Order> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getOrderId(id);

        return deserialize(Order, dataResponse);
    }

    public async updateStatus(order: Order, status: OrderStatus): Promise<Order> {
        const {data: {data: dataResponse}} = await this.getAjaxService().updateStatusOrderId(order.getId(), status);

        return deserialize(Order, dataResponse);
    }

    public async sendEmail(order: Order, email: string): Promise<boolean> {
        const {data: {data}} = await this.getAjaxService().sendEmailOrder(order.getId(), email);

        return data;
    }
}
