import * as React                         from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import InjectPrefix                       from "../../Decorators/InjectPrefix";
import User                               from "../../Models/User/User";

interface HomeViewProps extends WithNamespaces {
    prefix?: string;
    user: User;
}

@InjectPrefix
class HomeView extends React.Component<HomeViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {user} = this.props;

        return (
            <div className={`${this.prefix}-p-home`}>
                You are login {user.getEmail()}
            </div>
        );
    }
}

export default withNamespaces("page")(HomeView);
