import * as React      from "react";
import { EditorState } from "draft-js";
import { Editor }      from "react-draft-wysiwyg";
import InjectPrefix    from "../../../Decorators/InjectPrefix";

interface IWysiwygProps {
    editorState: EditorState;
    setEditorState: (value: EditorState) => void;
    prefix?: string;
}

@InjectPrefix
class Wysiwyg extends React.Component<IWysiwygProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  editorState,
                  setEditorState
              } = this.props;

        return (
            <Editor
                editorState={editorState}
                wrapperClassName={`${this.prefix}-wysiwyg`}
                editorClassName="editor"
                onEditorStateChange={setEditorState}
            />
        );
    }
}

export default Wysiwyg;
