import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import { ContactUs }                       from "../../../Models/ContactUs/ContactUs";
import TableRowContactUsView               from "../../../Views/Components/TableRowContactUs/TableRowContactUsView";
import DeleteContactUsViewModel            from "../Delete/DeleteContactUsViewModel";

interface ITableRowContactUsViewModelProps extends RouteComponentProps<any> {
    contactUs: ContactUs;
}

class TableRowContactUsViewModel extends React.Component<ITableRowContactUsViewModelProps, {}> {
    protected onDelete = () => {
        const {contactUs} = this.props;
        Container.get(ModalService).openModal(DeleteContactUsViewModel, {
            contactUs
        });
    };

    protected onView = () => {
        const {history} = this.props;
        history.push(renderRoute(PageNames.contactUsPageView));
    };

    public render(): React.ReactNode {
        const {contactUs} = this.props;

        return (
            <TableRowContactUsView
                contactUs={contactUs}
                onDelete={this.onDelete}
                onView={this.onView}
            />
        );
    }
}

export default withRouter(TableRowContactUsViewModel);
