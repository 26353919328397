import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import AboutUsView                       from "../../../Views/Pages/AboutUs/View/AboutUsView";
import { AboutUsStore }                  from "../../../Store/AboutUsStore";

interface IAboutUsViewModelProps extends RouteComponentProps<any> {
    AboutUsStore?: AboutUsStore;
}

@inject(AboutUsStore.NAME_STORE)
@observer
class AboutUsViewModel extends React.Component<IAboutUsViewModelProps, {}> {
    get aboutUsStore(): AboutUsStore {
        return this.props.AboutUsStore as AboutUsStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.aboutUsStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.aboutUsStore.getPaginate();

        this.aboutUsStore
            .setPages(response.pages)
            .setAboutUss(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <AboutUsView
                aboutUss={this.aboutUsStore.getAboutUss()}
                spinner={this.aboutUsStore.getSpinner()}
                add={() => history.push(renderRoute(PageNames.aboutUsAddPage))}
                getPage={this.getPage}
                pages={this.aboutUsStore.getPages()}
                currentPage={this.aboutUsStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(AboutUsViewModel);
