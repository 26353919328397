import * as React     from "react";
import Wysiwyg        from "../../Views/Components/WYSIWYG/Wysiwyg";
import {
    ContentState,
    convertFromHTML,
    convertToRaw,
    EditorState
}                     from "draft-js";
import { observable } from "mobx";
import { observer }   from "mobx-react";
import draftToHtml    from "draftjs-to-html";

interface IWysiwygViewModelProps {
    onChange: (value: string) => void;
    value: string;
}

@observer
class WysiwygViewModel extends React.Component<IWysiwygViewModelProps, {}> {
    @observable
    private editorState: EditorState;

    public componentDidMount = (): void => {
        this.initEditorState();
    };

    protected initEditorState = () => {
        const value = (this.props.value || "").replace(/<[^>]*>?/gm, "").replace(/(\r\n|\n|\r)/gm,"");
        if (!value) {
            this.editorState = EditorState.createEmpty();

            return;
        }

        const blocksFromHTML = convertFromHTML(value),
              contentBlocks  = ContentState.createFromBlockArray(
                  blocksFromHTML.contentBlocks,
                  blocksFromHTML.entityMap
              );

        this.editorState = EditorState.createWithContent(contentBlocks);
    };

    protected setEditorState = (value: EditorState) => {
        this.editorState = value;
        const {onChange} = this.props;

        onChange(
            draftToHtml(
                convertToRaw(this.editorState.getCurrentContent()),
                {
                    separator: " ",
                    trigger  : "#",
                },
            )
        );
    };

    public render(): React.ReactNode {
        if (this.editorState) {
            return (
                <Wysiwyg
                    editorState={this.editorState}
                    setEditorState={this.setEditorState}
                />
            );
        }

        return <React.Fragment/>;
    }
}

export default WysiwygViewModel;
