import { inject, observer }   from "mobx-react";
import { ConfigurationStore } from "../../Store/ConfigurationStore";
import { UserStore }          from "../../Store/UserStore";
import * as React             from "react";
import MenuUserView           from "../../Views/Components/Header/MenuUserView";

interface IMenuUserViewModelProps {
    ConfigurationStore?: ConfigurationStore;
    UserStore?: UserStore;
}

@inject(ConfigurationStore.NAME_STORE, UserStore.NAME_STORE)
@observer
export class MenuUserViewModel extends React.Component<IMenuUserViewModelProps, {}> {
    get configurationStore(): ConfigurationStore {
        return this.props.ConfigurationStore as ConfigurationStore;
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        return (
            <MenuUserView
                getAnchorEl={this.configurationStore.getAnchorEl}
                setAnchorEl={this.configurationStore.setAnchorEl}
            />
        );
    }
}
