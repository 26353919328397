import {
    custom,
    object,
    serializable
}                       from "serializr";
import { date }         from "../../Serializer/date";
import { observable }   from "mobx";
import { Category }     from "./Category";
import { IImageSelect } from "../Gallery/GalleryItem";

export class Product {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private code: string = "";

    @serializable
    @observable
    private name: string = "";

    @serializable
    @observable
    private price: number;

    @serializable
    @observable
    private order: number;

    @serializable
    @observable
    private uuid: string;

    @serializable
    @observable
    private description: string = "";

    @serializable
    @observable
    private image: string = "";

    @serializable
    @observable
    private brand: string = "";

    @serializable
    @observable
    private categoryId: string;

    @serializable
    @observable
    private subCategoryId: string;

    @serializable
    @observable
    private colorPaletteId: string;

    @serializable
    @observable
    private slug: string;

    @serializable
    @observable
    private inStock: boolean;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    @serializable(object(Category))
    private category: Category;

    @serializable(object(Category))
    private subCategory: Category;

    @observable
    private imageSelect: IImageSelect;

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getCode(): string {
        return this.code;
    }

    public setCode(value: string) {
        this.code = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string) {
        this.name = value;
    }

    public getPrice(): number {
        return this.price;
    }

    public setPrice(value: number) {
        this.price = value;
    }

    public getUuid(): string {
        return this.uuid;
    }

    public setUuid(value: string) {
        this.uuid = value;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(value: string) {
        this.description = value;
    }

    public getImageFullPath(): string {
        return this.image ? this.image : "";
    }

    public getImage(): string {
        return this.image;
    }

    public setImage(value: string) {
        this.image = value;
    }

    public getBrand(): string {
        return this.brand;
    }

    public setBrand(value: string) {
        this.brand = value;
    }

    public getCategoryId(): string {
        return this.categoryId;
    }

    public setCategoryId(value: string) {
        this.categoryId = value;
    }

    public getSubCategoryId(): string {
        return this.subCategoryId;
    }

    public setSubCategoryId(value: string) {
        this.subCategoryId = value;
    }

    public getSlug(): string {
        return this.slug;
    }

    public setSlug(value: string) {
        this.slug = value;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }

    public getCategory(): Category {
        return this.category;
    }

    public setCategory(value: Category) {
        this.category = value;
    }

    public getSubCategory(): Category {
        return this.subCategory;
    }

    public setSubCategory(value: Category) {
        this.subCategory = value;
    }

    public getImageSelect(): IImageSelect {
        return this.imageSelect;
    }

    public setImageSelect(value: IImageSelect) {
        this.imageSelect = value;
    }

    public getOrder(): number {
        return this.order;
    }

    public setOrder(value: number) {
        this.order = value;
    }

    public getInStock(): boolean {
        return this.inStock;
    }

    public setInStock(value: boolean) {
        this.inStock = value;
    }

    public getColorPaletteId(): string {
        return this.colorPaletteId;
    }

    public setColorPaletteId(value: string) {
        this.colorPaletteId = value;
    }
}
