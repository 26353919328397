/**
 * Roles
 */
export enum Roles {
    ADMIN                 = "ADMIN",
    ABOUT_US              = "ABOUT_US",
    CONTACT_US            = "CONTACT_US",
    GALLERY               = "GALLERY",
    OCCUPATION            = "OCCUPATION",
    PAGE                  = "PAGE",
    PROMOTION             = "PROMOTION",
    RECOMMENDATION_PERSON = "RECOMMENDATION_PERSON",
    TUTORIAL              = "TUTORIAL",
    USER                  = "USER",
    PRODUCT               = "PRODUCT",
    CATEGORY_PRODUCT      = "CATEGORY_PRODUCT",
    METHOD_TRANSPORT      = "METHOD_TRANSPORT",
    COLOR_PALETTE         = "COLOR_PALETTE",
    ORDER                 = "ORDER",
}