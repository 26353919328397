import * as React              from "react";
import { TableCell, TableRow } from "@material-ui/core";
import formatDate              from "../../../config/formatDate";
import Actions                 from "../Table/Actions";
import moment                  from "moment";
import User                    from "../../../Models/User/User";

interface ITableRowGalleryViewProps {
    user: User;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowUserView extends React.Component<ITableRowGalleryViewProps, {}> {

    public render(): React.ReactNode {
        const {
                  user,
                  onEdit,
                  onDelete,
              } = this.props;

        return (
            <TableRow>
                <TableCell>{user.getUsername()}</TableCell>
                <TableCell>{user.getEmail()}</TableCell>
                <TableCell>{user.getFirstName()}</TableCell>
                <TableCell>{user.getLastName()}</TableCell>
                <TableCell>{user.getActive()}</TableCell>
                <TableCell>{moment(user.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(user.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowUserView;
