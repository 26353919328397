import { withNamespaces, WithNamespaces } from "react-i18next";
import Row                                from "reactstrap/lib/Row";
import Col                                from "reactstrap/lib/Col";
import * as React                         from "react";
import InjectPrefix                       from "../../../../Decorators/InjectPrefix";
import { CronJobList }                    from "../../../../config/cronJobList";
import ActionItemViewModel                from "../../../../ViewModel/Action/Item/ActionItemViewModel";
import { Grocery }                        from "../../../../config/grocery";

interface IActionViewProps extends WithNamespaces {
    prefix?: string;
}

@InjectPrefix
class ActionView extends React.Component<IActionViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  t
              } = this.props;

        return (
            <>
                <h3 className="d-block mb-3">
                    {t("actions")}
                </h3>
                <Row className={"align-self-stretch"}>
                    <Col xs={12} md={3}>
                        <ActionItemViewModel
                            actionName={CronJobList.SyncProductsCron}
                            getActionName={`${CronJobList.SyncProductsCron}-${Grocery.MAIN}`}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <ActionItemViewModel
                            actionName={CronJobList.SyncProductsCron}
                            getActionName={`${CronJobList.SyncProductsCron}-${Grocery.SECONDARY}`}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default withNamespaces("page")(ActionView);
