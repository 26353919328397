import { inject, observer }               from "mobx-react";
import * as React                         from "react";
import ConfirmationModal                  from "../../../Views/Components/Modal/ConfirmationModal";
import ModalService                       from "../../../Service/ModalService";
import { Container }                      from "typedi";
import { ModalStore }                     from "../../../Store/ModalStore";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { MethodTransportStore }           from "../../../Store/MethodTransportStore";
import { MethodTransport }                from "../../../Models/MethodTransport/MethodTransport";

interface IDeleteMethodTransportStoreViewModelProps extends WithNamespaces {
    ModalStore?: ModalStore;
    MethodTransportStore?: MethodTransportStore;
    methodTransport: MethodTransport;
}

@inject(ModalStore.NAME_STORE, MethodTransportStore.NAME_STORE)
@observer
class DeleteMethodTransportViewModel extends React.Component<IDeleteMethodTransportStoreViewModelProps, {}> {
    private readonly modalService: ModalService;

    constructor(props: IDeleteMethodTransportStoreViewModelProps) {
        super(props);

        this.modalService = Container.get(ModalService);
    }

    get methodTransportStore(): MethodTransportStore {
        return this.props.MethodTransportStore as MethodTransportStore;
    }

    get modalStore(): ModalStore {
        return this.props.ModalStore as ModalStore;
    }

    public action = async () => {
        const {methodTransport} = this.props;

        this.methodTransportStore.setSpinner(true);
        await this.methodTransportStore.deleteById(methodTransport.getId());
        const data = await this.methodTransportStore.getPaginate();

        this.methodTransportStore
            .setPages(data.pages)
            .setMethodTransports(data.data)
            .setSpinner(false);

        this.modalService.closeModal();
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <ConfirmationModal
                handleClose={this.modalService.closeModal}
                open={this.modalStore.getModal().getOpenModal()}
                action={this.action}
                spinner={this.methodTransportStore.getSpinner()}
                title={t("confirm.content")}
                content={t("confirm.title")}
            />
        );
    }
}

export default withNamespaces("modal")(DeleteMethodTransportViewModel);
