import { observer } from "mobx-react";
import * as React   from "react";
import {
    LinearProgress,
    TextField,
}                   from "@material-ui/core";
import {
    WithNamespaces,
    withNamespaces
}                   from "react-i18next";
import {
    ArrowDownward as ArrowDownwardIcon,
    ArrowUpward as ArrowUpwardIcon
}                   from "@material-ui/icons";
import {
    ChromePicker,
    CirclePicker,
    ColorResult
}                   from "react-color";
import {
    List,
    ListRowProps,
}                   from "react-virtualized";

import { ColorPalette } from "../../../Models/Color/ColorPalette";
import InjectPrefix     from "../../../Decorators/InjectPrefix";
import { Color }        from "../../../Models/Color/Color";
import { Button }       from "../Button/Button";
import {
    Col,
    Row
}                       from "reactstrap";

interface IColorsCollectionViewProps extends WithNamespaces {
    colorPalette: ColorPalette;
    prefix?: string;
}

@InjectPrefix
@observer
class ColorsCollectionView extends React.Component<IColorsCollectionViewProps, {}> {
    protected refList: any;

    protected get prefix(): string {
        return this.props.prefix as string;
    }

    protected getButtons = (colorPalette: ColorPalette, index: number) => {
        const buttons: React.ReactNode[] = [
            <Button
                key={"delete"}
                variant={"outlined"}
                color={"primary"}
                colorSpinner={"primary"}
                size={"medium"}
                text={"Eliminar"}
                onClick={() => colorPalette.removeColor(index)}
                spinner={false}
            />,
        ];

        if (index !== colorPalette.getColors().length) {
            buttons.push(
                <Button
                    key={"up"}
                    variant={"outlined"}
                    color={"primary"}
                    colorSpinner={"primary"}
                    size={"medium"}
                    text={<ArrowDownwardIcon/>}
                    onClick={() => {
                        colorPalette.reorderColor(index, index + 1);
                        this.forceRenderList();
                    }}
                    spinner={false}
                />
            );
        }

        if (index !== 0) {
            buttons.push(
                <Button
                    key={"down"}
                    variant={"outlined"}
                    color={"primary"}
                    colorSpinner={"primary"}
                    size={"medium"}
                    text={<ArrowUpwardIcon/>}
                    onClick={() => {
                        colorPalette.reorderColor(index, index - 1);
                        this.forceRenderList();
                    }}
                    spinner={false}
                />
            );
        }

        return buttons;
    }

    protected forceRenderList = () => {
        if (this.refList) this.refList.forceUpdateGrid();
    }

    protected rowRenderer = ({
                                 key, // Unique key within array of rows
                                 index, // Index of row within collection
                                 isScrolling, // The List is currently being scrolled
                                 isVisible, // This row is visible within the List (eg it is not an overscanned row)
                                 style, // Style object to be applied to row (to position it)
                             }: ListRowProps) => {
        const {colorPalette, t} = this.props,
              color          = colorPalette.getColors()[index];

        return (
            <Row key={key} style={style} className={key}>
                {isScrolling
                    ? <LinearProgress className="mt-4 mb-4"/>
                    : <>
                        <Col className="border">
                            <TextField
                                fullWidth
                                className={""}
                                value={color.getCode()}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    color.setCode(evt.currentTarget.value);
                                    this.forceRenderList();

                                }}
                                margin="normal"
                                type="text"
                                label={t("edit.name")}
                            />
                        </Col>
                        <Col className="border">
                            <TextField
                                fullWidth
                                className={""}
                                value={color.getName()}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                    color.setName(evt.currentTarget.value);
                                    this.forceRenderList();

                                }}
                                margin="normal"
                                type="text"
                                label={t("edit.code")}
                            />
                        </Col>
                        <Col className="border pt-3">
                            <CirclePicker
                                colors={[color.getRgb() || "#fff"]}
                            />
                        </Col>
                        <Col className="border pt-3">
                            <ChromePicker
                                disableAlpha
                                color={color.getRgb() || "#fff"}
                                onChange={(colorResult: ColorResult) => {
                                    color.setRgb(colorResult.hex);
                                    this.forceRenderList();
                                }}
                            />
                        </Col>
                        <Col align="right" className="border pt-3">
                            {this.getButtons(colorPalette, index)}
                        </Col>
                    </>
                }
            </Row>
        );
    }

    public render(): React.ReactNode {
        const {
                  t,
                  colorPalette
              } = this.props;

        return (
            <section>
                <Button
                    variant={"outlined"}
                    color={"primary"}
                    colorSpinner={"primary"}
                    size={"medium"}
                    text={t("car:add")}
                    spinner={false}
                    onClick={() => colorPalette.addColor(new Color())}
                />
                <List
                    ref={ref => this.refList = ref}
                    width={300}
                    height={900}
                    rowCount={colorPalette.getColors().length}
                    rowHeight={300}
                    rowRenderer={this.rowRenderer}
                    className={`${this.prefix}-react-virtualize-list`}
                />
            </section>
        );
    }
}

export default withNamespaces("page")(ColorsCollectionView);