import BaseStore                               from "./Base/BaseStore";
import { deserialize, serialize }              from "serializr";
import { Container }                           from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { observable }                          from "mobx";
import { globalPagerOptions, IPaginationData } from "../config/pagination";
import { Category }                            from "../Models/Product/Category";

export class CategoryStore extends BaseStore {
    public static readonly NAME_STORE: string = "CategoryStore";

    @observable
    private categorys: Category[] = [];

    @observable
    private category: Category;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getCategory(): Category {
        return this.category;
    }

    public setCategory(value: Category) {
        this.category = value;
    }

    public getCategories(): Category[] {
        return this.categorys;
    }

    public setCategories(value: Category[]) {
        this.categorys = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<Category>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateCategories(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(Category, item)),
            pages: dataResponse.pages
        } as IPaginationData<Category>;
    }

    public async getById(id: string): Promise<Category> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getCategoryId(id);

        return deserialize(Category, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deleteCategoryId(id);

        return success;
    }

    public async save(category: Category): Promise<Category> {
        const data     = serialize(category);

        const {data: {data: dataResponse}} = await this.getAjaxService().saveCategory(data);

        return deserialize(Category, dataResponse);
    }

    public async getAllSubCategories(): Promise<Category[]  > {
        const {data: {data: dataResponse}} = await this.getAjaxService().getAllSubCategories();

        return dataResponse.map((item: any) => deserialize(Category, item));
    }

    public async getAllCategories(): Promise<Category[]  > {
        const {data: {data: dataResponse}} = await this.getAjaxService().getAllCategories();

        return dataResponse.map((item: any) => deserialize(Category, item));
    }
}
