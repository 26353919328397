import * as React                         from "react";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
}                                         from "@material-ui/core";
import { withNamespaces, WithNamespaces } from "react-i18next";
import AddIcon                            from "@material-ui/icons/Add";
import InjectPrefix                       from "../../../../Decorators/InjectPrefix";
import { Button }                         from "../../../Components/Button/Button";
import { Page }                           from "../../../../Models/Page/Page";
import Pagination                         from "../../../Components/Pagination/Pagination";
import TableRowPageViewModel              from "../../../../ViewModel/PageSeo/View/TableRowPageViewModel";

interface IPageViewProps extends WithNamespaces {
    pagesSeo: Page[];
    spinner: boolean;
    add: () => void;
    prefix?: string;
    pages: number;
    currentPage: number;
    getPage: (page: number) => void;
}

@InjectPrefix
class PageView extends React.Component<IPageViewProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    protected renderBody = (): React.ReactNode => {
        const {pagesSeo} = this.props;

        return pagesSeo.map((page: Page, key: number) => (
            <TableRowPageViewModel
                page={page}
                key={key}
            />
        ));
    };

    protected renderPagination = (): React.ReactNode => {
        const {
                  spinner,
                  pages,
                  currentPage,
                  getPage,
              } = this.props;

        return <Pagination
            className="mt-4"
            pages={pages}
            currentPage={currentPage}
            getPage={!spinner ? getPage : () => {}}
        />;
    };

    public render(): React.ReactNode {
        const {
                  spinner,
                  t,
                  add
              }            = this.props,
              propsButtons = {
                  color  : "primary" as "primary",
                  onClick: add,
                  size   : "small" as "small",
                  spinner: false,
                  variant: "contained" as "contained"
              };

        return (
            <>
                <h3>
                    {t("SEO")}
                </h3>
                <div className="d-flex align-items-end flex-column mb-3">
                    <Button {...propsButtons}>
                        <AddIcon/> {t("edit.create")}
                    </Button>
                </div>
                <Table className={`${this.prefix}-table-info`}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("edit.firstName")}</TableCell>
                            <TableCell>{t("edit.createdAt")}</TableCell>
                            <TableCell>{t("edit.updatedAt")}</TableCell>
                            <TableCell align="right">{t("edit.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderBody()}
                    </TableBody>
                </Table>
                {this.renderPagination()}
                {
                    spinner &&
                    <LinearProgress className="mt-4 mb-4"/>
                }
            </>
        );
    }
}

export default withNamespaces("page")(PageView);
