import { inject, observer }               from "mobx-react";
import * as React                         from "react";
import ConfirmationModal                  from "../../../Views/Components/Modal/ConfirmationModal";
import ModalService                       from "../../../Service/ModalService";
import { Container }                      from "typedi";
import { ModalStore }                     from "../../../Store/ModalStore";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { PageStore }                  from "../../../Store/PageStore";
import { Page }                       from "../../../Models/Page/Page";

interface IDeletePageViewModelProps extends WithNamespaces {
    ModalStore?: ModalStore;
    PageStore?: PageStore;
    page: Page;
}

@inject(ModalStore.NAME_STORE, PageStore.NAME_STORE)
@observer
class DeletePageViewModel extends React.Component<IDeletePageViewModelProps, {}> {
    private readonly modalService: ModalService;

    constructor(props: IDeletePageViewModelProps) {
        super(props);

        this.modalService = Container.get(ModalService);
    }

    get pageStore(): PageStore {
        return this.props.PageStore as PageStore;
    }

    get modalStore(): ModalStore {
        return this.props.ModalStore as ModalStore;
    }

    public action = async () => {
        const {page} = this.props;

        this.pageStore.setSpinner(true);
        await this.pageStore.deleteById(page.getId());
        const data = await this.pageStore.getPaginate();

        this.pageStore
            .setPages(data.pages)
            .setPagesSeo(data.data)
            .setSpinner(false);

        this.modalService.closeModal();
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <ConfirmationModal
                handleClose={this.modalService.closeModal}
                open={this.modalStore.getModal().getOpenModal()}
                action={this.action}
                spinner={this.pageStore.getSpinner()}
                title={t("confirm.content")}
                content={t("confirm.title")}
            />
        );
    }
}

export default withNamespaces("modal")(DeletePageViewModel);
