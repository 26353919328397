import * as React             from "react";
import { inject, observer }   from "mobx-react";
import { observable }         from "mobx";
import Header                 from "../../Views/Components/Header/Header";
import { ConfigurationStore } from "../../Store/ConfigurationStore";
import { UserStore }          from "../../Store/UserStore";
import { TokenStore }         from "../../Store/TokenStore";

@inject(ConfigurationStore.NAME_STORE, UserStore.NAME_STORE, TokenStore.NAME_STORE)
@observer
class HeaderViewModel extends React.Component<{
    ConfigurationStore?: ConfigurationStore;
    UserStore?: UserStore;
    TokenStore?: TokenStore;
}, {}> {
    public getOpenMenuLeft = (): boolean => {
        return this.configurationStore.getOpenMenuLeft();
    };

    public setOpenMenuLeft = (value: boolean) => {
        this.configurationStore.setOpenMenuLeft(value);
    };

    get show(): boolean {
        return this.configurationStore.getConfiguration().getShowHeader();
    }

    get configurationStore(): ConfigurationStore {
        return this.props.ConfigurationStore as ConfigurationStore;
    }

   get tokenStore(): TokenStore {
        return this.props.TokenStore as TokenStore;
    }

    get userStore(): UserStore {
        return this.props.UserStore as UserStore;
    }

    /**
     * render
     */
    public render(): React.ReactNode {
        return (
            this.show ?
            <Header
                openMenuLeft={this.getOpenMenuLeft()}
                setOpenMenuLeft={this.setOpenMenuLeft}
                user={this.userStore.getUser()}
                currentPage={this.configurationStore.getCurrentPage()}
                getAnchorEl={this.configurationStore.getAnchorEl}
                setAnchorEl={this.configurationStore.setAnchorEl}
                logout={() => {
                    this.tokenStore.logout();
                }}
            />
                      : <></>
        );
    }
}

export default HeaderViewModel;
