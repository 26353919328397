import * as React                          from "react";
import GalleryView                         from "../../../Views/Pages/Gallery/View/GalleryView";
import { inject, observer }                from "mobx-react";
import { GalleryStore }                    from "../../../Store/GalleryStore";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import PromotionView                       from "../../../Views/Pages/Promotion/View/PromotionView";

interface IGalleryViewModelProps extends RouteComponentProps<any> {
    GalleryStore?: GalleryStore;
}

@inject(GalleryStore.NAME_STORE)
@observer
class GalleryViewModel extends React.Component<IGalleryViewModelProps, {}> {

    get galleryStore(): GalleryStore {
        return this.props.GalleryStore as GalleryStore;
    }

    public componentDidMount = (): void => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.galleryStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.galleryStore.getPaginate();

        this.galleryStore
            .setPages(response.pages)
            .setGalleries(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;
        return (
            <GalleryView
                galleries={this.galleryStore.getGalleries()}
                spinner={this.galleryStore.getSpinner()}
                addGallery={() => history.push(renderRoute(PageNames.galleryAddPage))}
                getPage={this.getPage}
                pages={this.galleryStore.getPages()}
                currentPage={this.galleryStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(GalleryViewModel);
