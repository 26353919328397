import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import DeleteMethodTransportViewModel      from "../Delete/DeleteMethodTransportViewModel";
import { MethodTransport }                 from "../../../Models/MethodTransport/MethodTransport";
import TableRowMethodTransportView
                                           from "../../../Views/Components/TableRowMethodTransport/TableRowMethodTransportView";

interface ITableRowMethodTransportViewModelProps extends RouteComponentProps<any> {
    methodTransport: MethodTransport;
}

class TableRowMethodTransportViewModel extends React.Component<ITableRowMethodTransportViewModelProps, {}> {
    protected onEdit = () => {
        const {history, methodTransport} = this.props;
        history.push(renderRoute(PageNames.methodTransportEditPage, {id: methodTransport.getId()}));
    };

    protected onDelete = () => {
        const {methodTransport} = this.props;
        Container.get(ModalService).openModal(DeleteMethodTransportViewModel, {
            methodTransport
        });
    };

    public render(): React.ReactNode {
        const {methodTransport} = this.props;

        return (
            <TableRowMethodTransportView
                methodTransport={methodTransport}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowMethodTransportViewModel);
