import * as React                          from "react";
import InjectPrefix                        from "../../../Decorators/InjectPrefix";
import { Button as ButtonBase, PropTypes } from "@material-ui/core";
import CircularProgress                    from "@material-ui/core/CircularProgress";

interface IButtonProps {
    variant: "text" | "outlined" | "contained";
    colorSpinner?: "primary" | "secondary" | "inherit";
    color: PropTypes.Color;
    size: "small" | "medium" | "large";
    className?: string;
    text?: string | React.ReactNode;
    children?: React.ReactNode;
    onClick: () => void;
    spinner: boolean;
    disable?: boolean;
}

@InjectPrefix
export class Button extends React.Component<IButtonProps, {}> {
    public render(): React.ReactNode {
        const {
                  variant,
                  color,
                  colorSpinner = "primary",
                  size,
                  onClick,
                  text,
                  children,
                  disable,
                  spinner
              }         = this.props,
              className = `${this.props.className || ""} ${spinner || disable ? "disabled" : ""}`;

        return (
            <ButtonBase
                variant={variant}
                color={color}
                size={size}
                className={className}
                disabled={spinner || disable}
                onClick={() => onClick()}
            >
                {spinner ? <CircularProgress color={colorSpinner}/> : (text || children)}
            </ButtonBase>
        );
    }
}
