import * as React              from "react";
import { TableCell, TableRow } from "@material-ui/core";
import formatDate              from "../../../config/formatDate";
import Actions                 from "../Table/Actions";
import moment                  from "moment";
import { MethodTransport }     from "../../../Models/MethodTransport/MethodTransport";

interface ITableRowCategoryViewProps {
    methodTransport: MethodTransport;
    onEdit: () => void;
    onDelete: () => void;
}

class TableRowMethodTransportView extends React.Component<ITableRowCategoryViewProps, {}> {

    protected renderPrice = (methodTransport: MethodTransport): React.ReactNode [] => {
        return methodTransport.getPriceByKmRange().map(item => {
            return (
                <li key={item.getUuid()}>
                    <p>Init: {item.getInitKm()}</p>
                    <p>End: {item.getEndKm()}</p>
                    <p>Price: {item.getPriceByKm()}</p>
                </li>
            );
        });
    };

    public render(): React.ReactNode {
        const {
                  methodTransport,
                  onEdit,
                  onDelete,
              } = this.props;

        return (
            <TableRow>
                <TableCell>{`${methodTransport.getName()} (${methodTransport.getMinWeight()} - ${methodTransport.getMaxWeight()})Kg`}</TableCell>
                <TableCell>{methodTransport.getActive() ? "Si" : "No"}</TableCell>
                {/*<TableCell>*/}
                {/*    <ul className="list-unstyled">*/}
                {/*        {this.renderPrice(methodTransport)}*/}
                {/*    </ul>*/}
                {/*</TableCell>*/}
                <TableCell>{moment(methodTransport.getCreatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell>{moment(methodTransport.getUpdatedAt()).format(formatDate.fullDate)}</TableCell>
                <TableCell align="right">
                    <Actions
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default TableRowMethodTransportView;
