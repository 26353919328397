import * as React               from "react";
import { IconButton } from "@material-ui/core";
import { AccountCircle }        from "@material-ui/icons";

interface IMenuUserViewProps {
    getAnchorEl: () => HTMLElement | undefined;
    setAnchorEl: (anchorEl: HTMLElement | undefined) => void;
}

class MenuUserView extends React.Component<IMenuUserViewProps, {}> {
    /**
     * render
     */
    public render(): React.ReactNode {

        const {
                  setAnchorEl,
              } = this.props;
        return (
            <div>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
                    color="primary"
                >
                    <AccountCircle/>
                </IconButton>
            </div>
        );
    }
}

export default MenuUserView;
