import InjectPrefix  from "../../../../Decorators/InjectPrefix";
import * as React    from "react";
import ImageUploader from "react-images-upload";


interface IUploadImageProps {
    prefix?: string;
    onDrop: (files: File[], picturesBase64: string[]) => void;
}

@InjectPrefix
class UploadImage extends React.Component<IUploadImageProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    private onDrop = (files: File[], picturesBase64: string[]) => {
        this.props.onDrop(files, picturesBase64);
    };

    public render(): React.ReactNode {
        return (
            <div className={`${this.prefix}-form-upload`}>
                <ImageUploader
                    withIcon={false}
                    buttonText="Choose image"
                    onChange={this.onDrop as (files: File[]) => void}
                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                />
            </div>
        );
    }
}

export default UploadImage;
