import { inject, observer }               from "mobx-react";
import * as React                         from "react";
import ConfirmationModal                  from "../../../Views/Components/Modal/ConfirmationModal";
import ModalService                       from "../../../Service/ModalService";
import { Container }                      from "typedi";
import { ModalStore }                     from "../../../Store/ModalStore";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { RecommendationPersonStore }      from "../../../Store/RecommendationPersonStore";
import { RecommendationPerson }           from "../../../Models/RecomendationPerson/RecommendationPerson";

interface IDeleteRecommendationPersonViewModelProps extends WithNamespaces {
    ModalStore?: ModalStore;
    RecommendationPersonStore?: RecommendationPersonStore;
    recommendationPerson: RecommendationPerson;
}

@inject(ModalStore.NAME_STORE, RecommendationPersonStore.NAME_STORE)
@observer
class DeleteRecommendationPersonViewModel extends React.Component<IDeleteRecommendationPersonViewModelProps, {}> {
    private readonly modalService: ModalService;

    constructor(props: IDeleteRecommendationPersonViewModelProps) {
        super(props);

        this.modalService = Container.get(ModalService);
    }

    get recommendationPersonStore(): RecommendationPersonStore {
        return this.props.RecommendationPersonStore as RecommendationPersonStore;
    }

    get modalStore(): ModalStore {
        return this.props.ModalStore as ModalStore;
    }

    public action = async () => {
        const {recommendationPerson} = this.props;

        this.recommendationPersonStore.setSpinner(true);
        await this.recommendationPersonStore.deleteById(recommendationPerson.getId());
        const data = await this.recommendationPersonStore.getPaginate();

        this.recommendationPersonStore
            .setPages(data.pages)
            .setRecommendationPersons(data.data)
            .setSpinner(false);

        this.modalService.closeModal();
    };

    public render(): React.ReactNode {
        const {t} = this.props;

        return (
            <ConfirmationModal
                handleClose={this.modalService.closeModal}
                open={this.modalStore.getModal().getOpenModal()}
                action={this.action}
                spinner={this.recommendationPersonStore.getSpinner()}
                title={t("confirm.content")}
                content={t("confirm.title")}
            />
        );
    }
}

export default withNamespaces("modal")(DeleteRecommendationPersonViewModel);
