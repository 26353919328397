import * as React           from "react";
import { Order }            from "../../../Models/Order/Order";
import { TextField }        from "@material-ui/core";
import {
    WithNamespaces,
    withNamespaces
}                           from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import { Button }           from "../Button/Button";
import EmailIcon            from "@material-ui/icons/Send";
import {
    Col,
    Row
}                           from "reactstrap";
import { observer }         from "mobx-react";

interface ISendEmailViewProps extends WithNamespaces {
    order: Order;
    email: string;
    setEmail: (v: string) => void;
    sendEmail: () => void;
    validator: SimpleReactValidator;
}

@observer
class SendEmailView extends React.Component<ISendEmailViewProps, {}> {
    public render(): React.ReactNode {
        const {
                  order,
                  t,
                  email,
                  sendEmail,
                  setEmail,
                  validator
              } = this.props;

        return (
            <Row>
                <Col xs={6}>
                    <TextField
                        fullWidth
                        label={t("edit.email")}
                        className={""}
                        value={email}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(evt.currentTarget.value);
                        }}
                        margin="normal"
                    />
                    {validator.message(t("edit.email"), email, "required|email")}
                </Col>
                <Col xs={6}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        colorSpinner={"primary"}
                        size={"medium"}
                        text={<>Enviar <EmailIcon/></>}
                        onClick={() => {
                            sendEmail();
                        }}
                        spinner={false}
                    />
                </Col>
            </Row>
        );
    }
}

export default withNamespaces("page")(SendEmailView);

