import * as React                 from "react";
import { observable }             from "mobx";
import { inject, observer }       from "mobx-react";
import { withNamespaces }         from "react-i18next";
import { withRouter }             from "react-router";
import { LinearProgress }         from "@material-ui/core";
import { FormStore }              from "../../../Store/FormStore";
import { PageNames, renderRoute } from "../../../Routes/routes";
import { OccupationStore }        from "../../../Store/OccupationStore";
import { Occupation }             from "../../../Models/Occupation/Occupation";
import IBaseProps                 from "../../Props/IBaseProps";
import CreateOrEditOccupationView from "../../../Views/Pages/Occupation/CreateOrEdit/CreateOrEditOccupationView";

interface INewEditOccupationViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    OccupationStore?: OccupationStore;
}

@inject(FormStore.NAME_STORE, OccupationStore.NAME_STORE)
@observer
class CreateOrEditOccupationViewModel extends React.Component<INewEditOccupationViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    constructor(props: INewEditOccupationViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }
    }

    public componentDidMount = async (): Promise<void> => {
        const {id} = this.props.match.params;

        if (!id) {
            this.occupationStore.setOccupation(new Occupation());
            return;
        }

        const occupation = await this.occupationStore.getById(id);

        this.occupationStore.setOccupation(occupation);

        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get occupationStore(): OccupationStore {
        return this.props.OccupationStore as OccupationStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const occupation = await this.occupationStore.save(this.occupationStore.getOccupation()),
              {history}  = this.props;

        history.push(renderRoute(PageNames.occupationPage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.occupationPage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    public render(): React.ReactNode {
        if (this.loading || !this.occupationStore.getOccupation())
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (!this.occupationStore.getOccupation())
            return (<React.Fragment/>);

        return (
            <CreateOrEditOccupationView
                currentTab={this.getCurrentTab()}
                setCurrentTab={this.setCurrentTab}
                currentTabSeo={this.formStore.getCurrentTabSeo()}
                setCurrentTabSeo={this.formStore.setCurrentTabSeo}
                occupation={this.occupationStore.getOccupation()}
                validate={this.validate}
                spinner={this.getSpinner()}
                save={this.save}
                validator={this.formStore.getValidator()}
                cancel={this.cancel}
            />
        );
    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditOccupationViewModel));
