import { observable }                                 from "mobx";
import { persist }                                    from "mobx-persist";
import User                                           from "../Models/User/User";
import BaseStore                                      from "./Base/BaseStore";
import AjaxService                                    from "../Service/AjaxService";
import Container                                      from "typedi";
import { date, deserialize, serializable, serialize } from "serializr";
import { globalPagerOptions, IPaginationData }        from "../config/pagination";
import Role                                           from "../Models/User/Role";

const moment = require("moment");

export class UserStore extends BaseStore {
    public static readonly NAME_STORE: string = "UserStore";

    @persist("object", User)
    @observable
    private user: User | undefined;

    @observable
    private userCMS: User;

    @observable
    private userCMSList: User[] = [];

    @persist("object")
    @serializable(date())
    private previousCallAccess: Date;

    private callMe: boolean = false;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    protected init() {
        this.needPersistData = true;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    public getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public getPreviousAccess(): Date {
        return this.previousCallAccess;
    }

    public setPreviousAccess(currentDate: Date) {
        this.previousCallAccess = currentDate;
    }

    public setUser(user: User | undefined): this {
        this.user = user;

        return this;
    }

    public getUser(): User | undefined {
        const now = moment().add(2, "h");

        // if (!this.getPreviousAccess() || now.isBefore(moment(this.getPreviousAccess()))) this.getUserMeApi();

        return this.user;
    }

    public setUserCMS(user: User): this {
        this.userCMS = user;

        return this;
    }

    public getUserCMS(): User {
        return this.userCMS;
    }

    public setUserCMSList(user: User[]): this {
        this.userCMSList = user;

        return this;
    }

    public getUserCMSList(): User[] {
        return this.userCMSList;
    }

    public async getUserMeApi(): Promise<User> {
        const response   = await this.getAjaxService().getMe(),
              user: User = deserialize(User, response.data.data);

        this.setUser(user)
            .setPreviousAccess(moment());

        return user;
    }

    public async getPaginate(): Promise<IPaginationData<User>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateUsers(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(User, item)),
            pages: dataResponse.pages
        } as IPaginationData<User>;
    }

    public async getById(id: string): Promise<User> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getUserId(id);

        return deserialize(User, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deleteUserId(id);

        return success;
    }

    public async save(user: User): Promise<User> {
        let dataResponse;

        if (user.getId())
            dataResponse = (await this.getAjaxService().updateUser(serialize(user), user.getId())).data.data;
        else
            dataResponse = (await this.getAjaxService().saveUser(serialize(user))).data.data;

        return deserialize(User, dataResponse);
    }

    /**
     * Get Roles
     */
    public async getRolesAssignable(): Promise<Role[]> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getRoles();

        return dataResponse.map((data: any) => deserialize(Role, data));
    }
}
