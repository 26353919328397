import * as React                    from "react";
import { observable }                from "mobx";
import { inject, observer }          from "mobx-react";
import { withNamespaces }            from "react-i18next";
import { withRouter }                from "react-router";
import { LinearProgress }            from "@material-ui/core";
import { FormStore }                 from "../../../Store/FormStore";
import { PageNames, renderRoute }    from "../../../Routes/routes";
import { RecommendationPersonStore } from "../../../Store/RecommendationPersonStore";
import { RecommendationPerson }      from "../../../Models/RecomendationPerson/RecommendationPerson";
import IBaseProps                    from "../../Props/IBaseProps";
import CreateOrEditRecommendationPersonView
                                     from "../../../Views/Pages/RecommendationPerson/CreateOrEdit/CreateOrEditRecommendationPersonView";
import { OccupationStore }           from "../../../Store/OccupationStore";
import { Occupation }                from "../../../Models/Occupation/Occupation";
import { contains }                  from "../../../Utils/array";

interface INewEditRecommendationPersonViewModelProps extends IBaseProps {
    FormStore?: FormStore;
    RecommendationPersonStore?: RecommendationPersonStore;
    OccupationStore?: OccupationStore;
}

@inject(FormStore.NAME_STORE, RecommendationPersonStore.NAME_STORE, OccupationStore.NAME_STORE)
@observer
class CreateOrEditRecommendationPersonViewModel extends React.Component<INewEditRecommendationPersonViewModelProps, {}> {
    @observable
    private currentTab: number = 0;

    @observable
    private spinner: boolean = false;

    @observable
    private loading: boolean = false;

    @observable
    private selectOccupations: Map<string, Occupation> = new Map<string, Occupation>();

    constructor(props: INewEditRecommendationPersonViewModelProps) {
        super(props);

        this.formStore.initValidator(props.t);

        const {id} = this.props.match.params;

        if (id) {
            this.loading = true;
            return;
        }
    }

    public componentDidMount = async (): Promise<void> => {
        const {id}     = this.props.match.params;
        const response = await this.occupationStore.setCurrentPage(1).getPaginate();

        this.occupationStore
            .setPages(response.pages)
            .setOccupations(response.data);

        if (!id) {
            this.recommendationPersonStore.setRecommendationPerson(new RecommendationPerson());
            return;
        }

        const recommendationPerson = await this.recommendationPersonStore.getById(id);

        this.recommendationPersonStore.setRecommendationPerson(recommendationPerson);
        this.recommendationPersonStore.getRecommendationPerson().getOccupations().forEach((item: string) => {
            // @ts-ignore
            const occupation = contains(response.data, {_id: item}, "_id" as keyof Occupation);

            if (occupation)
                this.selectOccupations.set(item, occupation);
        });

        this.loading = false;
    };

    get formStore(): FormStore {
        return this.props.FormStore as FormStore;
    }

    get occupationStore(): OccupationStore {
        return this.props.OccupationStore as OccupationStore;
    }

    get recommendationPersonStore(): RecommendationPersonStore {
        return this.props.RecommendationPersonStore as RecommendationPersonStore;
    }

    protected getCurrentTab = (): number => {
        return this.currentTab;
    };

    protected setCurrentTab = (value: number) => {
        this.currentTab = value;
    };

    protected save = async () => {
        this.setSpinner(true);
        const recommendationPerson = await this.recommendationPersonStore.save(this.recommendationPersonStore.getRecommendationPerson()),
              {history}            = this.props;

        history.push(renderRoute(PageNames.recommendationPersonPage));
    };

    public setSpinner = (spinner: boolean) => {
        this.spinner = spinner;
    };

    public getSpinner = (): boolean => {
        return this.spinner;
    };

    public cancel = () => {
        const {history} = this.props;

        history.push(renderRoute(PageNames.recommendationPersonPage));
    };

    protected validate = () => {
        return this.formStore.actionValidate(this);
    };

    protected onChangeOccupation = (item: Occupation) => {
        if (!this.selectOccupations.has(item.getId()))
            this.selectOccupations.set(item.getId(), item);
        else
            this.selectOccupations.delete(item.getId());

        this.recommendationPersonStore.getRecommendationPerson().setOccupations(
            Array.from(this.selectOccupations.values()).map((item: Occupation) => item.getId())
        );
    };

    public render(): React.ReactNode {
        if (this.loading || !this.recommendationPersonStore.getRecommendationPerson())
            return (<LinearProgress className="mt-4 mb-4"/>);

        if (!this.recommendationPersonStore.getRecommendationPerson())
            return (<React.Fragment/>);

        return (
            <CreateOrEditRecommendationPersonView
                currentTab={this.getCurrentTab()}
                setCurrentTab={this.setCurrentTab}
                currentTabSeo={this.formStore.getCurrentTabSeo()}
                setCurrentTabSeo={this.formStore.setCurrentTabSeo}
                recommendationPerson={this.recommendationPersonStore.getRecommendationPerson()}
                validate={this.validate}
                spinner={this.getSpinner()}
                save={this.save}
                validator={this.formStore.getValidator()}
                cancel={this.cancel}
                occupations={this.occupationStore.getOccupations()}
                selectOccupations={this.selectOccupations}
                onChangeOccupation={this.onChangeOccupation}
            />
        );
    }
}

export default withNamespaces("forms")(withRouter(CreateOrEditRecommendationPersonViewModel));
