export const body = document.getElementsByTagName("body")[0];

export const scrollToElement = (element: HTMLElement, duration: number, distance: number = 50) => {
    if (!window) return;

    const startingY = window.pageYOffset,
          elementY  = (element) ? startingY + element.getBoundingClientRect().top : 0,
          targetY   = document.body.scrollHeight - elementY < window.innerHeight ? body.scrollHeight - window.innerHeight : elementY;

    const diffY  = targetY - startingY - distance,
          easing = (t: any) => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

    let start: number;

    if (!diffY) return;

    const step = (timestamp: number) => {
        if (!start) start = timestamp;

        const time = timestamp - start;

        let percent = Math.min(time / duration, 1);

        percent = easing(percent);

        window.scrollTo(0, startingY + diffY * percent);

        if (time < duration) window.requestAnimationFrame(step);
    };

    window.requestAnimationFrame(step);
};


export const createFormData = (object: Object, form?: FormData, namespace?: string): FormData => {
    const formData = form || new FormData();

    for (const property in object) {
        if (!object.hasOwnProperty(property) || !object[property]) continue;

        const formKey = namespace ? `${namespace}[${property}]` : property;

        if (object[property] instanceof Date) {
            formData.append(formKey, object[property].toISOString());
        } else if (typeof object[property] === "object" && !(object[property] instanceof File)) {
            createFormData(object[property], formData, formKey);
        } else {
            formData.append(formKey, object[property]);
        }
    }
    return formData;
};

export const matchTextInString = (text: string, query: string): number[] => {
    const whitespacesRegex      = /\s+/,
          specialCharsRegex     = /[.*+?^${}()|[\]\\]/g,
          escapeRegexCharacters = (str: string) => {
              return str.replace(specialCharsRegex, "\\$&");
          };

    return (
        query
            .trim()
            .split(whitespacesRegex)
            // If query is blank, we'll get empty string here, so let's filter it out.
            .filter((word: any) => {
                return word.length > 0;
            })
            .reduce((result: any, word: any) => {
                const wordLen = word.length,
                      regex   = new RegExp(escapeRegexCharacters(word), "i"),
                      match   = text.match(regex);

                let index: number | undefined;

                if (match) index = match.index;
                else index = -1;

                if (index && index > -1) {
                    result.push([index, index + wordLen]);

                    // Replace what we just found with spaces so we don't find it again.
                    text =
                        text.slice(0, index) +
                        new Array(wordLen + 1).join(" ") +
                        text.slice(index + wordLen);
                }

                return result;
            }, [])
            .sort((match1: any, match2: any) => {
                return match1[0] - match2[0];
            })
    );
};

export const validateNumber = (val: number): number | string => {
    return val === 0 || val ? val : "";
};