import * as React       from "react";
import InjectPrefix     from "../../../Decorators/InjectPrefix";
import { Button }       from "../Button/Button";

interface IGroupButtonsProps {
    isEdit?: boolean;
    action: () => void;
    cancel: () => void;
    spinner: boolean;
    validate: () => boolean;
    prefix?: string;
}

@InjectPrefix
export class GroupButtons extends React.Component<IGroupButtonsProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    public render(): React.ReactNode {
        const {
                  isEdit,
                  action,
                  validate,
                  cancel,
                  spinner
              } = this.props;

        return (
            <div className={`${this.prefix}-group-buttons`}>
                <Button
                    variant={"outlined"}
                    color={"secondary"}
                    colorSpinner={"secondary"}
                    size={"medium"}
                    text={"Cancel"}
                    onClick={cancel}
                    spinner={spinner}
                />
                <Button
                    variant={"contained"}
                    color={"primary"}
                    colorSpinner={"primary"}
                    size={"medium"}
                    text={isEdit ? "Edit" : "Save"}
                    onClick={() => {
                        if (validate()) action();
                    }}
                    spinner={spinner}
                />
            </div>
        );
    }
}
