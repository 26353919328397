import { inject, observer }            from "mobx-react";
import * as React                      from "react";
import ProductRelationshipView         from "../../../Views/Pages/Product/CreateOrEdit/ProductRelationshipView";
import { ProductRelationship }         from "../../../Models/Product/ProductRelationship";
import { Product }                     from "../../../Models/Product/Product";
import { observable }                  from "mobx";
import { ProductRelationshipInternal } from "../../../Models/Product/ProductRalationshipInternal";
import { ProductStore }                from "../../../Store/ProductStore";
import { LinearProgress }              from "@material-ui/core";

interface IProductRelationshipViewModelProps {
    productRelationship: ProductRelationship;
    product: Product;
    ProductStore?: ProductStore;
}

@inject(ProductStore.NAME_STORE)
@observer
class ProductRelationshipViewModel extends React.Component<IProductRelationshipViewModelProps, {}> {
    @observable
    private loading: boolean = true;

    @observable
    private productRelationshipInternal: ProductRelationshipInternal = new ProductRelationshipInternal();

    @observable
    private productSelected: Product;

    protected getProductSelected = (): Product => {
        return this.productSelected;
    };

    protected setProductSelected = (value: Product) => {
        this.productSelected = value;
    };

    protected get productStore(): ProductStore {
        return this.props.ProductStore as ProductStore;
    }

    protected getLoading = (): boolean => {
        return this.loading;
    };

    protected setLoading = (value: boolean) => {
        this.loading = value;
    };

    public constructor(props: IProductRelationshipViewModelProps) {
        super(props);
    }

    public componentDidMount = async () => {
        const {
                  productRelationship,
                  product,
              } = this.props;

        this.productRelationshipInternal.setProduct(product);

        for (const productId of productRelationship.getProductIds()) {
            const product = await this.productStore.getById(productId);

            if (!product) continue;

            this.productRelationshipInternal.addProductsRelationship(product);
        }

        this.loading = false;
    };

    protected addProductRelationShip = (product: Product) => {
        this.productRelationshipInternal.addProductsRelationship(product);
        this.setProductsIdsToProductRelationship();
        // @ts-ignore
        this.setProductSelected(undefined);
    };

    protected removeProductRelationShip = (product: Product) => {
        this.productRelationshipInternal.removeProductRelationShip(product);
        this.setProductsIdsToProductRelationship();
    };

    protected reorderProductRelationShip = (startIndex: number, endIndex: number) => {
        this.productRelationshipInternal.reorderProductRelationShip(startIndex, endIndex);
        this.setProductsIdsToProductRelationship();
    };

    protected setProductsIdsToProductRelationship() {
        const {productRelationship} = this.props;

        productRelationship.setProductIds(this.productRelationshipInternal.getProductsRelationship().map(item => item.getId()));
    }

    public render(): React.ReactNode {
        const {productRelationship} = this.props;

        if (this.loading)
            return (<LinearProgress className="mt-4 mb-4"/>);

        return (
            <ProductRelationshipView
                productRelationship={productRelationship}
                productRelationshipInternal={this.productRelationshipInternal}
                setProductSelected={this.setProductSelected}
                productSelected={this.productSelected}
                addProductRelationShip={this.addProductRelationShip}
                removeProductRelationShip={this.removeProductRelationShip}
                reorderProductRelationShip={this.reorderProductRelationShip}
            />
        );
    }
}

export default ProductRelationshipViewModel;