import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import { CategoryStore }                    from "../../../Store/CategoryStore";
import CategoryView                         from "../../../Views/Pages/Category/View/CategoryView";

interface ICategoryViewModelProps extends RouteComponentProps<any> {
    CategoryStore?: CategoryStore;
}

@inject(CategoryStore.NAME_STORE)
@observer
class CategoryViewModel extends React.Component<ICategoryViewModelProps, {}> {
    get categoryStore(): CategoryStore {
        return this.props.CategoryStore as CategoryStore;
    }

    public componentDidMount = async (): Promise<void> => {
        this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.categoryStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.categoryStore.getPaginate();

        this.categoryStore
            .setPages(response.pages)
            .setCategories(response.data)
            .setSpinner(false);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <CategoryView
                categories={this.categoryStore.getCategories()}
                spinner={this.categoryStore.getSpinner()}
                add={() => history.push(renderRoute(PageNames.categoryAddPage))}
                getPage={this.getPage}
                pages={this.categoryStore.getPages()}
                currentPage={this.categoryStore.getCurrentPage()}
            />
        );
    }
}

export default withRouter(CategoryViewModel);
