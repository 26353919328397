import * as React                         from "react";
import Tabs                               from "@material-ui/core/Tabs";
import Tab                                from "@material-ui/core/Tab";
import {
    Checkbox,
    FormControlLabel,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
}                                         from "@material-ui/core";
import { Row, Col }                       from "reactstrap";
import SimpleReactValidator               from "simple-react-validator";
import { TabPanel }                       from "../../Components/TabPanel/TabPanel";
import { Gallery }                        from "../../../Models/Gallery/Gallery";
import { GroupButtons }                   from "../../Components/GroupButtons/GroupButtons";
import GalleryImagesViewModel             from "../../../ViewModel/Gallery/CreateOrEdit/GalleryImagesViewModel";
import { WithNamespaces, withNamespaces } from "react-i18next";
import { galleryPages }                   from "../../../config/GalleryPages";
import { observer }                       from "mobx-react";

interface INewEditGalleryViewProps extends WithNamespaces {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    gallery: Gallery;
    validate: () => boolean;
    spinner: boolean;
    save: () => void;
    cancel: () => void;
    validator: SimpleReactValidator;
}

@observer
class NewEditGalleryView extends React.Component<INewEditGalleryViewProps, {}> {
    protected renderNameText = (): React.ReactNode => {
        const {gallery, validator, t} = this.props;
        return (
            <Row>
                <Col xs={12}>
                    <TextField
                        fullWidth
                        label={t("edit.name")}
                        className={""}
                        value={gallery.getName()}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            gallery.setName(evt.currentTarget.value);
                        }}
                        margin="normal"
                    />
                    {validator.message(t("edit.name"), gallery.getName(), "required")}
                </Col>
            </Row>
        );
    };

    protected renderNameSelect = (): React.ReactNode => {
        const {gallery, validator, t} = this.props;

        return (
            <Row>
                <Col xs={12}>
                    <FormControl fullWidth variant="outlined" className="mt-4">
                        <InputLabel htmlFor="name-gallery" variant="outlined">
                            {t("edit.name")}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            required
                            fullWidth
                            value={gallery.getName() || galleryPages.home}
                            onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                                gallery.setName(event.target.value as galleryPages);
                                // this.forceUpdate();
                            }}
                            inputProps={{
                                id  : "name-gallery",
                                name: "nameGallery",
                            }}
                        >
                            {
                                Object.keys(galleryPages).map((item: string) => {
                                    return (
                                        <MenuItem value={galleryPages[item]} key={item}>
                                            {t(galleryPages[item], {ns: "galleryPages"})}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                        {validator.message(t("edit.name"), gallery.getName(), "required")}
                    </FormControl>
                </Col>
            </Row>
        );
    };

    public render(): React.ReactNode {
        const {
                  currentTab,
                  setCurrentTab,
                  gallery,
                  spinner,
                  cancel,
                  validate,
                  save,
                  validator,
                  t
              } = this.props;

        return (
            <div className={``}>
                <form noValidate autoComplete="off">
                    <Tabs
                        value={currentTab}
                        onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                            setCurrentTab(newValue);
                        }} aria-label="tabs"
                    >
                        <Tab label="General"/>
                        <Tab label="Images"/>
                    </Tabs>
                    <hr/>
                    <TabPanel value={currentTab} index={0}>
                        <Row>
                            <Col xs={12} md={6} xl={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={gallery.getActive()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                gallery.setActive(event.currentTarget.checked);
                                            }}
                                            value={gallery.getActive()}
                                            color="primary"
                                        />
                                    }
                                    label={t("edit.active")}
                                />
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={gallery.getPage()}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                gallery.setPage(event.currentTarget.checked);
                                                // this.forceUpdate();
                                            }}
                                            value={gallery.getPage()}
                                            color="primary"
                                        />
                                    }
                                    label={t("edit.page")}
                                />
                            </Col>
                            {
                                gallery.getPage()
                                ? this.renderNameSelect()
                                : this.renderNameText()
                            }
                        </Row>
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <GalleryImagesViewModel
                            gallery={gallery}
                            validator={validator}
                        />
                    </TabPanel>
                    <hr/>
                    <GroupButtons
                        action={save}
                        spinner={spinner}
                        validate={validate}
                        cancel={cancel}
                    />
                </form>
            </div>
        );
    }
}

export default withNamespaces("page")(NewEditGalleryView);