import * as React                                          from "react";
import InjectPrefix                                        from "../../../Decorators/InjectPrefix";
import ReactAutoSuggest, { ChangeEvent, RenderSuggestion } from "react-autosuggest";

interface IAutoSuggestProps {
    className?: string;
    placeholder?: string;
    icon?: {
        className: string;
        component: React.ReactNode;
    };
    onChange: (event: React.FormEvent<any>, params: ChangeEvent) => void;
    onSuggestionsClear: () => void;
    onSuggestionFetch: (params: { value: any }) => void;
    getSuggestion: any;
    renderItem: RenderSuggestion<any>;
    value: string;
    showSuggestionsAlways?: boolean;
    suggestions: any;
    prefix?: string;
}

@InjectPrefix
class AutoSuggest extends React.Component<IAutoSuggestProps, {}> {
    get prefix(): string {
        return this.props.prefix as string;
    }

    /**
     * Render a custom component icon (example: <Search/> from Material UI)
     *
     * @private
     * @returns {React.ReactNode}
     * @memberof SearchAutoSuggest
     */
    protected renderIcon = (): React.ReactNode => {
        const {icon} = this.props;

        if (icon) {

            return <span className={`auto-suggest-icon ${icon.className || ""}`}>{icon.component}</span>;
        }

        return <></>;
    };

    public render(): React.ReactNode {
        const {
                  showSuggestionsAlways,
                  suggestions,
                  getSuggestion,
                  onSuggestionsClear,
                  onSuggestionFetch,
                  renderItem,
                  onChange,
                  placeholder = "",
                  value,
                  className = "",
              }                = this.props,
              inputSearchProps = {
                  onChange,
                  placeholder,
                  value,
              };

        return (
            <div className={`${this.prefix}-auto-suggest ${className}`}>
                <ReactAutoSuggest
                    alwaysRenderSuggestions={showSuggestionsAlways || false}
                    suggestions={suggestions}
                    inputProps={inputSearchProps}
                    getSuggestionValue={getSuggestion}
                    onSuggestionsClearRequested={onSuggestionsClear}
                    onSuggestionsFetchRequested={onSuggestionFetch}
                    renderSuggestion={renderItem}
                />
                {this.renderIcon()}
            </div>
        );
    }
}

export {
    AutoSuggest
};