import * as React             from "react";
import { Paper as BasePaper } from "@material-ui/core";
import { PaperProps }         from "@material-ui/core/Paper";

interface IPaperProps extends PaperProps {
    children: React.ReactNode;
}

// tslint:disable-next-line:variable-name
export const Paper: React.FunctionComponent<IPaperProps> = (props: IPaperProps) => {
    const {children, className = "", ...otherProps} = props;

    return (
        <BasePaper className={`${className} m-2 mt-4 mb-5 p-4 pt-2 pb-2`} {...otherProps}>
            {children}
        </BasePaper>
    );
};
