import { custom, list, primitive, serializable } from "serializr";
import { observable }                            from "mobx";
import { date }                                  from "../../Serializer/date";

export class ProductRelationship {
    @serializable
    @observable
    private _id: string;

    @serializable
    @observable
    private productId: string;

    @serializable(list(primitive()))
    @observable
    private productIds: string[] = [];

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private createdAt: Date;

    @serializable(custom(date.serializer, date.deserializer))
    @observable
    private updatedAt: Date;

    public getId(): string {
        return this._id;
    }

    public setId(value: string) {
        this._id = value;
    }

    public getProductId(): string {
        return this.productId;
    }

    public setProductId(value: string) {
        this.productId = value;
    }

    public getProductIds(): string[] {
        return this.productIds;
    }

    public setProductIds(value: string[]) {
        this.productIds = value;
    }

    public addProductId(value: string) {
        this.productIds.push(value);
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(value: Date) {
        this.createdAt = value;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public setUpdatedAt(value: Date) {
        this.updatedAt = value;
    }
}
