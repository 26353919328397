import * as React                          from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PageNames, renderRoute }          from "../../../Routes/routes";
import ModalService                        from "../../../Service/ModalService";
import { Container }                       from "typedi";
import TableRowRecommendationPersonView
                                           from "../../../Views/Components/TableRowRecommendationPerson/TableRowRecommendationPersonView";
import { RecommendationPerson }            from "../../../Models/RecomendationPerson/RecommendationPerson";
import DeleteRecommendationPersonViewModel from "../Delete/DeleteRecommendationPersonViewModel";

interface ITableRowRecommendationPersonViewModelProps extends RouteComponentProps<any> {
    recommendationPerson: RecommendationPerson;
}

class TableRowRecommendationPersonViewModel extends React.Component<ITableRowRecommendationPersonViewModelProps, {}> {
    protected onEdit = () => {
        const {recommendationPerson, history} = this.props;
        history.push(renderRoute(PageNames.recommendationPersonEditPage, {id: recommendationPerson.getId()}));
    };

    protected onDelete = () => {
        const {recommendationPerson} = this.props;
        Container.get(ModalService).openModal(DeleteRecommendationPersonViewModel, {
            recommendationPerson
        });
    };

    public render(): React.ReactNode {
        const {recommendationPerson} = this.props;

        return (
            <TableRowRecommendationPersonView
                recommendationPerson={recommendationPerson}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    }
}

export default withRouter(TableRowRecommendationPersonViewModel);
