import * as React                          from "react";
import { inject, observer }                from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import OrderView                           from "../../../Views/Pages/Order/View/OrderView";
import { OrderStore }                      from "../../../Store/OrderStore";
import { observable }                      from "mobx";
import { MethodTransport }                 from "../../../Models/MethodTransport/MethodTransport";
import { MethodTransportStore }            from "../../../Store/LoadStore/LoadStore";

interface IOrderViewModelProps extends RouteComponentProps<any> {
    OrderStore?: OrderStore;
    MethodTransportStore?: MethodTransportStore;
}

@inject(OrderStore.NAME_STORE, MethodTransportStore.NAME_STORE)
@observer
class OrderViewModel extends React.Component<IOrderViewModelProps, {}> {
    @observable
    private methodsTransport: MethodTransport[] = [];

    get orderStore(): OrderStore {
        return this.props.OrderStore as OrderStore;
    }

    get methodTransportStore(): MethodTransportStore {
        return this.props.MethodTransportStore as MethodTransportStore;
    }

    protected getMethodsTransport = (): MethodTransport[] => {
        return this.methodsTransport;
    };

    protected setMethodsTransport = (value: MethodTransport[]) => {
        this.methodsTransport = value;
    };

    public componentDidMount = async (): Promise<void> => {
        this.setMethodsTransport(await this.methodTransportStore.getActive());
        this.orderStore.getOrderFilter().setMethodsTransportId(this.getMethodsTransport().map(item => item.getId()));
        await this.getPage(1);
    };

    public getPage = async (page: number) => {
        this.orderStore
            .setSpinner(true)
            .setCurrentPage(page);

        const response = await this.orderStore.getPaginate();

        this.orderStore
            .setPages(response.pages)
            .setOrders(response.data)
            .setSpinner(false);
    };

    protected getFilter = () => {
        this.getPage(1);
    };

    public render(): React.ReactNode {
        const {history} = this.props;

        return (
            <OrderView
                orders={this.orderStore.getOrders()}
                spinner={this.orderStore.getSpinner()}
                getPage={this.getPage}
                pages={this.orderStore.getPages()}
                currentPage={this.orderStore.getCurrentPage()}
                orderFilter={this.orderStore.getOrderFilter()}
                getFilter={this.getFilter}
                methodsTransport={this.getMethodsTransport()}
            />
        );
    }
}

export default withRouter(OrderViewModel);
