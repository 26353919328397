import { PageNames }    from "../../Routes/routes";
import { galleryPages } from "../../config/GalleryPages";
import { pages }        from "../../config/Pages";
import { Roles }        from "../../config/security";
import { OrderStatus }  from "../../config/orderStatus";
import { MethodsPay }   from "../../config/MethodsPay";
import { CronJobList }  from "../../config/cronJobList";
import { Grocery }      from "../../config/grocery";

/**
 * Default Language
 */
const es = {
    actions       : {
        [CronJobList.SyncProductsCron]                          : "Sincronizar Productos",
        [`${CronJobList.SyncProductsCron}-${Grocery.MAIN}`]     : "Sincronizar Productos Principal",
        [`${CronJobList.SyncProductsCron}-${Grocery.SECONDARY}`]: "Sincronizar Productos Secundaria (Pinturas)",
        description                                             : {
            [CronJobList.SyncProductsCron]: "Ejecuta una sincronización manual de los productos. Normalmente se hace cada hora.",
        }
    },
    button        : {},
    car           : {
        "add"                 : "Agregar",
        "car-to-buy"          : "Carrito de Compra",
        "color"               : "Color",
        "continue"            : "Continuar",
        "direction"           : "Dirección",
        "empty-car"           : "Tu carrito esta vacio",
        "generate-order"      : "Generar Orden",
        "generated-order"     : "Orden Generada",
        "method-pay"          : "Método de pago",
        "method-transport"    : "Método de transporte",
        "need-bill-electronic": "Factura Electronica",
        "price"               : "Precio",
        "price-unity"         : "Precio unitario",
        "procced-to-pay"      : "Proceder a pagar",
        "producto"            : "Producto",
        "quantity"            : "Cantidad",
        "shipping"            : "Transporte",
        "subtotal"            : "SubTotal",
        "total"               : "Total",
    },
    cards         : {
        "show-more": "Ver más"
    },
    footer        : {
        "all-rights-reserved": "© {{year}} All rights reserved.",
        "back-to-top"        : "Volver a arriba",
        "content"            : "Somos una empresa dedicada a la venta de todo tipo de materiales para la construcción y ferretería, también contamos con servicio de copias de llaves.",
        "information"        : "Información"
    },
    forms         : {
        "bill-electronic-saved"    : "Información guardada.",
        "canton"                   : "Canton",
        "direction"                : "Dirección",
        "direction-saved"          : "Dirección Salavada",
        "dni"                      : "Identificación",
        "email"                    : "Email",
        "exact-direction"          : "Dirección Exacta",
        "field-required"           : "El :attribute es requerido.",
        "fullName"                 : "Nombre Completo",
        "invalid-credentials-login": "Credenciales Invalidas.",
        "lastName"                 : "Apellidos",
        "login"                    : "Login",
        "message"                  : "Mensaje",
        "name"                     : "Nombre",
        "password"                 : "Contraseña",
        "password-confirm"         : "Confirme Contraseña",
        "phone"                    : "Teléfono",
        "principal-bill-electronic": "Utilizar como mi datos de facturación digital principal",
        "principal-direction"      : "Utilizar como mi dirección de envío principal",
        "province"                 : "Provincia",
        "search"                   : "Buscar",
        "search-products"          : "Buscar en los productos",
        "sent"                     : "Su mensaje fue enviado.",
        "submit"                   : "enviar",
        "type-dni"                 : "Tipo de Identificación",
        "wish-contact"             : "¿Cómo desea ser contáctado?"
    },
    galleryPages  : {
        [galleryPages.home]                : "Home",
        [galleryPages.homeBrands]          : "Home Nuestras Marcas",
        [galleryPages.promotions]          : "Promociones",
        [galleryPages.tutorials]           : "Tutoriales",
        [galleryPages.whoAre]              : "Quienes Somos Vertical",
        [galleryPages.recommendationPerson]: "Personas Recomendadas",
        [galleryPages.whoAreCarousel]      : "Quienes Somos Horizontal",
        [galleryPages.contact]             : "Contáctenos",
    },
    global        : {
        "actual-price": "Precio Actual",
        "after"       : "Despúes",
        "before"      : "Antes",
        "description" : "Descripción",
        "endKm"       : "Final",
        "enterprise"  : "Maderas Camacho S.A.",
        "execute"     : "Ejecutar",
        "initKm"      : "Inicio",
        "no-results"  : "No hay resultados",
        "order"       : {
            [1] : "Ascendente",
            [-1]: "Descendente",
        },
        "pages"       : "Páginas",
        "pickup"      : "Pickup",
        "priceByKm"   : "Precio por Km",
        "remove"      : "Eliminar",
        "show-more"   : "Ver más",
    },
    header        : {},
    menu          : {
        [PageNames.actionPage]              : "Acciones",
        [PageNames.homePage]                : "Dashboard",
        [PageNames.galleryPage]             : "Galerias",
        [PageNames.promotionPage]           : "Promociones",
        [PageNames.tutorialPage]            : "Tutoriales",
        [PageNames.userPage]                : "Usuarios",
        [PageNames.aboutUsPage]             : "Acerca de nosotros",
        [PageNames.contactUsPage]           : "Contáctenos",
        [PageNames.occupationPage]          : "Ocupaciones",
        [PageNames.recommendationPersonPage]: "Recomendaciones de personas",
        [PageNames.pagePage]                : "SEO",
        [PageNames.productPage]             : "Productos",
        [PageNames.categoryPage]            : "Categorias",
        [PageNames.methodTransportPage]     : "Métodos de Transporte",
        [PageNames.orderPage]               : "Ordenes",
        [PageNames.colorPalettePage]        : "Paletas de Color",
    },
    "methods-pay" : {
        [MethodsPay.CASH]    : "Efectivo",
        [MethodsPay.CARD]    : "Tarjeta",
        [MethodsPay.TRANSFER]: "Transferencia Bancaria",
    },
    modal         : {
        "agree"  : "Si",
        "cancel" : "Cancelar",
        "confirm": {
            "content": "Esta acción es irreversible.",
            "title"  : "Esta Seguro?"
        }
    },
    order         : {
        [OrderStatus.canceled]     : "Cancelado",
        [OrderStatus.confirm]      : "Confirmado",
        [OrderStatus.shipped]      : "Enviado",
        [OrderStatus.readyToPickup]: "Pedido Listo para retirar (Pickup)",
        [OrderStatus.delivered]    : "Entregado",
    },
    page          : {
        "SEO"             : "SEO",
        "about-us"        : "Acerca de nosotros",
        "actions"         : "Acciones",
        "categories"      : "Categorias",
        "color-palette"   : "Paleta de Colores",
        "contact-us"      : "Contáctenos",
        "edit"            : {
            "actions"              : "Acciones",
            "active"               : "Activo",
            "actualPrice"          : "Precio Actual",
            "brand"                : "Marca",
            "category"             : "Categoria",
            "code"                 : "Código",
            "colors"               : "Colores",
            "create"               : "Crear",
            "createdAt"            : "Fecha Creación",
            "date"                 : "Fecha",
            "dateEnd"              : "Fecha Final",
            "dateInit"             : "Fecha Inicio",
            "delete"               : "Eliminar",
            "description"          : "Descripción",
            "direction"            : "Dirección",
            "edit"                 : "Editar",
            "email"                : "Email",
            "extraData"            : "Extra Data",
            "first-image"          : "Primera Imagen",
            "firstName"            : "Nombre",
            "general"              : "General",
            "identifier"           : "Identificador",
            "image"                : "Imagen",
            "images"               : "Imagenes",
            "inStock"              : "En Stock",
            "information"          : "Información",
            "lastPrice"            : "Precio Anterior",
            "lastname"             : "Apellidos",
            "maxWeight"            : "Máximo peso (Kg)",
            "message"              : "Mensaje",
            "method-pay"           : "Método de pago",
            "method-transport"     : "Método de transporte",
            "minWeight"            : "Mínimo peso (Kg)",
            "name"                 : "Nombre",
            "name-client"          : "Nombre Cliente",
            "occupations"          : "Ocupaciones",
            "order"                : "Orden",
            "order-by"             : "Ordenar por",
            "page"                 : "Es para una página?",
            "password"             : "Password",
            "phone"                : "Teléfono",
            "price"                : "Precio",
            "priceByKm"            : "Precio por KM",
            "products-relationship": "Productos Relacionados",
            "rgb"                  : "RGB",
            "roles"                : "Roles",
            "search"               : "Buscar",
            "see"                  : "Ver",
            "shortDescription"     : "Descripción Corta",
            "slug"                 : "Slug",
            "status"               : "Estado",
            "subcategory"          : "SubCategoria",
            "title"                : "Título",
            "updatedAt"            : "Fecha Actualización",
            "username"             : "Username",
            "wishContact"          : "Como desea ser contactado",
            "youTubeId"            : "Youtube ID",
        },
        "galleries"       : "Galerias",
        "gallery"         : {
            "image"   : "Imagen",
            "order"   : "Orden",
            "subtitle": "Subtítulo",
            "title"   : "Título",
        },
        "last-promotions" : "Ultimas promociones",
        "method-transport": "Métodos de Transporte",
        "order"           : {
            "detail-order"          : "Detalles de la order:",
            "my-car"                : "En el carrito",
            "my-orders"             : "Mis Ordenes",
            "new-order"             : "Ordern Generada",
            "new-order-content"     : "Muy pronto recibira un correo electrónico",
            "order"                 : "Orden:",
            "order-date"            : "Ordenado el:",
            "review-order"          : "Revise su pedido",
            "select-bill-electronic": "Factura Electronica",
            "select-direction"      : "Seleccion una dirección",
        },
        "our-brands"      : "Nuestras Principales Marcas",
        "products"        : "Productos",
        "promotion"       : {
            "description"          : "Descripción",
            "information-promotion": "Información de la promoción",
            "other-promotions"     : "Otras promociones",
            "title"                : "Título",
        },
        "promotions"      : "Promociones",
        "seo"             : {
            "description"       : "Descripción",
            "google"            : "Google",
            "googleAuthor"      : "Author",
            "googleDescription" : "Descripción",
            "googleName"        : "Nombre",
            "googlePublisher"   : "Publisher",
            "image"             : "Imagen",
            "keywords"          : "Keywords",
            "og"                : "OG",
            "seo"               : "SEO",
            "site_name"         : "Nombre del Sitio",
            "title"             : "Nombre",
            "twitter"           : "Twitter",
            "twitterCard"       : "Card",
            "twitterCreator"    : "Creador / Autor",
            "twitterDescription": "Descripción",
            "twitterSite"       : "Site",
            "twitterTitle"      : "Nombre",
            "type"              : "Tipo",
            "url"               : "URL",
        },
        "tutorials"       : "Tutoriales",
        "user"            : "Usuarios"
    },
    role          : {
        [Roles.ADMIN]                : "Admin",
        [Roles.ABOUT_US]             : "Acerca de nosotros",
        [Roles.CONTACT_US]           : "Contáctenos",
        [Roles.GALLERY]              : "Galería",
        [Roles.OCCUPATION]           : "Ocupaciones",
        [Roles.PAGE]                 : "Página SEO",
        [Roles.PROMOTION]            : "Promociones",
        [Roles.COLOR_PALETTE]        : "Paleta de Colores",
        [Roles.RECOMMENDATION_PERSON]: "Recomendaciones Personas",
        [Roles.TUTORIAL]             : "Tutoriales",
        [Roles.USER]                 : "Usuarios",
        [Roles.METHOD_TRANSPORT]     : "Metodos de transporte",
        [Roles.PRODUCT]              : "Productos",
        [Roles.CATEGORY_PRODUCT]     : "Categorias de productos",
        [Roles.ORDER]                : "Ordenes",
    },
    seoPages      : {
        [pages.contact]   : "Contáctenos",
        [pages.default]   : "Default",
        [pages.home]      : "Home",
        [pages.promotions]: "Promociones",
        [pages.tutorials] : "Tutoriales",
        [pages.whoAre]    : "Contáctenos",
    },
    successPayment: {
        amount     : "Monto",
        brand      : "Brand Tarjeta",
        chargeId   : "ID",
        cvc        : "CVC",
        description: "Descripción",
        exp_year   : "Año de expiracion",
        last4      : "Ultimos 4 digitos de la tarjeta",
        title      : "Datos Pago",
    }
};

export default es;
