import BaseStore                               from "./Base/BaseStore";
import { deserialize, serialize }              from "serializr";
import { Container }                           from "typedi";
import AjaxService                             from "../Service/AjaxService";
import { observable }                          from "mobx";
import { globalPagerOptions, IPaginationData } from "../config/pagination";
import { MethodTransport }                     from "../Models/MethodTransport/MethodTransport";

export class MethodTransportStore extends BaseStore {
    public static readonly NAME_STORE: string = "MethodTransportStore";

    @observable
    private methodTransports: MethodTransport[] = [];

    @observable
    private methodTransport: MethodTransport;

    @observable
    private spinner: boolean    = false;
    private currentPage: number = 1;
    private pages: number       = 1;

    public getMethodTransport(): MethodTransport {
        return this.methodTransport;
    }

    public setMethodTransport(value: MethodTransport) {
        this.methodTransport = value;
    }

    public getMethodTransports(): MethodTransport[] {
        return this.methodTransports;
    }

    public setMethodTransports(value: MethodTransport[]) {
        this.methodTransports = value;

        return this;
    }

    public getPages(): number {
        return this.pages;
    }

    public setPages(value: number): this {
        this.pages = value;

        return this;
    }

    public getSpinner(): boolean {
        return this.spinner;
    }

    public setSpinner(value: boolean): this {
        this.spinner = value;

        return this;
    }

    public getCurrentPage(): number {
        return this.currentPage;
    }

    public setCurrentPage(value: number): this {
        this.currentPage = value;

        return this;
    }

    protected init() {
        this.needPersistData = false;
    }

    private getAjaxService(): AjaxService {
        return Container.get(AjaxService);
    }

    public async getPaginate(): Promise<IPaginationData<MethodTransport>> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getPaginateMethodTransports(
            this.getCurrentPage(),
            globalPagerOptions.MAX_PER_PAGE
        );

        return {
            data : dataResponse.data.map((item: any) => deserialize(MethodTransport, item)),
            pages: dataResponse.pages
        };
    }

    public async getActive(): Promise<MethodTransport[]> {
        const {data: {data}} = await this.getAjaxService().getActiveMethodTransports();

        return data.map((item: any) => deserialize(MethodTransport, item));
    }

    public async getById(id: string): Promise<MethodTransport> {
        const {data: {data: dataResponse}} = await this.getAjaxService().getMethodTransportId(id);

        return deserialize(MethodTransport, dataResponse);
    }

    public async deleteById(id: string): Promise<boolean> {
        const {data: {success}} = await this.getAjaxService().deleteMethodTransportId(id);

        return success;
    }

    public async save(category: MethodTransport): Promise<MethodTransport> {
        const {data: {data: dataResponse}} = await this.getAjaxService().saveMethodTransport(serialize(category));

        return deserialize(MethodTransport, dataResponse);
    }
}
